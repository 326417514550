import styled from "styled-components";
import fonts from "styles/fonts";

export const LastUpdatedTextStyle = styled.p`
  font-size: ${fonts.xSmall};

  /* Need enough specificity to override the content css */
  &&& {
    margin-bottom: 0;
  }
`;
