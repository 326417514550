import SectionPanel from "components/homepage/SectionPanel/SectionPanel";
import { RequestStatus } from "hooks/useRequest";
import { CreditDefaultNoticeModelTransformed } from "models/creditDefaultNotice/creditDeafultNoticeTransformed";
import { DataOrErrored } from "utils/dataOrErrored";
import routeConstants from "utils/routeConstants";

import CreditDefaultNoticeContent from "./CreditDefaultNoticesContent/CreditDefaultNoticesContent";

export type CreditDefaultNoticesData = {
  creditDefaultNotices: DataOrErrored<CreditDefaultNoticeModelTransformed[]>;
} | null;

interface Props {
  requestStatus: RequestStatus;
  data: CreditDefaultNoticesData;
  lastRefreshedDate?: Date;
}

const CreditDefaultNotices: React.FC<Props> = ({
  requestStatus,
  data,
  lastRefreshedDate,
}) => (
  <SectionPanel
    title="Credit default notices"
    description={
      <p>
        Credit Default Notices are generated by the ECVAA system when a trading
        party enters or clears a Credit Default level. There are 2 levels of
        Credit Default, level 1 and level 2.
      </p>
    }
    link={{
      text: "See all notices",
      href: routeConstants.creditDefaultNotices,
    }}
    data={data}
    requestStatus={requestStatus}
    testId="credit-default-notices"
    lastRefreshedDate={lastRefreshedDate}
  >
    {({ creditDefaultNotices }): React.ReactNode => (
      <CreditDefaultNoticeContent creditDefaultNotices={creditDefaultNotices} />
    )}
  </SectionPanel>
);

export default CreditDefaultNotices;
