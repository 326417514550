import { faFilter } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Button from "components/components/Button/Button";
import Dropdown from "components/components/Dropdown/Dropdown";
import {
  ApplyFiltersButton,
  ButtonsDiv,
  ErrorMessageDiv,
  UnsavedChangesDiv,
} from "components/components/FiltersForm/style";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import {
  mapToBmuTypeOptions,
  mapToLeadPartyOptions,
} from "components/components/balancingMechanism/marketView/MarketViewFilters/helpers";
import {
  DerivedDataFilters,
  filterBMUByDerivedDataFilters,
  hasAnyFilters,
} from "components/dataVisualization/DerivedData/utils";
import { RequestStatus } from "hooks/useRequest";
import useToggle from "hooks/useToggle";
import { BmuModel } from "models/bmuData/bmuModel";
import { FormEvent, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { areObjectsEqual } from "utils/objectUtils";

import {
  StyledFilterButton,
  StyledDiv,
  StyledForm,
  DropdownsDiv,
} from "./style";

interface Props {
  children: JSX.Element;
  bmuData: BmuModel[];
  bmuStatus: RequestStatus;
  derivedDataFilters?: BmuModel[];
  setDerivedDataFilters: (filters: BmuModel[] | undefined) => void;
}

const DerivedDataFilter: React.FC<Props> = ({
  children,
  bmuData,
  bmuStatus,
  derivedDataFilters,
  setDerivedDataFilters,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = useState<DerivedDataFilters>({
    leadParty: searchParams.get("leadParty"),
    bmuType: searchParams.get("bmuType"),
  });
  const [activeFilters, setActiveFilters] =
    useState<DerivedDataFilters>(selectedFilters);
  const unsavedChanges = useMemo(
    () => !areObjectsEqual<DerivedDataFilters>(activeFilters, selectedFilters),
    [selectedFilters, activeFilters]
  );

  const updateFilter = <T extends keyof DerivedDataFilters>(
    newOption: string,
    key: T
  ): void => {
    setSelectedFilters((curr) => ({ ...curr, [key]: newOption }));
  };

  const [showForm, toggleForm] = useToggle(false);
  const onApplyFilters = (filters: DerivedDataFilters): void => {
    const { leadParty, bmuType } = filters;
    const queryParams = {
      ...(leadParty && { leadParty }),
      ...(bmuType && { bmuType }),
    };

    setSearchParams(queryParams);
    setDerivedDataFilters(
      hasAnyFilters(filters)
        ? filterBMUByDerivedDataFilters(bmuData, filters)
        : undefined
    );
    history.replaceState(
      {
        query:
          leadParty || bmuType
            ? `?${new URLSearchParams(queryParams).toString()}`
            : "",
      },
      ""
    );
  };

  const handleReset = (): void => {
    if (selectedFilters.leadParty || selectedFilters.bmuType) {
      setSelectedFilters({
        leadParty: null,
        bmuType: null,
      });
    }
  };

  const handleDropdownChange = <T extends keyof DerivedDataFilters>(
    newValue: string,
    oldValue: string | null,
    key: T
  ): void => {
    if (newValue !== oldValue) {
      updateFilter(newValue, key);
    }
  };
  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onApplyFilters(selectedFilters);
    setActiveFilters(selectedFilters);
  };

  const filterButton = (
    <StyledFilterButton
      onClick={toggleForm}
      className={classnames("filter", {
        active: derivedDataFilters ? derivedDataFilters?.length > 0 : false,
      })}
      type="button"
      data-test-id="derived-data-filters-button"
    >
      <span>Filters</span>
      <Icon iconName={faFilter} size={IconSize.xSmall} />
    </StyledFilterButton>
  );

  return (
    <StyledDiv>
      <h2>Time frame and filters</h2>
      <div>
        {children}
        {filterButton}
      </div>
      <StyledForm
        onSubmit={handleSubmit}
        noValidate
        className={classnames({ visible: showForm })}
        aria-live="polite"
      >
        <DropdownsDiv>
          <div data-test-id="lead-party-filter">
            <label>Lead party name</label>
            <Dropdown
              values={mapToLeadPartyOptions(bmuData).sort((a, b) =>
                a.localeCompare(b)
              )}
              selectedValue={selectedFilters.leadParty}
              onChange={(newValue: string): void => {
                handleDropdownChange(
                  newValue,
                  selectedFilters.leadParty,
                  "leadParty"
                );
              }}
              ariaLabel="Lead party dropdown"
              status={bmuStatus}
            />
          </div>
          <div data-test-id="bmu-type-filter">
            <label>BMU type</label>
            <Dropdown
              values={mapToBmuTypeOptions(bmuData)}
              selectedValue={selectedFilters.bmuType}
              onChange={(newValue: string): void =>
                handleDropdownChange(
                  newValue,
                  selectedFilters.bmuType,
                  "bmuType"
                )
              }
              ariaLabel="BMU type dropdown"
              status={bmuStatus}
            />
          </div>
        </DropdownsDiv>
        <ButtonsDiv>
          <ApplyFiltersButton buttonText="Apply Filters" type="submit" />
          <Button
            className="link-inline"
            buttonText="Reset all filters"
            type="reset"
            onClick={handleReset}
          />
        </ButtonsDiv>
        {unsavedChanges && (
          <UnsavedChangesDiv>
            There are unsaved changes in your filters. Remember to click
            &quot;apply filters&quot; to see accurate results in the table
            below.
          </UnsavedChangesDiv>
        )}
        {bmuStatus === RequestStatus.ERRORED && (
          <ErrorMessageDiv>
            An error occurred while loading filters, please refresh the page.
          </ErrorMessageDiv>
        )}
      </StyledForm>
    </StyledDiv>
  );
};
export default DerivedDataFilter;
