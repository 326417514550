import axios from "axios";
import { useErrorContext } from "contexts/ErrorContext";
import { useCallback, useState } from "react";

export enum RequestStatus {
  SUCCESSFUL_OR_NOT_STARTED = "SuccessfulOrNotStarted",
  IN_PROGRESS = "InProgress",
  ERRORED = "Errored",
}

const useRequest = <TData, TArgs extends unknown[]>(
  request: (...args: TArgs) => Promise<TData>,
  onError?: (e: Error) => void
): {
  request: (...args: TArgs) => Promise<void>;
  errorCode: number | null;
  status: RequestStatus;
  data: TData | null;
} => {
  const [status, setStatus] = useState<RequestStatus>(
    RequestStatus.SUCCESSFUL_OR_NOT_STARTED
  );
  const [data, setData] = useState<TData | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);

  const makeRequest = useCallback(
    async (...args: TArgs): Promise<void> => {
      setStatus(RequestStatus.IN_PROGRESS);

      try {
        const response = await request(...args);
        setErrorCode(null);
        setData(response);
        setStatus(RequestStatus.SUCCESSFUL_OR_NOT_STARTED);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status) {
          setErrorCode(e.response.status);
        } else {
          setErrorCode(null);
        }
        onError?.(e as Error);
        setStatus(RequestStatus.ERRORED);
        // Note: we rely on data still containing the last data that was
        // successfully loaded for useDataWithRefresh, so don't call setData
        // here
      }
    },
    [onError, request]
  );

  return {
    request: makeRequest,
    errorCode,
    status,
    data,
  };
};

export const useRequestWithDefaultErrorHandling = <
  TData,
  TArgs extends unknown[]
>(
  request: (...args: TArgs) => Promise<TData>,
  errorText?: string
): {
  request: (...args: TArgs) => Promise<void>;
  errorCode: number | null;
  status: RequestStatus;
  data: TData | null;
} => {
  const { registerError } = useErrorContext();
  const onError = (): void => registerError(errorText);
  return useRequest(request, onError);
};

export default useRequest;
