import styled from "styled-components";

/**
 * We fix the column widths so that all columns widths are known on page load (fix for #271656).
 *
 * This is because the third column contains truncated text (using the ShowMore component) and if we use
 * variable widths the truncation and browser column width optimiser can get into an argument about how
 * wide the column should be, resulting in the width 'bouncing' back and forth infinitely for pathological
 * cases. Even in good cases it takes a while for them to settle.
 *
 * If we fix it then the truncation can immediately happen in the right place. We fix based on the first
 * two columns because these contain (short) data of well defined length.
 *
 * The assumption is that we will not need this more widely, so making this simple change here is best.
 * If we need to set columns widths on more tables in future then we should consider adding it as a first
 * class feature of the SortableTable component as props, probably by passing in some widths to the column
 * props.
 */
export const FixedColumnWidths = styled.div`
  table {
    table-layout: fixed;
  }

  th:nth-child(1) {
    width: 140px;
  }

  th:nth-child(2) {
    width: 170px;
  }
`;
