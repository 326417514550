import React from "react";
import { Link } from "react-router-dom";

interface Props {
  urlBase: string;
  queryParams: URLSearchParams;
  linkText: string;
  openInANewTab?: boolean;
}

const TextLinkWithQueryParams: React.FC<Props> = ({
  urlBase,
  queryParams,
  linkText,
  openInANewTab,
}: Props) => (
  <Link
    to={urlBase + "?" + queryParams.toString()}
    target={openInANewTab ? "_blank" : "_self"}
    rel="noreferrer"
  >
    {linkText}
  </Link>
);

export default TextLinkWithQueryParams;
