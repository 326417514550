import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import PageMeta from "components/components/Meta/PageMeta";
import {
  BalancingMechanismTabName,
  TabDetails,
} from "components/components/Tabs/Tabs/Tabs";
import BmuAndDateSelection from "components/components/balancingMechanism/bmuView/BmuAndDateSelection/BmuAndDateSelection";
import BmuViewFooter from "components/components/balancingMechanism/bmuView/BmuViewFooter/BmuViewFooter";
import BmuViewTabs from "components/components/balancingMechanism/bmuView/BmuViewTabs/BmuViewTabs";
import BmuViewBalancingServicesVolume from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/bmuView/BmuViewBalancingServicesVolume";
import BmuViewBidOffer from "components/dataVisualization/balancingMechanismTabs/BidOffer/bmuView/BmuViewBidOffer";
import BmuViewDynamic from "components/dataVisualization/balancingMechanismTabs/Dynamic/bmuView/BmuViewDynamic";
import BmuViewPhysical from "components/dataVisualization/balancingMechanismTabs/Physical/bmuView/BmuViewPhysical";
import AdditionalInfoSection, {
  DocumentationInformation,
} from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import {
  BmuViewContextProvider,
  useBmuViewContext,
} from "contexts/BmuViewContext";
import React from "react";

const balancingMechanismTabDocumentationLinkDict: {
  [key in BalancingMechanismTabName]: DocumentationInformation;
} = {
  Physical: {
    chart: ["balancing/acceptances", "balancing/physical", "balancing/dynamic"],
  },
  Dynamic: {
    chart: ["balancing/dynamic", "balancing/dynamic/rates"],
  },
  "Bid Offer": {
    chart: ["balancing/physical", "balancing/bid-offer"],
  },
  "Balancing Services Volume": {
    chart: ["balancing/nonbm/volumes"],
  },
};

const BmuViewPage: React.FC = () => {
  const tabs: TabDetails<BalancingMechanismTabName>[] = [
    {
      title: "Physical",
      pageTitle: "Physical data",
      children: <BmuViewPhysical />,
    },
    {
      title: "Dynamic",
      pageTitle: "Dynamic data",
      children: <BmuViewDynamic />,
    },
    {
      title: "Bid Offer",
      pageTitle: "Bid offer data",
      children: <BmuViewBidOffer />,
    },
    {
      title: "Balancing Services Volume",
      pageTitle: "Balancing services volume data",
      children: <BmuViewBalancingServicesVolume />,
    },
  ];

  const { activeTabTitle, setActiveTabTitle } = useBmuViewContext();

  return (
    <>
      <PageMeta title="Balancing Mechanism data by BMU view" />
      <PageHeader
        title="Balancing Mechanism data by BMU view"
        pageDescription={
          <p>
            This page can be used to view Physical, Dynamic, Bid Offer and
            Balancing Services Volume data for a specific BM Unit. Use the
            controls below to select a BM Unit and a date range for which to
            view the data.
          </p>
        }
      />
      <FullWidthSection className="is-grey-section">
        <BmuAndDateSelection />
      </FullWidthSection>
      <BmuViewTabs
        tabs={tabs}
        activeTabTitle={activeTabTitle}
        setActiveTabTitle={setActiveTabTitle}
      />
      <BmuViewFooter />
      <AdditionalInfoSection
        documentationInformation={
          balancingMechanismTabDocumentationLinkDict[activeTabTitle]
        }
      />
    </>
  );
};

const BmuView: React.FC = () => (
  <BmuViewContextProvider>
    <BmuViewPage />
  </BmuViewContextProvider>
);

export default BmuView;
