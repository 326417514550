import { Serie } from "@nivo/line";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { DagData } from "models/dayAheadAggregatedGeneration/dayAheadAggregatedGeneration";
import colours from "styles/colours";

const dagDataset = {
  colour: colours.elexonBlue,
  dataKey: "generation",
  displayName: "Generation",
};

export const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [dagDataset],
    id: "DAG",
  },
];

export const transformDagDataToSerie = (rawData: DagData[]): Serie[] => [
  {
    id: dagDataset.dataKey,
    colour: dagDataset.colour,
    data: rawData.map((data) => ({
      x: new Date(data.startTime),
      y: data.quantity,
      settlementPeriod: data.settlementPeriod,
    })),
  },
];
