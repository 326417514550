import React from "react";

import { StyledTopMenu, StyledTopMenuLink } from "./style";

interface Props {
  url: string;
  linkText: string;
}

const TopMenuLink: React.FC<Props> = ({ url, linkText }: Props) => (
  <StyledTopMenuLink>
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      data-test-id="top-menu-link"
    >
      {linkText}
    </a>
  </StyledTopMenuLink>
);

const TopMenu: React.FC = () => (
  <StyledTopMenu>
    <TopMenuLink url="https://www.elexon.co.uk" linkText="BSC Website" />
    <TopMenuLink url="https://www.elexonportal.co.uk" linkText="BSC Portal" />
  </StyledTopMenu>
);

export default TopMenu;
