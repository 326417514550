import {
  Data,
  RowDl,
  TileTitle,
  TileWrapper,
} from "components/remit/MessageDetails/RemitMessageTiles/style";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import React from "react";
import {
  propertyDisplayNames,
  formatRowValue,
  getDisplayNameFromJsonKey,
} from "utils/remitHelpers";

const RemitMessageTile: React.FC<{
  tileTitle: string;
  data: Partial<RemitMessageDetailsModel>;
}> = ({ tileTitle, data }) => (
  <TileWrapper>
    <TileTitle>{tileTitle}</TileTitle>
    <Data>
      {Object.entries(data).map(([key, value]) => (
        <React.Fragment key={key}>
          <RowDl>
            <dt>{getDisplayNameFromJsonKey(key)}</dt>
            <dd>
              {formatRowValue(
                data,
                key as keyof RemitMessageDetailsModel,
                value
              )}
            </dd>
          </RowDl>
          {getDisplayNameFromJsonKey(key) !==
            propertyDisplayNames.messageHeading && <hr />}
        </React.Fragment>
      ))}
    </Data>
  </TileWrapper>
);

export default RemitMessageTile;
