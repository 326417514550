import InternalLink from "components/shared/InternalLink/InternalLink";
import styled from "styled-components";
import fonts from "styles/fonts";

export const SettlementMessageList = styled.ol`
  && {
    margin: 0;
  }
`;

export const SettlementMessageListItem = styled.li`
  list-style: none;
  margin-bottom: 16px;

  h3 {
    font-size: ${fonts.mediumBigger}; /* matches the other headings on the page */
  }
`;

export const HorizontalLayout = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 8px;

  /* Needs to be more specific than the main content styling */
  &&& > * {
    margin: auto 0;
    padding: 0;
  }
`;

export const DateFilterText = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;

  margin-bottom: 12px;

  & > span {
    font-weight: ${fonts.heavy};
  }
`;

export const InternalLinkWithBottomMargin = styled(InternalLink)`
  margin-bottom: 16px;
  display: block;
`;
