import { getSettlementMessagesJsonData } from "api/dataVisualisation/settlementSystemPricesAndMessages/settlementSystemPricesAndMessages";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SettlementMessagesModel } from "models/settlementMessages/settlementMessagesModel";
import { useCallback } from "react";

import { MessageTypeText } from "./settlementMessageUtils";

const sortSettlementMessagesDataNewestToOldest = (
  data: SettlementMessagesModel[]
): SettlementMessagesModel[] =>
  data.sort((x, y): number => {
    if (new Date(x.settlementDate) < new Date(y.settlementDate)) {
      return 1;
    } else if (new Date(x.settlementDate) > new Date(y.settlementDate)) {
      return -1;
    }
    if (x.settlementPeriod < y.settlementPeriod) {
      return 1;
    }
    return -1;
  });

const useSettlementMessagesData = (
  dateFilter: DateFilterModel,
  messageType: MessageTypeText
): {
  fetchData: () => void;
  data: SettlementMessagesModel[] | null;
  status: RequestStatus;
} => {
  const {
    data: jsonData,
    status,
    request: fetchSettlementMessagesData,
  } = useRequest(getSettlementMessagesJsonData);

  const data = jsonData
    ? sortSettlementMessagesDataNewestToOldest(
        jsonData.data.filter(
          (x) =>
            messageType === MessageTypeText.AllMessages ||
            x.messageType === messageType
        )
      )
    : null;

  const fetchData = useCallback(() => {
    fetchSettlementMessagesData(dateFilter);
  }, [dateFilter, fetchSettlementMessagesData]);

  return {
    data,
    status,
    fetchData,
  };
};

export default useSettlementMessagesData;
