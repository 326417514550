import RemitInfoPopup from "components/homepage/Remit/RemitInfoPopup/RemitInfoPopup";
import { BoldedText, ItemName } from "components/homepage/style";
import PercentageDonutChart from "components/shared/PercentageDonutChart/PercentageDonutChart";
import { EventStatus } from "models/remit/remitMessageDetailsModel";
import React, { useState } from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";
import { formatCapacity } from "utils/remitHelpers";

import {
  InterconnectorInformation,
  RemitListItem,
  GreenBorderOnHoverButton,
  UnavailableCapacity,
} from "./style";

interface Props {
  mrid: string;
  messageId: number;
  assetId: string;
  eventStatus: EventStatus;
  normalCapacity: number;
  availableCapacity: number;
  unavailableCapacity: number;
  fuelType: string | null;
  eventStartTime: Date;
  eventEndTime: Date | null;
  publishTime: Date;
}

const isInterconnector = (assetId: string): boolean => assetId.startsWith("I");

const RemitItem: React.FC<Props> = ({
  mrid,
  messageId,
  assetId,
  eventStatus,
  normalCapacity,
  availableCapacity,
  unavailableCapacity,
  fuelType,
  eventStartTime,
  eventEndTime,
  publishTime,
}) => {
  const [showModal, setShowModal] = useState(false);
  const percentageAvailable =
    availableCapacity === 0
      ? 0
      : (availableCapacity * 100) / (availableCapacity + unavailableCapacity);

  return (
    <RemitListItem data-test-id="remit-item">
      <GreenBorderOnHoverButton onClick={(): void => setShowModal(true)}>
        <div>
          <ItemName>{assetId}</ItemName>
          <div>
            <BoldedText>Event start</BoldedText>:{" "}
            {formatDateTimeString(eventStartTime, DateFormat.DateTime)}
          </div>
          {eventEndTime && (
            <div>
              <BoldedText>Event end</BoldedText>:{" "}
              {formatDateTimeString(eventEndTime, DateFormat.DateTime)}
            </div>
          )}
          <div>
            <BoldedText>Published</BoldedText>:{" "}
            {formatDateTimeString(publishTime, DateFormat.DateTime)}
          </div>
        </div>
        {isInterconnector(assetId) ? (
          <InterconnectorInformation>
            <div>
              <BoldedText>Unavailable capacity:</BoldedText>
            </div>
            <UnavailableCapacity>
              {formatCapacity(unavailableCapacity)}
            </UnavailableCapacity>
          </InterconnectorInformation>
        ) : (
          <PercentageDonutChart
            percentage={percentageAvailable}
            displayText={formatCapacity(availableCapacity)}
            displayTextLabel="Available"
          />
        )}
      </GreenBorderOnHoverButton>
      <RemitInfoPopup
        showModal={showModal}
        setShowModal={setShowModal}
        mrid={mrid}
        messageId={messageId}
        assetId={assetId}
        eventStatus={eventStatus}
        eventStartTime={eventStartTime}
        eventEndTime={eventEndTime}
        fuelType={fuelType}
        normalCapacity={normalCapacity}
        availableCapacity={availableCapacity}
        unavailableCapacity={unavailableCapacity}
      />
    </RemitListItem>
  );
};

export default RemitItem;
