import { Serie } from "@nivo/line";
import { TotalLoadActualAndDayAheadData } from "models/TotalLoad/totalLoadModel";

export const transformTotalLoadActualAndDayAheadTypeDataToSerie = (
  rawData: TotalLoadActualAndDayAheadData[],
  dataset: string,
  colour: string
): Serie => ({
  id: dataset,
  colour: colour,
  data: rawData.map((data) => ({
    x: new Date(data.startTime),
    y: data.quantity,
    settlementPeriod: data.settlementPeriod,
    publishTime: data.publishTime ? new Date(data.publishTime) : undefined,
  })),
});
