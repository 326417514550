import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import InternalLink from "components/shared/InternalLink/InternalLink";
import LastUpdatedText from "components/shared/LastUpdated/LastUpdatedText/LastUpdatedText";
import Panel from "components/shared/Panel/Panel";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const PanelWithVerticalMargins = styled(Panel)`
  margin: 8px 0 16px;
  color: ${colours.charcoal};

  font-size: ${fonts.smallMedium};

  padding: 20px 18px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h2`
  && {
    font-size: ${fonts.large};
    margin-bottom: 24px;
  }
`;

export const StyledLink = styled(InternalLink)`
  font-size: ${fonts.mediumSmaller};
  font-weight: ${fonts.semibold};
`;

export const HorizontalLayout = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: center;
`;

export const MinHeight = styled.div`
  min-height: 150px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-size: ${fonts.medium};
  font-weight: bold;

  padding-top: 30px;
`;

export const FixedSizeLoadingSpinner = styled(LoadingSpinner)`
  height: 70px;
`;

export const LastUpdatedTextWithMargins = styled(LastUpdatedText)`
  margin-top: 10px;
  margin-left: 10px;
`;
