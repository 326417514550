import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "components/components/CheckboxInput/Checkbox";
import Icon from "components/components/Icon/Icon";
import { RequestStatus } from "hooks/useRequest";
import { ReactNode, useMemo } from "react";
import colours from "styles/colours";

import { FilterOption, mapToOptions } from "./helpers";
import {
  BoldWord,
  Divider,
  Placeholder,
  SelectAllLabel,
  SelectAllRow,
  StyledLabel,
  StyledMultiSelect,
  ValueRendererLabel,
} from "./style";

interface MultiSelectDropdownProps {
  values: string[];
  selectedValues: string[];
  onChange: (value: FilterOption[]) => void;
  status: RequestStatus;
  disableSearchBar?: boolean;
}

interface ItemRendererProps {
  checked: boolean;
  option: FilterOption;
  onClick: () => void;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  values,
  selectedValues,
  onChange,
  status,
  disableSearchBar = true,
}) => {
  const allOptions = useMemo(() => mapToOptions(values), [values]);
  const selectedOptions = useMemo(() => {
    if (selectedValues.length === 0) {
      return [];
    }
    return mapToOptions(selectedValues);
  }, [selectedValues]);

  const customValueRenderer = (
    selected: FilterOption[],
    _options: FilterOption[]
  ): ReactNode => (
    <ValueRendererLabel>
      {selected.length === _options.length ? (
        <>
          Selected filters:&nbsp;<BoldWord>All</BoldWord>
        </>
      ) : selected.length ? (
        <>
          Selected filters:&nbsp;<BoldWord>{selected.length}</BoldWord>
        </>
      ) : (
        <Placeholder>
          {disableSearchBar ? "Select option" : "Search or select"}
        </Placeholder>
      )}
    </ValueRendererLabel>
  );

  const customItemRenderer: React.FC<ItemRendererProps> = ({
    checked,
    option,
    onClick,
  }) => (
    <>
      {option.label === "Select All" ? (
        <SelectAllRow>
          <SelectAllLabel onClick={onClick}>{option.label}</SelectAllLabel>
          <Divider />
          <SelectAllLabel onClick={(): void => onChange([])}>
            Deselect All
          </SelectAllLabel>
        </SelectAllRow>
      ) : (
        <div className="item-renderer">
          <Checkbox
            inputId={`toggle-hidden-boal-flags-${option.value}`}
            isChecked={checked}
            isInline
            onChange={onClick}
          />
          <StyledLabel>{option.label}</StyledLabel>
        </div>
      )}
    </>
  );

  const customDropdownIndicator = (): JSX.Element => (
    <Icon iconName={faAngleDown} colour={colours.darkGrey} />
  );

  return (
    <StyledMultiSelect
      options={allOptions}
      value={selectedOptions}
      onChange={onChange}
      labelledBy="Select"
      valueRenderer={customValueRenderer}
      ItemRenderer={customItemRenderer}
      ArrowRenderer={customDropdownIndicator}
      ClearSelectedIcon={null}
      disableSearch={disableSearchBar}
      isLoading={status === RequestStatus.IN_PROGRESS}
    />
  );
};

export default MultiSelectDropdown;
