import { RevisionHistory } from "components/components/Remit/RemitFiltersForm/remitFilters";
import ResultsCount from "components/components/ResultsCount/ResultsCount";
import { StyledDateTimeWithSeconds } from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import { RemitTableModel } from "models/remit/remitTableModel";
import React from "react";

import { StyledSection, StyledHeader } from "./style";

interface Props {
  data: RemitTableModel[];
  revisionHistoryFilter: RevisionHistory;
}

const RemitLandingPageTable: React.FC<Props> = ({
  data,
  revisionHistoryFilter,
}) => (
  <>
    <StyledSection>
      <ResultsCount count={data.length} />
      <StyledHeader>
        You are viewing{" "}
        {revisionHistoryFilter == RevisionHistory.LatestOnly
          ? " the latest revisions only"
          : " all revisions"}
      </StyledHeader>
    </StyledSection>
    <SortableTable
      headers={{
        publishTime: "Publish Time\n" + "(UTC)",
        mrid: "Message Group (MRID)",
        participantId: "Participant ID",
        assetId: "Asset ID",
        eventStartTime: "Event start\n" + "(UTC)",
        eventEndTime: "Event end\n" + "(UTC)",
        availableCapacity: "Available\n" + "capacity",
        fuelType: "Fuel Type",
        unavailabilityType: "Unavailability\n" + " type",
        messageType: "Message type",
        eventType: "Event type",
        eventStatus: "Event status",
        messageId: "MessageId",
        url: "Url",
      }}
      items={data}
      dataTestId="remit-landing-page-table"
      renderFns={{
        publishTime: ({ publishTime }): TableCellRender => ({
          content: StyledDateTimeWithSeconds(new Date(publishTime)),
        }),
        eventStartTime: ({ eventStartTime }): TableCellRender => ({
          content: StyledDateTimeWithSeconds(new Date(eventStartTime)),
        }),
        eventEndTime: ({ eventEndTime }): TableCellRender => ({
          content: StyledDateTimeWithSeconds(
            eventEndTime ? new Date(eventEndTime) : null
          ),
        }),
      }}
      hiddenColumns={["url"]}
      allowWrapping={true}
    />
  </>
);

export default RemitLandingPageTable;
