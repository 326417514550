import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const NoContentText = styled.p`
  text-align: center;
  font-size: ${fonts.medium};
  font-weight: ${fonts.heavy};

  padding-top: 30px;
`;

export const BoldedText = styled.span`
  font-weight: bold;
`;

export const ItemName = styled.h3`
  font-size: ${fonts.mediumBigger};
  font-weight: bold;
`;

export const ListItemWithNewHighlight = styled.li<{ isNew: boolean }>`
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  list-style-type: none;

  ${({ isNew }): false | FlattenSimpleInterpolation =>
    isNew &&
    css`
      background-color: ${colours.jungleGreen}4d;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid ${colours.jungleGreen};
    `}
`;

export const NewItemLabel = styled.div`
  font-size: ${fonts.medium};
  font-weight: bold;
  color: ${colours.elexonBlue};
  padding-bottom: 10px;
`;
