import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import {
  NormalWrapCell,
  NoWrapCell,
} from "components/components/SortableTable/style";
import CreditDefaultNoticeTableModel from "components/dataVisualization/creditDefaultNotice/CreditDefaultNoticeTableModel";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

interface Props {
  data: CreditDefaultNoticeTableModel[];
}

const compareByEnteredDateDesc = (
  a: CreditDefaultNoticeTableModel,
  b: CreditDefaultNoticeTableModel
): number => b.enteredDate.getTime() - a.enteredDate.getTime();

const CreditDefaultNoticeTable: React.FC<Props> = ({ data }) => (
  <SortableTable
    headers={{
      participantName: "Participant",
      participantId: "Participant ID",
      level: "Level",
      enteredDate: "Entered Date",
      enteredPeriod: "Entered Period",
      clearedDate: "Cleared Date",
      clearedPeriod: "Cleared Period",
      text: "Text",
    }}
    items={data.sort(compareByEnteredDateDesc)}
    renderFns={{
      enteredDate: ({ enteredDate }): TableCellRender => ({
        content: (
          <NoWrapCell>
            {formatDateTimeString(enteredDate, DateFormat.DateOnly)}
          </NoWrapCell>
        ),
      }),
      clearedDate: ({ clearedDate }): TableCellRender => ({
        content: (
          <NoWrapCell>
            {clearedDate
              ? formatDateTimeString(clearedDate, DateFormat.DateOnly)
              : "—"}
          </NoWrapCell>
        ),
      }),
      text: ({ text }): TableCellRender => ({
        content: <NormalWrapCell>{text ? text : "—"}</NormalWrapCell>,
      }),
    }}
    dataTestId="cdn-table-cdn-data"
  />
);

export default CreditDefaultNoticeTable;
