import Panel from "components/shared/Panel/Panel";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export const StyledPanel = styled(Panel)<{ isMiniTile?: boolean }>`
  ${({ isMiniTile }): false | FlattenSimpleInterpolation =>
    !!isMiniTile &&
    css`
      padding: 1rem;
    `}
`;
