import { Point, ResponsiveLine, Serie } from "@nivo/line";
import { ErrorHandlingProps } from "components/components/ComponentWithRequest/ComponentWithRequest";
import { nivConfig } from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesChartConfig";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { RequestStatus } from "hooks/useRequest";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartTheme,
  chartXTimeScale,
  commonChartProps,
  modifyChartMargin,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import {
  formatAsTimeWithDatesMarked,
  formatNumberToFixedWithoutTrailingZeroes,
} from "utils/chartHelpers";

interface Props extends ErrorHandlingProps {
  chartIndent: number;
  series: Serie[];
  tooltip: (input: Point) => JSX.Element;
}

const NivChart: React.FC<Props> = ({
  chartIndent,
  series,
  tooltip,
  dataFetchStatus,
  rerequestData,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const nivChartAxisLeft = chartAxisLeft("MWh", isChartLoaded, 4);
    nivChartAxisLeft.format = formatNumberToFixedWithoutTrailingZeroes;
    nivChartAxisLeft.legendOffset = -80 - chartIndent;

    return (
      <ResponsiveLine
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom(
          "Start Time (UTC)",
          series,
          isChartLoaded,
          undefined,
          formatAsTimeWithDatesMarked
        )}
        axisLeft={nivChartAxisLeft}
        gridYValues={4}
        enableGridX={true}
        sliceTooltip={(input): JSX.Element => tooltip(input.slice.points[0])}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series)}
        theme={{
          ...chartTheme,
          crosshair: {
            line: {
              strokeOpacity: 0,
              strokeDasharray: "999", // marker value for <StackedChartContainer> crosshair logic
            },
          },
        }}
        margin={modifyChartMargin({ left: chartIndent })}
      />
    );
  }, [dataFetchStatus, series, tooltip, chartIndent]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={nivConfig.datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
        containerHeight={330}
      >
        {memoisedChart}
      </ChartWithErrorHandling>
    ),
    [series, dataFetchStatus, memoisedChart, rerequestData]
  );
};

export default NivChart;
