import { parseStringToBoolean } from "utils/booleanUtils";

import { addYearsToDate } from "./dateHelpers";

const COOKIE_SCRIPT_CONSENT_COOKIE = "CookieScriptConsent";
export const SHOW_IRIS_LOGIN_MODAL_COOKIE = "ShowLoginModal";
export const HAS_COLLAPSED_BANNER_COOKIE = "HasCollapsedTestBanner";

// When there are no categories, the initial JSON.parse will parse `categories` correctly as an empty array.
// If there are categories present, then `categories` will be a JSON string of an array.
const parseCategories = (rawCategories: string | string[]): string[] =>
  typeof rawCategories === "string" ? JSON.parse(rawCategories) : rawCategories;

interface CookieScriptAcceptEvent extends Event {
  detail: {
    categories: string[];
  };
}

type CookieScriptConsent = {
  action: string;
  categories: string[];
  key: string;
};

const parseCookieScriptConsent = (raw: string): CookieScriptConsent => {
  const partiallyParsedCookie = JSON.parse(raw);
  const { categories } = partiallyParsedCookie;
  return {
    ...partiallyParsedCookie,
    categories: parseCategories(categories),
  };
};

export const parseCookies = (
  raw: string
): Record<string, string | object> & {
  CookieScriptConsent?: CookieScriptConsent;
} => {
  if (raw === "") {
    return {};
  }
  return raw.split("; ").reduce((prev, next) => {
    const [cookieName, cookieValue] = next.split("=");
    if (cookieName === COOKIE_SCRIPT_CONSENT_COOKIE) {
      return {
        ...prev,
        CookieScriptConsent: parseCookieScriptConsent(cookieValue),
      };
    }
    return {
      ...prev,
      [cookieName]: cookieValue,
    };
  }, {});
};

export const hasAcceptedAnalyticsCookies = (
  cookieScriptConsent: CookieScriptConsent | undefined
): boolean =>
  cookieScriptConsent?.action === "accept" &&
  cookieScriptConsent.categories.includes("performance");

export const hasAcceptedFunctionalityCookies = (
  cookieScriptConsent: CookieScriptConsent | undefined
): boolean =>
  cookieScriptConsent?.action === "accept" &&
  cookieScriptConsent.categories.includes("functionality");

export const functionalityCookiesAreEnabled = (): boolean => {
  const consentCookie = parseCookies(document.cookie).CookieScriptConsent;
  return consentCookie?.categories
    ? hasAcceptedFunctionalityCookies(consentCookie)
    : false;
};

export const irisCookieIsSet = (): boolean => {
  const cookie = parseCookies(document.cookie)[
    SHOW_IRIS_LOGIN_MODAL_COOKIE
  ] as string;
  return parseStringToBoolean(cookie);
};

export const setCookie = (key: string, value: string): void => {
  const expiry = addYearsToDate(new Date(), 1);
  document.cookie = `${key}=${value};expires=${expiry.toUTCString()}`;
};

export const setBooleanCookie = (key: string, value: boolean): void =>
  setCookie(key, value.toString());

export const setCookieIfNotExists = (key: string, value: string): void => {
  const cookies = parseCookies(document.cookie);
  const cookie = cookies[key];
  if (!cookie) {
    setCookie(key, value);
  }
};

export const deleteCookies = (
  nameStartsWith?: string,
  domain?: string
): void => {
  const cookies = document.cookie.split(";");

  cookies.forEach((cookie) => {
    const cookieName = cookie.split("=")[0].trim();

    // If nameStartsWith is undefined, delete all cookies.
    // If defined, delete only the cookies that start with the provided string.
    if (!nameStartsWith || cookieName.startsWith(nameStartsWith)) {
      // Set the cookie to expire in the past, effectively deleting it
      document.cookie = `${cookieName}=; expires=${new Date().toUTCString()}${
        domain ? "; domain=" + domain : ""
      }`;
    }
  });
};

export const isCookieScriptAcceptEvent = (
  e: Event
): e is CookieScriptAcceptEvent =>
  Array.isArray((e as CookieScriptAcceptEvent)?.detail?.categories);
