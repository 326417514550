import { Point } from "@nivo/line";
import MultiChartLineContainer from "components/dataVisualization/chartComponents/MultiChartLineContainer/MultiChartLineContainer";
import { RequestStatus } from "hooks/useRequest";
import {
  SystemPriceModel,
  SystemPricesDataKey,
} from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import React, { useMemo } from "react";
import {
  transformSystemSellPriceData,
  transformNivData,
} from "utils/ChartDataUtils/systemSellAndBuyPrices/systemSellAndBuyPrices";
import {
  formatNumberToFixedWithoutTrailingZeroes,
  getXTimeValuesExtrema,
} from "utils/chartHelpers";

import NivChart from "./NivChart";
import SellBuyPricesChart from "./SellBuyPricesChart";
import SellBuyPricesTooltip from "./SystemSellBuyPricesTooltip/SystemSellBuyPricesTooltip";
import { SystemPricesMultiChartTitle } from "./style";

interface Props {
  systemPrices: SystemPriceModel[] | null;
  headingsAs?: "h2" | "h3";
  headingsSizeOverride?: string;
  dataFetchStatus?: RequestStatus;
  rerequestData?: () => void | Promise<void>;
}

/**
 * The system prices charts, stacked vertically with a linked tooltip.
 *
 * If no fetch status is supplied, the data is assumed to have been loaded.
 */
const SystemSellBuyPricesCharts: React.FC<Props> = ({
  systemPrices,
  headingsAs = "h2",
  headingsSizeOverride,
  dataFetchStatus = RequestStatus.SUCCESSFUL_OR_NOT_STARTED,
  rerequestData,
}) => {
  const sellBuyData = useMemo(
    () => (systemPrices ? transformSystemSellPriceData(systemPrices) : []),
    [systemPrices]
  );
  const nivData = useMemo(
    () => (systemPrices ? transformNivData(systemPrices) : []),
    [systemPrices]
  );

  const allData = [...sellBuyData, ...nivData];
  const { maxXAxisValue, minXAxisValue } = getXTimeValuesExtrema(allData);

  // The following calculates the chart indent value by multiplying a fixed factor
  // by the maximum number of decimal places present in the y-axis tick values
  const chartIndent = useMemo(() => {
    const maxchartIndent = 30;
    const maxPossibleDecimalPlaces = 9; // based on precision in database
    let maxActualDecimalPlaces = 0;

    for (const dataset of nivData) {
      for (const datapoint of dataset.data) {
        if (typeof datapoint.y === "number") {
          const significantDigitsAfterDecimalPoint =
            formatNumberToFixedWithoutTrailingZeroes(datapoint.y).replace(
              ".",
              ""
            ).length;

          maxActualDecimalPlaces = Math.max(
            maxActualDecimalPlaces,
            significantDigitsAfterDecimalPoint
          );
        }
      }
    }

    return (
      Math.ceil(maxchartIndent / maxPossibleDecimalPlaces) *
      maxActualDecimalPlaces
    );
  }, [nivData]);

  const tooltip = (point: Point): JSX.Element => (
    <SellBuyPricesTooltip
      allChartData={allData}
      minXAxisValue={minXAxisValue}
      maxXAxisValue={maxXAxisValue}
      highlightedPoint={point}
    />
  );

  return (
    <MultiChartLineContainer chartIndent={chartIndent}>
      <>
        <SystemPricesMultiChartTitle
          chartIndent={chartIndent}
          as={headingsAs}
          fontSizeOverride={headingsSizeOverride}
        >
          System Buy / Sell Prices
        </SystemPricesMultiChartTitle>
        <SellBuyPricesChart
          series={sellBuyData.filter(
            (s) => s.id == SystemPricesDataKey.systemSellPrice
          )}
          dataFetchStatus={dataFetchStatus}
          rerequestData={rerequestData}
          tooltip={tooltip}
          chartIndent={chartIndent}
        />
        <SystemPricesMultiChartTitle
          chartIndent={chartIndent}
          as={headingsAs}
          fontSizeOverride={headingsSizeOverride}
        >
          Net Imbalance Volume
        </SystemPricesMultiChartTitle>
        <NivChart
          series={nivData.filter(
            (s) => s.id == SystemPricesDataKey.netImbalanceVolume
          )}
          dataFetchStatus={dataFetchStatus}
          rerequestData={rerequestData}
          tooltip={tooltip}
          chartIndent={chartIndent}
        />
      </>
    </MultiChartLineContainer>
  );
};

export default SystemSellBuyPricesCharts;
