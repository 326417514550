import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { getRemitBulkMessageDetails, getRemitRevisions } from "api/remit/remit";
import { HttpStatusCode } from "axios";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import RemitDataExporter from "components/remit/MessageDetails/RemitDataExporter/RemitDataExporter";
import RemitMessageStatuses from "components/remit/MessageDetails/RemitMessageStatuses/RemitMessageStatuses";
import {
  ContainerDiv,
  LoadingSpinnerDiv,
} from "components/remit/MessageDetails/RemitMessageStatuses/style";
import RemitMessageTiles from "components/remit/MessageDetails/RemitMessageTiles/RemitMessageTiles";
import RemitSidebar from "components/remit/MessageDetails/RemitSidebar/RemitSidebar";
import useRequest, { RequestStatus } from "hooks/useRequest";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import { SelectedMessageRevision } from "pages/RemitMessageDetails/RemitMessageDetails";
import {
  Container,
  TilesContainer,
  WarningContainer,
} from "pages/RemitMessageDetails/style";
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import colours from "styles/colours";
import {
  findAllMessageDifferences,
  generateRevisionHistory,
  getMessageDataById,
  getMessageIdAndData,
  getMessageIds,
  sortArrayByOrderOfListIdentifiers,
} from "utils/remitHelpers";
import routeConstants from "utils/routeConstants";

const RemitMessageDetailsContent: React.FC = () => {
  const { mrid } = useParams();
  const [messageDifferences, setMessageDifferences] = useState<
    (keyof RemitMessageDetailsModel)[][] | null
  >();
  const [urlParams, setUrlParams] = useSearchParams();
  const [latestRevisionId, setLatestRevisionId] = useState<number | null>();
  const [selectedMessageRevision, setSelectedMessageRevision] =
    useState<SelectedMessageRevision>({
      messageId: parseInt(urlParams.get("messageId")!),
      messageData: null,
    });

  const {
    data: revisionsData,
    errorCode,
    request: revisionsRequest,
    status: revisionsDataStatus,
  } = useRequest(getRemitRevisions);

  const {
    data: revisionsMessageDetailsData,
    request: revisionsMessageDetailsRequest,
    status: revisionsMessageDetailsDataStatus,
  } = useRequest(getRemitBulkMessageDetails);

  useEffect(() => {
    revisionsRequest(mrid!);
  }, [mrid, revisionsRequest]);

  useEffect(() => {
    if (revisionsData != null && revisionsMessageDetailsData !== null) {
      const getData = getMessageIdAndData(
        revisionsData,
        revisionsMessageDetailsData,
        urlParams
      );

      setSelectedMessageRevision(getData);
      setMessageDifferences(
        findAllMessageDifferences(
          sortArrayByOrderOfListIdentifiers(
            revisionsMessageDetailsData,
            revisionsData
          )
        )
      );
    }
  }, [revisionsData, revisionsMessageDetailsData, urlParams]);

  useEffect(() => {
    if (revisionsData != null) {
      setLatestRevisionId(revisionsData[0].id);
      revisionsMessageDetailsRequest(getMessageIds(revisionsData));
    }
  }, [revisionsData, revisionsMessageDetailsRequest]);

  if (errorCode === HttpStatusCode.NotFound) {
    return (
      <div>
        Remit message with MessageGroup {mrid} and ID
        {selectedMessageRevision.messageId} not found. Please return to the{" "}
        <Link to={routeConstants.remit}>REMIT landing page</Link>.
      </div>
    );
  }

  if (errorCode === HttpStatusCode.BadRequest) {
    return (
      <div>
        Invalid request. Please return to{" "}
        <Link to={routeConstants.remit}>REMIT landing page</Link>.
      </div>
    );
  }

  if (
    revisionsMessageDetailsDataStatus === RequestStatus.IN_PROGRESS ||
    revisionsDataStatus === RequestStatus.IN_PROGRESS
  ) {
    return (
      <ContainerDiv>
        <LoadingSpinnerDiv>
          <LoadingSpinner isLoading />
        </LoadingSpinnerDiv>
      </ContainerDiv>
    );
  }

  return (
    <>
      {selectedMessageRevision.messageData && revisionsData && (
        <>
          {selectedMessageRevision.messageId !== revisionsData[0].id && (
            <WarningContainer>
              <Icon
                size={IconSize.small}
                colour={colours.focusBlue}
                iconName={faExclamationCircle}
              />
              <p>
                You are <b>not</b> viewing the most recent revision of this
                message
              </p>
            </WarningContainer>
          )}
          <RemitMessageStatuses details={selectedMessageRevision.messageData} />
          <Container>
            <TilesContainer>
              <RemitMessageTiles
                details={selectedMessageRevision.messageData}
              />
              {revisionsMessageDetailsData && latestRevisionId && (
                <RemitDataExporter
                  details={revisionsMessageDetailsData}
                  viewedRevision={selectedMessageRevision.messageData}
                  latestRevision={
                    getMessageDataById(
                      revisionsMessageDetailsData,
                      latestRevisionId
                    ) as RemitMessageDetailsModel
                  }
                />
              )}
            </TilesContainer>
            {latestRevisionId && (
              <RemitSidebar
                revisionHistory={generateRevisionHistory(
                  revisionsData,
                  messageDifferences
                )}
                selectedMessageRevision={selectedMessageRevision}
                setUrlParams={setUrlParams}
                latestRevisionId={latestRevisionId}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default RemitMessageDetailsContent;
