import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SettlementMessagesModel } from "models/settlementMessages/settlementMessagesModel";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import {
  getDatePathsFromDateFilter,
  getPathsFromDateFilter,
  getSettlementPeriodRangesFromDateFilter,
  isInRange,
} from "utils/DateUtils";
import { stitchDataResults, stitchJsonResults } from "utils/dataStitchHelpers";

interface SettlementItem {
  settlementDate: string;
  settlementPeriod: number;
}

const fetchItemsForPaths = <T>(
  pathPrefix: string,
  pathSuffixes: string[],
  format: DataDownloadFormat
): Promise<DataDownloadModel<T>[]> =>
  Promise.all(
    pathSuffixes.map(
      async (path) =>
        (
          await getData(`${pathPrefix}/${path}`, {
            format,
          })
        ).data
    )
  );

/**
 * For JSON calls we fetch for all days in the date filter and then filter the results to avoid
 * too many API calls on page load.
 *
 * This is harder to do for XML and CSV as we'd have to parse the results, so we have not done
 * that for now (it's only used for download anyway). In future we could consider doing this for
 * other formats too, should the performance be an issue.
 */
const getSettlementItemJsonData = async <T extends SettlementItem>(
  pathPrefix: string,
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<T>> => {
  const pathSuffixes = getDatePathsFromDateFilter(dateFilter);
  const results = (await fetchItemsForPaths(
    pathPrefix,
    pathSuffixes,
    DataDownloadFormat.Json
  )) as JsonDataDownloadModel<T>[];
  const stitchedResults = stitchJsonResults(results);

  const dateFilterRanges = getSettlementPeriodRangesFromDateFilter(dateFilter);
  const itemIsWithinDateFilter = (item: T): boolean =>
    dateFilterRanges.some((range) =>
      isInRange(range, item.settlementDate, item.settlementPeriod)
    );

  return {
    ...stitchedResults,
    data: stitchedResults.data.filter(itemIsWithinDateFilter),
  };
};

const getSettlementItemData = async <T extends SettlementItem>(
  pathPrefix: string,
  dateFilter: DateFilterModel,
  format: DataDownloadFormat = DataDownloadFormat.Json
): Promise<DataDownloadModel<T>> => {
  // See comment on getSettlementItemJsonData for rationale for special casing JSON
  if (format === DataDownloadFormat.Json) {
    return getSettlementItemJsonData(pathPrefix, dateFilter);
  }

  const pathSuffixes = getPathsFromDateFilter(dateFilter);
  const results = await fetchItemsForPaths<T>(pathPrefix, pathSuffixes, format);
  return stitchDataResults(results, format);
};

export const getSystemPricesData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat = DataDownloadFormat.Json
): Promise<DataDownloadModel<SystemPriceModel>> =>
  getSettlementItemData<SystemPriceModel>(
    "/balancing/settlement/system-prices",
    dateFilter,
    format
  );

export const getSystemPricesJsonData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<SystemPriceModel>> =>
  getSystemPricesData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<SystemPriceModel>
  >;

export const getSettlementMessagesData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat = DataDownloadFormat.Json
): Promise<DataDownloadModel<SettlementMessagesModel>> =>
  getSettlementItemData<SettlementMessagesModel>(
    "/balancing/settlement/messages",
    dateFilter,
    format
  );

export const getSettlementMessagesJsonData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<SettlementMessagesModel>> =>
  getSettlementMessagesData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<SettlementMessagesModel>
  >;
