import OutageProfile from "models/remit/outageProfile";
import { RemitIdentifierModel } from "models/remit/remitIdentifierModel";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import { RevisionHistoryModel } from "models/remit/revisionHistoryModel";
import { SelectedMessageRevision } from "pages/RemitMessageDetails/RemitMessageDetails";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

export const propertyDisplayNames: Record<string, string> = {
  id: "Message ID",
  mrid: "Message group",
  revisionNumber: "Revision",
  publishTime: "Publish time",
  createdTime: "Created time",
  messageType: "Message type",
  messageHeading: "Message heading",
  eventType: "Event type",
  unavailabilityType: "Unavailability type",
  participantId: "Participant ID",
  registrationCode: "Registration code",
  assetId: "Asset ID",
  assetType: "Asset type",
  affectedUnit: "Affected unit",
  affectedUnitEIC: "Affected unit EIC code",
  affectedArea: "Affected area",
  biddingZone: "Bidding zone",
  fuelType: "Fuel type",
  normalCapacity: "Normal capacity",
  availableCapacity: "Available capacity",
  unavailableCapacity: "Unavailable capacity",
  eventStatus: "Event status",
  eventStartTime: "Event start (UTC)",
  eventEndTime: "Event end (UTC)",
  durationUncertainty: "Duration uncertainty",
  relatedInformation: "Related information",
  cause: "Cause",
  outageProfile: "Outage profile",
};

export const pickProps = <T extends object, K extends keyof T>(
  obj: T,
  props: K[]
): Pick<T, K> => {
  const newObj = {} as Partial<Pick<T, K>>;

  for (let prop of props) {
    newObj[prop] = obj[prop];
  }

  return newObj as Pick<T, K>;
};

export const getDisplayNameFromJsonKey = (key: string): string =>
  propertyDisplayNames[key] || "";

export const isString = (value: unknown): value is string =>
  typeof value === "string";

export const formatRowValue = (
  data: Partial<RemitMessageDetailsModel>,
  key: keyof RemitMessageDetailsModel,
  value: string | number | Date | OutageProfile[] | null
): string => {
  // We check if any of the capacity fields are null when rendering the capacity tile
  // Based on the two message types we always expect to either have all capacity values or none

  if (key === "normalCapacity") {
    return `${value} MW (100%)`;
  }

  const actualCapacity = data.availableCapacity! + data.unavailableCapacity!;
  if (key === "availableCapacity") {
    const availableCapacityPercentage = Math.round(
      (data.availableCapacity! / actualCapacity!) * 100
    );
    return `${value} MW (${availableCapacityPercentage}%)`;
  }

  if (key === "unavailableCapacity") {
    const unavailableCapacityPercentage = Math.round(
      (data.unavailableCapacity! / actualCapacity!) * 100
    );
    return `${value} MW (${unavailableCapacityPercentage}%)`;
  }

  if (key === "eventStartTime" || key === "eventEndTime") {
    if (isString(value)) {
      return formatDateTimeString(
        new Date(value),
        DateFormat.DateTimeWithSeconds
      );
    }
  }
  return value ? value.toString() : "—";
};

export const getMessageDataById = (
  revisionsMessageDetailsData: RemitMessageDetailsModel[] | null,
  messageId: number
): RemitMessageDetailsModel | null => {
  const revisionData =
    revisionsMessageDetailsData &&
    revisionsMessageDetailsData.find(
      (r: RemitMessageDetailsModel) => r.id === messageId
    );

  return revisionData ?? null;
};

export const getMessageIdAndData = (
  revisionData: RemitIdentifierModel[] | null,
  revisionsMessageDetailsData: RemitMessageDetailsModel[] | null,
  searchParams: URLSearchParams
): SelectedMessageRevision => {
  let messageId = parseInt(searchParams.get("messageId")!);
  if (isNaN(messageId)) {
    if (revisionData) {
      messageId = revisionData[0].id;
    }
  }
  const messageData =
    revisionsMessageDetailsData &&
    getMessageDataById(revisionsMessageDetailsData, messageId);
  return { messageId: messageId, messageData: messageData };
};

export const getMessageIds = (
  revisionsData: RemitIdentifierModel[]
): number[] => revisionsData.map((revision) => revision.id);

export const compareMessageObjects = <T extends {}>(
  obj1: T,
  obj2: T
): (keyof T)[] => {
  const keys = Object.keys(obj1) as (keyof T)[];
  const keysWithChanges: (keyof T)[] = [];

  const omitKeys = [
    "id",
    "revisionNumber",
    "publishTime",
    "createdTime",
    "dataset",
  ];

  for (const key of keys) {
    if (!omitKeys.includes(key.toString()) && obj1[key] !== obj2[key]) {
      keysWithChanges.push(key);
    }
  }

  return keysWithChanges;
};

export const findAllMessageDifferences = <T extends {}>(
  data: T[]
): (keyof T)[][] => {
  const differences: (keyof T)[][] = [];

  for (let i = 0; i < data.length - 1; i++) {
    const obj1 = data[i];
    const obj2 = data[i + 1];
    const objDifferences = compareMessageObjects(obj1, obj2);
    differences.push(objDifferences);
  }

  return differences;
};

export const sortArrayByOrderOfListIdentifiers = (
  unsortedArray: RemitMessageDetailsModel[],
  firstArray: RemitIdentifierModel[]
): RemitMessageDetailsModel[] =>
  [...unsortedArray].sort((a, b) => {
    const indexA = firstArray.findIndex((r) => r.id === a.id);
    const indexB = firstArray.findIndex((r) => r.id === b.id);
    return indexA - indexB;
  });

export const generateRevisionOption = (
  revision: RemitMessageDetailsModel
): { inlineLabelContent: JSX.Element; inputId: string } => {
  const labelContent = (
    <span key={`message-id-${revision.id}-data-element`}>
      {`${formatDateTimeString(new Date(revision.createdTime))} - Revision ${
        revision.revisionNumber
      }`}
    </span>
  );

  return {
    inputId: revision.id.toString(),
    inlineLabelContent: labelContent,
  };
};

export const generateRevisionHistory = (
  revisionsData: RemitIdentifierModel[],
  messageDifferences: (keyof RemitMessageDetailsModel)[][] | null | undefined
): RevisionHistoryModel[] =>
  revisionsData.map((revision, i) => {
    const revisionChanges =
      messageDifferences && messageDifferences[i] ? messageDifferences[i] : [];

    return {
      url: revision.url,
      id: revision.id,
      mrid: revision.mrid,
      revisionNumber: revision.revisionNumber,
      createdTime: revision.createdTime,
      publishTime: revision.publishTime,
      changesFromPreviousRevision: revisionChanges,
    };
  });

const capacityFormatter = new Intl.NumberFormat("en-GB", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

const getScaledCapacity = (
  capacity: number
): { unit: string; value: number } => {
  if (capacity >= 1e6) {
    return { unit: "TW", value: capacity / 1e6 };
  } else if (capacity >= 1e3) {
    return { unit: "GW", value: capacity / 1e3 };
  } else {
    return { unit: "MW", value: capacity };
  }
};

export const formatCapacity = (capacity: number): string => {
  const { unit, value } = getScaledCapacity(capacity);
  return `${capacityFormatter.format(value)}${unit}`;
};
