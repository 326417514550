import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import { SpinnerContainer } from "components/components/LoadingSpinner/style";
import DataLoadFailedMessage from "components/shared/DataLoadFailedMessage/DataLoadFailedMessage";
import { RequestStatus } from "hooks/useRequest";
import React, { PropsWithChildren } from "react";

import { ComponentPopover } from "./style";

export interface ErrorHandlingProps {
  dataFetchStatus: RequestStatus;
  rerequestData?: () => void | Promise<void>;
}

interface Props extends ErrorHandlingProps, PropsWithChildren<unknown> {
  dataIsEmpty?: boolean;
  emptyDataPlaceholder?: React.ReactNode;
  totalRows?: number;
  maxRows?: number;
}

/**
 * Must be the child of an element with position: relative
 */
const ComponentWithRequest: React.FC<Props> = ({
  dataFetchStatus,
  rerequestData,
  dataIsEmpty,
  emptyDataPlaceholder,
  totalRows,
  maxRows,
  children,
}: Props) => {
  const tooMuchData = !!maxRows && !!totalRows && totalRows > maxRows;

  return (
    <>
      <SpinnerContainer>
        <LoadingSpinner
          isLoading={dataFetchStatus === RequestStatus.IN_PROGRESS}
        />
      </SpinnerContainer>
      {dataFetchStatus === RequestStatus.ERRORED && (
        <ComponentPopover>
          <DataLoadFailedMessage
            rerequestData={rerequestData}
            buttonDataTestId="gen-component-error-msg"
          />
        </ComponentPopover>
      )}
      {dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED &&
        dataIsEmpty && (
          <ComponentPopover>
            {emptyDataPlaceholder || (
              <h3 data-test-id="gen-component-no-data-msg">No data to show</h3>
            )}
          </ComponentPopover>
        )}
      {dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED &&
        tooMuchData && (
          <ComponentPopover>
            <h3>
              Your search yielded {totalRows.toLocaleString()} results, but only{" "}
              {maxRows.toLocaleString()} results can be shown at a time.
            </h3>
            <h3>Please refine your search using the filters provided.</h3>
          </ComponentPopover>
        )}
      {children}
    </>
  );
};

export default ComponentWithRequest;
