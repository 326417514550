import classNames from "classnames";
import LogoBanner from "components/layout/LogoBanner/LogoBanner";
import NavLink from "components/layout/NavMenu/NavLink/NavLink";
import NestedNavLink from "components/layout/NavMenu/NestedNavLink/NestedNavLink";
import {
  FlexDiv,
  NavGroupContainer,
  StyledNav,
} from "components/layout/NavMenu/style";
import TestBanner from "components/layout/TestBanner/TestBanner";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import featureFlags from "utils/featureFlags";
import routeConstants, {
  balancingRoutes,
  dataServicesRoutes,
  demandRoutes,
  generationRoutes,
  noticesRoutes,
  transmissionRoutes,
} from "utils/routeConstants";

const NavMenu: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleNavbar = (): void => {
    setExpanded((e) => !e);
  };
  const handleNestedLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    setExpanded(false);
    event.currentTarget.blur();
  };

  const currentPath = useLocation().pathname;

  return (
    <>
      <LogoBanner onClick={handleNestedLinkClick} />
      <StyledNav className="navbar">
        <div className="navbar-brand">
          <button
            className="debutton navbar-burger"
            type="button"
            aria-label="menu"
            aria-expanded={expanded}
            data-target="navbarBasicExample"
            onClick={toggleNavbar}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>
        <NavGroupContainer
          className={classNames("navbar-menu", { "is-active": expanded })}
        >
          <FlexDiv data-test-id="navbar">
            <ul className="navbar-start">
              <NavLink
                linkText="REMIT"
                linkTo="/remit"
                isActive={currentPath.startsWith("/remit")}
              />
              <NavLink
                linkText="GENERATION"
                isActive={Object.values(generationRoutes).some((value) =>
                  currentPath.startsWith(value)
                )}
              >
                <ul>
                  <NestedNavLink
                    linkText="Generation by fuel type"
                    linkTo={routeConstants.generationByFuelType}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Interconnector flows"
                    linkTo={routeConstants.interconnectorFlows}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Generation forecast"
                    linkTo={routeConstants.generationForecast}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Generation forecast for wind & solar"
                    linkTo={routeConstants.generationForecastForWindAndSolar}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Wind generation"
                    linkTo={routeConstants.windGeneration}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Actual aggregated generation per type"
                    linkTo={routeConstants.actualGenerationPerType}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Actual or estimated wind and solar power generation"
                    linkTo={
                      routeConstants.actualOrEstimatedWindAndSolarPowerGeneration
                    }
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Day-ahead aggregated generation"
                    linkTo={routeConstants.dayAheadAggregatedGeneration}
                    onClick={handleNestedLinkClick}
                  />
                </ul>
              </NavLink>
              <NavLink
                linkText="DEMAND"
                isActive={Object.values(demandRoutes).some((value) =>
                  currentPath.startsWith(value)
                )}
              >
                <ul>
                  <NestedNavLink
                    linkText="Rolling system demand"
                    linkTo={routeConstants.rollingSystemDemand}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Surplus forecast and margin"
                    linkTo={routeConstants.surplusAndMargin}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Demand outturn"
                    linkTo={routeConstants.demandOutturn}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Daily energy transmitted"
                    linkTo={routeConstants.dailyEnergyTransmitted}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Demand forecast"
                    linkTo={routeConstants.demandForecast}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Indicative peak demand"
                    linkTo={routeConstants.indicativeDemandPeak}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Total load: actual and day-ahead forecast"
                    linkTo={routeConstants.totalLoadActualAndDayAhead}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Total load: week-ahead forecast"
                    linkTo={routeConstants.totalLoadWeekAhead}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Indicated forecasts"
                    linkTo={routeConstants.indicatedForecasts}
                    onClick={handleNestedLinkClick}
                  />
                </ul>
              </NavLink>
              <NavLink
                linkText="BALANCING"
                isActive={Object.values(balancingRoutes).some((value) =>
                  currentPath.startsWith(value)
                )}
              >
                <ul>
                  <NestedNavLink
                    linkText="Short-term operating reserves"
                    linkTo={routeConstants.shortTermOperatingReserves}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Balancing Mechanism data by BMU view"
                    linkTo={routeConstants.bmuView}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Balancing Mechanism data by market view"
                    linkTo={routeConstants.marketView}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Adjustment data (NETBSAD)"
                    linkTo={routeConstants.adjustmentDataNetbsad}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Adjustment actions (DISBSAD)"
                    linkTo={routeConstants.adjustmentActionsDisbsad}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Market index prices"
                    linkTo={routeConstants.marketIndexPrices}
                    onClick={handleNestedLinkClick}
                  />
                  <>
                    <NestedNavLink
                      linkText="Derived data"
                      linkTo={routeConstants.derivedData}
                      onClick={handleNestedLinkClick}
                    />
                    <NestedNavLink
                      linkText="Detailed system prices"
                      linkTo={routeConstants.detailedSystemPrices}
                      onClick={handleNestedLinkClick}
                    />
                    <NestedNavLink
                      linkText="System sell and buy prices"
                      linkTo={routeConstants.systemPrices}
                      onClick={handleNestedLinkClick}
                    />
                  </>
                </ul>
              </NavLink>
              <NavLink
                linkText="NOTICES"
                isActive={Object.values(noticesRoutes).some((value) =>
                  currentPath.startsWith(value)
                )}
              >
                <ul>
                  <NestedNavLink
                    linkText="Credit default notices"
                    linkTo={routeConstants.creditDefaultNotices}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Events"
                    linkTo={routeConstants.events}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Settlement messages"
                    linkTo={routeConstants.settlementMessages}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="SO-SO trade prices"
                    linkTo={routeConstants.sosoTradePrices}
                    onClick={handleNestedLinkClick}
                  />
                </ul>
              </NavLink>
              <NavLink
                linkText="TRANSMISSION"
                isActive={Object.values(transmissionRoutes).some((value) =>
                  currentPath.startsWith(value)
                )}
              >
                <ul>
                  <NestedNavLink
                    linkText="Rolling system frequency"
                    linkTo={routeConstants.rollingSystemFrequency}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Temperature"
                    linkTo={routeConstants.temperature}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Loss of load probability (LoLP) and de-rated margin"
                    linkTo={routeConstants.lolpdrm}
                    onClick={handleNestedLinkClick}
                  />
                </ul>
              </NavLink>
              <NavLink
                linkText="DATA SERVICES"
                isActive={Object.values(dataServicesRoutes).some((value) =>
                  currentPath.startsWith(value)
                )}
              >
                <ul>
                  <NestedNavLink
                    linkText="API documentation"
                    linkTo={routeConstants.apiDocs}
                    onClick={handleNestedLinkClick}
                  />
                  <NestedNavLink
                    linkText="Insights Real-Time Information Service"
                    linkTo={routeConstants.iris}
                    onClick={handleNestedLinkClick}
                  />
                </ul>
              </NavLink>
              <NavLink
                linkText="WHAT'S NEW"
                linkTo={routeConstants.whatsNew}
                isActive={currentPath.startsWith(routeConstants.whatsNew)}
              />
            </ul>
          </FlexDiv>
        </NavGroupContainer>
      </StyledNav>
      {featureFlags.displayTestBanner && <TestBanner />}
    </>
  );
};

export default NavMenu;
