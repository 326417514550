import { Link } from "react-router-dom";
import styled from "styled-components";
import colours from "styles/colours";

/**
 * An internal link (using Link from react-router) that renders as an underlined blue link.
 */
const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${colours.linkColor};
`;

export default StyledLink;
