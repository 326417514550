import {
  RemitFilterEndpoint,
  RemitFilters,
  RevisionHistory,
  TimeRangeLength,
  TimeRangeType,
} from "components/components/Remit/RemitFiltersForm/remitFilters";
import DateFilterModel from "models/filterModels/dateFilterModel";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import { RemitTableModel } from "models/remit/remitTableModel";
import { compareDates } from "utils/dateHelpers";
import routeConstants from "utils/routeConstants";

import { defaultEndDate, defaultStartDate } from "./remitConfig";

const mapRemitDetailsUrl = (model: RemitMessageDetailsModel): string =>
  `${routeConstants.remitMessageDetails}/${model.mrid}?messageId=${model.id}`;

type ReadableRadioButtonType = { [key: string]: string };
export const readableRadioButton: ReadableRadioButtonType = {
  [RemitFilterEndpoint.Event]: "Event date",
  [RemitFilterEndpoint.Publish]: "Published date",
  [RevisionHistory.Include]: "Include revision history",
  [RevisionHistory.LatestOnly]: "Latest revision only",
};

export const defaultStringRemitFilters = {
  timeRangeType: TimeRangeType.Previous,
  timeRangeLength: TimeRangeLength.Day,
  endpoint: RemitFilterEndpoint.Publish,
  revisionHistory: RevisionHistory.Include,
};

export const defaultRemitFilters = {
  ...defaultStringRemitFilters,
  messageType: [],
  unavailabilityType: [],
  eventType: [],
  fuelType: [],
  participantID: null,
  assetID: null,
  messageID: null,
  dateFilter: new DateFilterModel(defaultStartDate, defaultEndDate),
};

export const mapToRemitRow = (
  remitData: RemitMessageDetailsModel[]
): RemitTableModel[] =>
  remitData.map((row) => ({
    id: row.id,
    publishTime: new Date(row.publishTime),
    mrid: row.mrid,
    participantId: row.participantId,
    assetId: row.assetId,
    eventStartTime: new Date(row.eventStartTime),
    eventEndTime: row.eventEndTime ? new Date(row.eventEndTime) : null,
    availableCapacity: row.availableCapacity,
    fuelType: row.fuelType,
    unavailabilityType: row.unavailabilityType,
    messageType: row.messageType,
    eventType: row.eventType,
    eventStatus: row.eventStatus,
    messageId: row.id,
    url: mapRemitDetailsUrl(row),
  }));

const getDateFilterParam = (searchParams: URLSearchParams): DateFilterModel =>
  new DateFilterModel(
    new Date(searchParams.get("from") ?? defaultStartDate),
    new Date(searchParams.get("to") ?? defaultEndDate)
  );

const getParamOrDefault = (
  param: keyof typeof defaultStringRemitFilters,
  searchParams: URLSearchParams
): string => searchParams.get(param) ?? defaultStringRemitFilters[param];

const getRevisionHistoryParam = (
  searchParams: URLSearchParams
): RevisionHistory =>
  searchParams.get("revisionHistory") == RevisionHistory.LatestOnly
    ? RevisionHistory.LatestOnly
    : RevisionHistory.Include;

const getParamListOrDefault = (
  param: keyof typeof defaultRemitFilters,
  searchParams: URLSearchParams
): string[] => searchParams.get(param)?.split(",") ?? [];

export const getRemitFiltersFromUrlParams = (
  searchParams: URLSearchParams
): RemitFilters => ({
  timeRangeType: getParamOrDefault("timeRangeType", searchParams),
  timeRangeLength: getParamOrDefault("timeRangeLength", searchParams),
  messageType: getParamListOrDefault("messageType", searchParams),
  unavailabilityType: getParamListOrDefault("unavailabilityType", searchParams),
  eventType: getParamListOrDefault("eventType", searchParams),
  fuelType: getParamListOrDefault("fuelType", searchParams),
  participantID: searchParams.get("participantID"),
  assetID: searchParams.get("assetID"),
  messageID: searchParams.get("messageID"),
  endpoint: getParamOrDefault("endpoint", searchParams),
  revisionHistory: getRevisionHistoryParam(searchParams),
  dateFilter: getDateFilterParam(searchParams),
});

export const getUrlParamsFromRemitFilters = (
  filters: RemitFilters
): { [key: string]: string } => {
  const {
    timeRangeType,
    timeRangeLength,
    participantID,
    assetID,
    messageID,
    endpoint,
    revisionHistory,
  } = filters;

  const queryParams: { [key: string]: string } = {
    ...(timeRangeType && { timeRangeType }),
    ...(timeRangeLength && { timeRangeLength }),
    ...(participantID && { participantID }),
    ...(assetID && { assetID }),
    ...(messageID && { messageID }),
    ...(endpoint && { endpoint }),
    ...(revisionHistory && { revisionHistory }),
  };

  queryParams.from = filters.dateFilter.startDate.toISOString();
  queryParams.to = filters.dateFilter.endDate.toISOString();

  Object.entries(filters).forEach(([filterName, filterValues]) => {
    if (Array.isArray(filterValues) && filterValues.length > 0) {
      queryParams[filterName] = filterValues.join(",");
    }
  });

  return queryParams;
};

export const mridFilter = (
  r: RemitTableModel,
  mridFilterValue: string | null
): boolean => !mridFilterValue || r.mrid.includes(mridFilterValue);

export const sortRemitData = (
  remitData: RemitTableModel[],
  endpoint: string = RemitFilterEndpoint.Publish
): RemitTableModel[] =>
  [...remitData].sort((previous, next) =>
    endpoint === RemitFilterEndpoint.Publish
      ? compareDates(previous.publishTime, next.publishTime)
      : compareDates(previous.eventStartTime, next.eventStartTime)
  );
