import SettlementPeriodSelector from "components/components/SettlementPeriodSelector/SettlementPeriodSelector";
import { DerivedDataTabName } from "components/components/Tabs/Tabs/Tabs";
import { DerivedDataExporter } from "components/dataVisualization/DerivedData/DataExporter/DerivedDataExporter";
import DerivedDataFilter from "components/dataVisualization/DerivedData/DerivedDataFilter/DerivedDataFilter";
import { RequestStatus } from "hooks/useRequest";
import { BmuModel } from "models/bmuData/bmuModel";

import { StyledDiv } from "./style";

interface Props {
  selectedPeriod: Date;
  setSelectedPeriod: (newDate: Date) => void;
  maxDate: Date;
  bmuData: BmuModel[];
  bmuStatus: RequestStatus;
  derivedDataFilters?: BmuModel[];
  setDerivedDataFilters: (filters: BmuModel[] | undefined) => void;
  activeTab: DerivedDataTabName;
}

export const DerivedDataFilterContainer: React.FC<Props> = ({
  selectedPeriod,
  setSelectedPeriod,
  maxDate,
  bmuData,
  bmuStatus,
  derivedDataFilters,
  setDerivedDataFilters,
  activeTab,
}) => (
  <StyledDiv className="container px-6">
    <DerivedDataFilter
      bmuData={bmuData}
      bmuStatus={bmuStatus}
      derivedDataFilters={derivedDataFilters}
      setDerivedDataFilters={setDerivedDataFilters}
    >
      <SettlementPeriodSelector
        selectedDate={selectedPeriod}
        handleChangeToDateSelection={setSelectedPeriod}
        maxDate={maxDate}
      />
    </DerivedDataFilter>
    <DerivedDataExporter
      selectedDate={selectedPeriod}
      bmus={derivedDataFilters}
      activeTab={activeTab}
    />
  </StyledDiv>
);
