import { isRejected } from "utils/promiseHelpers";

export type DataErrored = {
  isErrored: true;
};

export type DataSuccess<T> = {
  isErrored: false;
  data: T;
};

export type DataOrErrored<T> = DataErrored | DataSuccess<T>;

/**
 * The type of the object with the same keys as TData, but whose values are:
 * - (if DataOrErrored) the type of data in the DataOrErrored type
 * - (if not DataOrErrored) the same type as the original value
 * e.g. type TData
 * {
 *   data1: DataOrErrored<T1>,
 *   data2: DataOrErrored<T2>,
 *   data3: string
 * }
 * maps to type DataExtract<TData>
 * {
 *   data1: T1,
 *   data2: T2,
 *   data3: string
 * }
 */
export type DataExtract<TData> = {
  [K in keyof TData]: TData[K] extends DataOrErrored<infer U> ? U : TData[K];
};

export const isDataOrErroredObject = <T>(
  dataOrErrored: unknown
): dataOrErrored is DataOrErrored<T> =>
  typeof dataOrErrored === "object" &&
  dataOrErrored !== null &&
  "isErrored" in dataOrErrored;

export const dataIsSuccess = <T>(
  dataOrErrored: DataOrErrored<T>
): dataOrErrored is DataSuccess<T> => !dataOrErrored.isErrored;

export const dataIsErrored = <T>(
  dataOrErrored: DataOrErrored<T>
): dataOrErrored is DataErrored => dataOrErrored.isErrored;

export const extractDataFromPromise = <TResult, TData>(
  dataPromise: PromiseSettledResult<TResult>,
  transform: (result: TResult) => TData
): DataOrErrored<TData> =>
  isRejected(dataPromise)
    ? {
        isErrored: true,
      }
    : {
        isErrored: false,
        data: transform(dataPromise.value),
      };
