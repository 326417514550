import React from "react";
import { StyledComponentsPolymorphicProp } from "utils/commonTypes";

import { PanelContainer } from "./style";

interface Props {
  colour?: string;
  className?: string;
  as?: StyledComponentsPolymorphicProp;
  children: React.ReactNode;
  testId?: string;
}

/**
 * Panel component which puts content inside a box with a coloured top border.
 *
 * Used for 'what's new' articles, summary tiles and the homepage sections.
 *
 * For use with the Bulma tile layout use TileChild.tsx.
 */
const Panel: React.FC<Props> = ({
  colour,
  className,
  as,
  children,
  testId,
}) => (
  <PanelContainer
    colour={colour}
    className={className}
    as={as}
    data-test-id={testId}
  >
    {children}
  </PanelContainer>
);

export default Panel;
