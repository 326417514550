import { Serie } from "@nivo/line";
import { AgwsData } from "models/actualOrEstimatedWindAndSolarPowerGeneration/ActualOrEstimatedWindAndSolarPowerGeneration";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { chartColours } from "styles/colours";

export const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [
      {
        colour: chartColours.agwsWindOnshore,
        dataKey: "wind-onshore",
        displayName: "Wind Onshore",
      },
      {
        colour: chartColours.agwsWindOffshore,
        dataKey: "wind-offshore",
        displayName: "Wind Offshore",
      },
      {
        colour: chartColours.agwsSolar,
        dataKey: "solar",
        displayName: "Solar",
      },
    ],
    id: "AGWS",
    title: "Generation",
  },
];

export const transformAgwsDataToSerie = (
  rawData: AgwsData[],
  datasetCategoriesToDisplay: DatasetCategory<ChartDatasetModel>[]
): Serie[] =>
  datasetCategoriesToDisplay[0].datasets.map((series) => ({
    id: series.dataKey,
    colour: series.colour,
    data: rawData
      .filter((data) => data.psrType === series.displayName)
      .map((data) => ({
        x: new Date(data.startTime),
        y: data.quantity,
        settlementPeriod: data.settlementPeriod,
      })),
  }));
