import { getCreditDefaultNoticeJsonData } from "api/dataVisualisation/creditDefaultNotice/creditDefaultNotice";
import {
  getSettlementMessagesJsonData,
  getSystemPricesJsonData,
} from "api/dataVisualisation/settlementSystemPricesAndMessages/settlementSystemPricesAndMessages";
import { getSystemWarningsJsonData } from "api/dataVisualisation/systemWarnings/systemWarnings";
import { getRemitTableJsonData } from "api/remit/remit";
import PageMeta from "components/components/Meta/PageMeta";
import { RemitFilters } from "components/components/Remit/RemitFiltersForm/remitFilters";
import { getTransformedMessageData } from "components/dataVisualization/DetailedSystemPrices/utils";
import CreditDefaultNotices, {
  CreditDefaultNoticesData,
} from "components/homepage/CreditDefaultNotices/CreditDefaultNotices";
import Events, { EventsData } from "components/homepage/Events/Events";
import Remit, { RemitData } from "components/homepage/Remit/Remit";
import SystemSellBuyPrices, {
  SystemSellBuyPriceData,
} from "components/homepage/SystemSellBuyPrices/SystemSellBuyPrices";
import { defaultRemitFilters } from "components/remit/remitLandingPageHelpers";
import LastUpdatedWithRefresh from "components/shared/LastUpdated/LastUpdatedWithRefresh/LastUpdatedWithRefresh";
import useDataWithRefresh from "hooks/useDataWithRefresh";
import {
  CreditDefaultNoticeModelTransformed,
  getTransformedCreditDefaultNotice,
} from "models/creditDefaultNotice/creditDeafultNoticeTransformed";
import { SettlementMessageModelTransformed } from "models/detailedSystemPrices/settlementModels";
import DateFilterModel from "models/filterModels/dateFilterModel";
import RemitMessageDetailsModelTransformed, {
  getTransformedRemitMessageDetail,
} from "models/remit/remitMessageDetailsModelTransformed";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";
import React, { useCallback } from "react";
import { DataOrErrored, extractDataFromPromise } from "utils/dataOrErrored";
import {
  addDaysToDate,
  addHoursToDate,
  ceilToHalfHour,
} from "utils/dateHelpers";
import { isRejected } from "utils/promiseHelpers";

export type HomepageData = {
  systemWarnings: DataOrErrored<SystemWarningsModel[]>;
  systemPrices: DataOrErrored<SystemPriceModel[]>;
  settlementMessages: DataOrErrored<SettlementMessageModelTransformed[]>;
  remitMessages: DataOrErrored<RemitMessageDetailsModelTransformed[]>;
  creditDefaultNotices: DataOrErrored<CreditDefaultNoticeModelTransformed[]>;
  systemPricesDateFilter: DateFilterModel;
};

const automaticRefreshIntervalInMinutes = 5;

const eventsDataSelector = (homepageData: HomepageData | null): EventsData =>
  homepageData
    ? {
        systemWarnings: homepageData.systemWarnings,
      }
    : null;

const systemSellBuyPricesDataSelector = (
  homepageData: HomepageData | null
): SystemSellBuyPriceData =>
  homepageData
    ? {
        systemPrices: homepageData.systemPrices,
        settlementMessages: homepageData.settlementMessages,
        systemPricesDateFilter: homepageData.systemPricesDateFilter,
      }
    : null;

const creditDefaultNoticesDataSelector = (
  homepageData: HomepageData | null
): CreditDefaultNoticesData =>
  homepageData
    ? {
        creditDefaultNotices: homepageData.creditDefaultNotices,
      }
    : null;

const remitMessagesSelector = (homepageData: HomepageData | null): RemitData =>
  homepageData ? { remitMessages: homepageData.remitMessages } : null;

const Homepage: React.FC = () => {
  const fetchData = useCallback(async (date: Date): Promise<HomepageData> => {
    // Events
    const dateFilterOneWeek = new DateFilterModel(
      addDaysToDate(date, -7),
      date
    );
    const systemWarningsApiPromise = getSystemWarningsJsonData(
      dateFilterOneWeek,
      ""
    );

    // System sell/buy prices
    const startOfNextSettlementPeriod = ceilToHalfHour(date); // matches end date for sell/buy prices page (in SystemSellBuyPricesChartContainer)
    const systemPricesFilter = new DateFilterModel(
      addDaysToDate(startOfNextSettlementPeriod, -1),
      startOfNextSettlementPeriod
    );
    const systemPricesApiPromise = getSystemPricesJsonData(systemPricesFilter);
    const settlementMessagesApiPromise =
      getSettlementMessagesJsonData(systemPricesFilter);

    // REMIT
    const dateFilterHalfDay = new DateFilterModel(
      addHoursToDate(date, -12),
      date
    );
    const halfDayRemitFilter: RemitFilters = {
      ...defaultRemitFilters,
      unavailabilityType: ["Unplanned"],
      dateFilter: dateFilterHalfDay,
    };
    const remitMessagesApiPromise = getRemitTableJsonData(halfDayRemitFilter);
    const creditDefaultNoticesApiPromise = getCreditDefaultNoticeJsonData();

    const results = await Promise.allSettled([
      systemWarningsApiPromise,
      systemPricesApiPromise,
      settlementMessagesApiPromise,
      remitMessagesApiPromise,
      creditDefaultNoticesApiPromise,
    ]);
    // If all API requests failed, then essentially the whole request failed and we want to show
    // an error rather than updating the data - handle this case separately
    if (results.every(isRejected)) {
      throw new Error("All homepage API requests failed.");
    }
    return {
      systemWarnings: extractDataFromPromise(results[0], (res) => res.data),
      systemPrices: extractDataFromPromise(results[1], (res) => res.data),
      settlementMessages: extractDataFromPromise(results[2], (res) =>
        getTransformedMessageData(res.data)
      ),
      remitMessages: extractDataFromPromise(results[3], (res) =>
        res.data.map((item) => getTransformedRemitMessageDetail(item))
      ),
      creditDefaultNotices: extractDataFromPromise(results[4], (res) =>
        res.data.map((item) => getTransformedCreditDefaultNotice(item))
      ),
      systemPricesDateFilter: systemPricesFilter,
    };
  }, []);

  const {
    data: homepageData,
    requestStatus,
    lastRefreshedDate,
    triggerDataRefresh,
  } = useDataWithRefresh(fetchData, automaticRefreshIntervalInMinutes);

  return (
    <>
      <PageMeta
        title="Home page"
        description="New home page, only available on dev and test environments"
      />
      {/* Add the section classname (defined in the scss by bulma styles) so that the padding matches other pages */}
      <div className="section">
        <h1 data-test-id="summary-heading">Welcome to Insights Solution</h1>
        <p>
          The Insights Solution collects and publishes operational data about
          the electricity system in Great Britain.
        </p>

        <LastUpdatedWithRefresh
          triggerDataRefresh={triggerDataRefresh}
          lastRefreshedDate={lastRefreshedDate}
          requestStatus={requestStatus}
        />
        <Events
          requestStatus={requestStatus}
          data={eventsDataSelector(homepageData)}
          lastRefreshedDate={lastRefreshedDate}
        />
        <Remit
          requestStatus={requestStatus}
          data={remitMessagesSelector(homepageData)}
          lastRefreshedDate={lastRefreshedDate}
        />
        <SystemSellBuyPrices
          requestStatus={requestStatus}
          data={systemSellBuyPricesDataSelector(homepageData)}
          lastRefreshedDate={lastRefreshedDate}
        />
        <CreditDefaultNotices
          requestStatus={requestStatus}
          data={creditDefaultNoticesDataSelector(homepageData)}
          lastRefreshedDate={lastRefreshedDate}
        />
      </div>
    </>
  );
};

export default Homepage;
