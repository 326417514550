import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import DerivedDataTable from "components/dataVisualization/DerivedData/DerivedDataTable/DerivedDataTable";
import {
  mapIndicativeVolumeData,
  pairHeaders,
} from "components/dataVisualization/DerivedData/utils";
import { RequestStatus } from "hooks/useRequest";
import {
  IndicativeVolumeModel,
  IndicativeVolumeRow,
} from "models/derivedData/indicativeVolumeModel";
import { useCallback, useMemo } from "react";
import { combineRequestStatuses } from "utils/requestHelpers";

interface Props {
  bidRequest: {
    fetchData: () => void;
    data: IndicativeVolumeModel[] | null;
    status: RequestStatus;
  };
  offerRequest: {
    fetchData: () => void;
    data: IndicativeVolumeModel[] | null;
    status: RequestStatus;
  };
}

const IndicativeVolumeTab: React.FC<Props> = ({
  bidRequest: { data: rawBidData, fetchData: fetchBidData, status: bidStatus },
  offerRequest: {
    data: rawOfferData,
    fetchData: fetchOfferData,
    status: offerStatus,
  },
}) => {
  const fetchData = useCallback((): void => {
    fetchBidData();
    fetchOfferData();
  }, [fetchBidData, fetchOfferData]);

  const mappedData = useMemo(
    () => mapIndicativeVolumeData(rawBidData, rawOfferData),
    [rawBidData, rawOfferData]
  );
  const status = combineRequestStatuses(bidStatus, offerStatus);

  return (
    <TableWithErrorHandling
      data={mappedData}
      dataFetchStatus={status}
      rerequestData={fetchData}
      aria-label="indicative-volume-table"
    >
      <DerivedDataTable<IndicativeVolumeRow>
        headers={{
          bmUnit: "BMU ID",
          leadPartyName: "Lead Party Name",
          bidOffer: "Bid/Offer",
          dataType: "Data Type",
          totalVolumeAccepted: "Total",
          ...pairHeaders,
          bmUnitType: "BMU Type",
          nationalGridBmUnit: "NG BMU Name",
        }}
        mappedData={mappedData}
      />
    </TableWithErrorHandling>
  );
};

export default IndicativeVolumeTab;
