import ShowMoreText from "components/shared/ShowMoreText/ShowMoreText";
import styled from "styled-components";
import fonts from "styles/fonts";

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
`;

export const HorizontalLayout = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const EventInfo = styled.div`
  width: 100%;
`;

export const StyledShowMoreText = styled(ShowMoreText)`
  font-size: ${fonts.smallMedium};
`;
