import { getSystemPricesData } from "api/dataVisualisation/settlementSystemPricesAndMessages/settlementSystemPricesAndMessages";
import useSystemSellBuyPricesChartContainerData from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesChartContainer/useSystemSellBuyPricesChartContainerData";
import SystemSellBuyPricesCharts from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesCharts/SystemSellBuyPricesCharts";
import {
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addDaysToDate,
  ceilToHalfHour,
  DAYS_IN_ONE_WEEK,
} from "utils/dateHelpers";

const dateSelectorTabs = [DateSelectorTabs.OneWeek];

const SystemSellBuyPricesChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.OneWeek
  );

  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addDaysToDate(defaultEndDate, -7), defaultEndDate)
  );

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter.restrictToMaxOneWeek());
    setActiveDateSelectorTab(newActiveTab);
  };

  const { systemPrices, dataFetchStatus, rerequestData } =
    useSystemSellBuyPricesChartContainerData(dateFilter);

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getSystemPricesData(dateFilter, fileFormat),
      `SystemSellAndBuyPrices-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartHeader>
      <SystemSellBuyPricesCharts
        systemPrices={systemPrices}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      />
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartFooter>
    </>
  );
};

export default SystemSellBuyPricesChartContainer;
