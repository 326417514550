import React, { useCallback, useEffect, useState } from "react";
import {
  formatUTCDuration,
  getDurationFromStartAndEndDatesUTC,
} from "utils/dateHelpers";

import { LastUpdatedTextStyle } from "./style";

const lastUpdatedTextMap = {
  noData: "no data loaded",
  updating: "updating...",
  justNow: "updated just now",
  durationAgo: (formattedDuration: string): string =>
    `last updated ${formattedDuration} ago`,
};

const getLastUpdatedText = (
  isLoading: boolean,
  lastRefreshedDate?: Date
): string => {
  if (isLoading) {
    return lastUpdatedTextMap.updating;
  }
  if (!lastRefreshedDate) {
    // Usually the first data fetch will be done on page load so this will not
    // show, however we allow for the initial load being initiated later.
    return lastUpdatedTextMap.noData;
  }

  const duration = getDurationFromStartAndEndDatesUTC(
    lastRefreshedDate,
    new Date()
  );

  if (Object.values(duration).every((timePeriod) => timePeriod === 0)) {
    return lastUpdatedTextMap.justNow;
  }
  return lastUpdatedTextMap.durationAgo(formatUTCDuration(duration));
};

interface Props {
  lastRefreshedDate?: Date;
  isLoading: boolean;
  className?: string;
}

/**
 * Displays the elapsed time in minutes since the last data refresh.
 *
 * @param {Date} lastRefreshDate - The timestamp of the last data refresh.
 * @param {boolean} isLoading - Indicates whether data is currently loading.
 *                              If true, the component should display a loading message.
 */
const LastUpdatedText: React.FC<Props> = ({
  lastRefreshedDate,
  isLoading,
  className,
}) => {
  const [lastUpdatedText, setLastUpdatedText] = useState(
    lastUpdatedTextMap.noData
  );
  const updateLastUpdatedText = useCallback(() => {
    // If text has changed, this will force a refresh via lastRefreshedDate
    const newText = getLastUpdatedText(isLoading, lastRefreshedDate);
    setLastUpdatedText(newText);
  }, [isLoading, lastRefreshedDate]);

  useEffect(() => {
    updateLastUpdatedText();
    if (!isLoading) {
      // Note: if page is not the active tab then timers may take longer to
      // fire, but in this case we do not mind since it calculates the text
      // from the current time independently of when it was last called
      const checkTextUpdateTimer = setInterval(() => {
        updateLastUpdatedText();
      }, 10_000);
      return () => clearInterval(checkTextUpdateTimer);
    }
  }, [updateLastUpdatedText, isLoading]);

  return (
    <LastUpdatedTextStyle
      data-test-id="last-updated-text"
      className={className}
    >
      {lastUpdatedText}
    </LastUpdatedTextStyle>
  );
};

export default LastUpdatedText;
