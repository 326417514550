import React from "react";

interface Props {
  lines: string[];
}

// Displays each string in the array on a new line
// Useful for cases where the text is a string containing new lines that won't be rendered
// You can split the string by using the processNewLinesAndSplit function from src/utils/stringHelpers.ts
// and pass the output to this function for rendering
const ParagraphWithLineBreaks: React.FC<Props> = ({ lines }) => (
  <p>
    {lines.map((lineText, index) => (
      <React.Fragment key={`${lineText}-${index}`}>
        {lineText}
        {index < lines.length - 1 && <br />}
      </React.Fragment>
    ))}
  </p>
);

export default ParagraphWithLineBreaks;
