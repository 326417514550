import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colours from "styles/colours";

export const PanelContainer = styled.div<{ colour?: string }>`
  background-color: ${colours.white};
  box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  ${({ colour }): FlattenSimpleInterpolation =>
    css`
      border-top: 8px solid ${colour ?? colours.elexonBlue};
    `}
`;
