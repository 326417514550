import { getAllBmUnits } from "api/dataVisualisation/reference/reference";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import PageMeta from "components/components/Meta/PageMeta";
import Tabs, {
  derivedDataTabDocumentationLinkDict,
  DerivedDataTabEnum,
  DerivedDataTabName,
  TabDetails,
  WidthType,
} from "components/components/Tabs/Tabs/Tabs";
import { StyledDiv } from "components/components/balancingMechanism/marketView/MarketViewFilters/style";
import BidOfferAcceptanceTab from "components/dataVisualization/DerivedData/BidOfferAcceptanceTab/BidOfferAcceptanceTab";
import { DerivedDataFilterContainer } from "components/dataVisualization/DerivedData/DerivedDataFilterContainer/DerivedDataFilterContainer";
import IndicativeCashflowTab from "components/dataVisualization/DerivedData/IndicativeCashflowTab/IndicativeCashflowTab";
import IndicativeVolumeTab from "components/dataVisualization/DerivedData/IndicativeVolumeTab/IndicativeVolumeTab";
import { filterBMUByDerivedDataFilters } from "components/dataVisualization/DerivedData/utils";
import { BidOfferQueryIndicator } from "components/dataVisualization/DetailedSystemPrices/utils";
import AdditionalInfoSection, {
  ExternalDataSource,
} from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import { useDerivedData } from "hooks/useDerivedData";
import useRequest from "hooks/useRequest";
import { BmuModel } from "models/bmuData/bmuModel";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { addMinsToDate, floorToHalfHour } from "utils/dateHelpers";

import { WideTabContainer } from "./style";

const DerivedData: React.FC = () => {
  const defaultDate = floorToHalfHour(addMinsToDate(new Date(), -45));
  const [selectedPeriod, setSelectedPeriod] = useState<Date>(defaultDate);

  const [searchParams] = useSearchParams();
  const bmuDetails = useRequest(getAllBmUnits);
  const { data: bmuData, request: bmuRequest, status } = bmuDetails;
  useEffect(() => {
    bmuRequest();
  }, [bmuRequest]);

  const [filteredBmuIds, setFilteredBmuIds] = useState<
    BmuModel[] | undefined
  >();

  useEffect(() => {
    setFilteredBmuIds(
      filterBMUByDerivedDataFilters(bmuData ?? [], {
        leadParty: searchParams.get("leadParty"),
        bmuType: searchParams.get("bmuType"),
      })
    );
  }, [bmuData, searchParams]);

  const [activeTabTitle, setActiveTabTitle] = useState<DerivedDataTabName>(
    DerivedDataTabEnum.BidAcceptance
  );
  const [documentationLink, setDocumentationLink] = useState<string>(
    derivedDataTabDocumentationLinkDict[DerivedDataTabEnum.BidAcceptance]
  );
  useEffect(() => {
    setDocumentationLink(derivedDataTabDocumentationLinkDict[activeTabTitle]);
  }, [activeTabTitle]);

  const {
    bidAcceptanceData,
    offerAcceptanceData,
    indicativeCashflowBidData,
    indicativeCashflowOfferData,
    indicativeVolumeBidData,
    indicativeVolumeOfferData,
  } = useDerivedData(selectedPeriod, filteredBmuIds, activeTabTitle);

  const tabs: TabDetails<DerivedDataTabName>[] = [
    {
      title: DerivedDataTabEnum.BidAcceptance,
      children: (
        <BidOfferAcceptanceTab
          bidOffer={BidOfferQueryIndicator.BID}
          request={bidAcceptanceData}
        />
      ),
    },
    {
      title: DerivedDataTabEnum.OfferAcceptance,
      children: (
        <BidOfferAcceptanceTab
          bidOffer={BidOfferQueryIndicator.OFFER}
          request={offerAcceptanceData}
        />
      ),
    },
    {
      title: DerivedDataTabEnum.IndicativeVolume,
      children: (
        <IndicativeVolumeTab
          bidRequest={indicativeVolumeBidData}
          offerRequest={indicativeVolumeOfferData}
        />
      ),
    },
    {
      title: DerivedDataTabEnum.IndicativeCashflows,
      children: (
        <IndicativeCashflowTab
          bidRequest={indicativeCashflowBidData}
          offerRequest={indicativeCashflowOfferData}
        />
      ),
    },
  ];

  return (
    <>
      <PageMeta title="Derived data" />
      <PageHeader
        title="Derived data"
        pageDescription={
          <p>
            The Derived data page displays an estimate of the Bid and Offer
            volumes accepted for each Bid-Offer pair by the System Operator for
            a single BM Unit. The data is provided at both a per-acceptance and
            period total level. At acceptance level, an indication of the
            acceptance duration is given (&apos;S&apos; for short and
            &apos;L&apos; for long). At period level the Settlement Period cash
            flow for the BM Unit is provided.
          </p>
        }
        expanderHeading="What you need to know while using this data"
        expanderContent={
          <p>
            The Accepted Bid-Offer Volume is the quantity of Bid or Offer from
            Bid-Offer Pair accepted as a result of Bid-Offer Acceptance.
          </p>
        }
      />
      <FullWidthSection className="is-grey-section">
        <DerivedDataFilterContainer
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          maxDate={defaultDate}
          bmuData={bmuData ?? []}
          bmuStatus={status}
          derivedDataFilters={filteredBmuIds}
          setDerivedDataFilters={setFilteredBmuIds}
          activeTab={activeTabTitle}
        />
        <StyledDiv className="container" />
      </FullWidthSection>
      <WideTabContainer>
        <Tabs
          widthTypeTab={WidthType.Wide}
          widthTypeTabBody={WidthType.Wide}
          tabs={tabs}
          showDefault={false}
          activeTabTitle={activeTabTitle}
          setActiveTabTitle={setActiveTabTitle}
        />
      </WideTabContainer>
      <AdditionalInfoSection
        documentationInformation={{
          table: [documentationLink],
        }}
        source={ExternalDataSource.Elexon}
      />
    </>
  );
};

export default DerivedData;
