import { CheckboxDataGroup } from "components/components/CheckboxList/CheckboxList";

export interface Dataset {
  name: string;
  id: string;
}

export const checkboxDataGroups = (datasets: {
  [key: string]: Dataset;
}): CheckboxDataGroup[] =>
  Object.keys(datasets).map((key, index) => {
    const dataset = datasets[key];
    return {
      id: index,
      name: dataset.name,
      parentId: null,
      datasets: [dataset.id],
    };
  });
