import { getSettlementBidOfferStacksData } from "api/dataVisualisation/detailedSystemPrices/detailedSystemPrices";
import {
  ColumnHeader,
  TableCellRender,
  WithoutId,
} from "components/components/SortableTable/SortableTable";
import { Dataset } from "components/dataVisualization/CheckboxDataExporter/utils";
import { SettlementTransformedData } from "components/dataVisualization/DetailedSystemPrices/SystemPricesHooks/useSystemPricesConsolidatedData";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  SettlementBidOfferStackModel,
  SettlementBidOfferStackModelTransformed,
  SettlementBidOfferTableRowModel,
  SettlementCalculationSummaryModel,
  SettlementCalculationSummaryModelTransformed,
  SettlementMessageModel,
  SettlementMessageModelTransformed,
} from "models/detailedSystemPrices/settlementModels";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { ActionType } from "pages/DetailedSystemPrices/utils";
import React from "react";
import colours from "styles/colours";
import { getSettlementPeriodFromSettlementTime } from "utils/DateUtils";
import { addMinsToDate, toDateOnlyString } from "utils/dateHelpers";
import { roundToMax4dp } from "utils/tableHelpers";

type TableCellData = number | string | null | undefined;

enum CautionMessage {
  MissingData = "Missing data - use with caution",
  Errors = "Critical data missing - no settlement calculation for period",
}

export enum Severity {
  Warning = "Warning",
  Error = "Error",
}

interface SeverityConfig {
  colour: string;
  cautionMessage: CautionMessage;
  severity: Severity;
}

export enum BidOfferQueryIndicator {
  BID = "bid",
  OFFER = "offer",
}

export enum StackTypeHeader {
  BID = "Bid",
  OFFER = "Offer",
}

type KeyOfSettlementBidOfferTableRowModel =
  keyof SettlementBidOfferTableRowModel;

export const severityConfig = {
  [Severity.Warning]: {
    colour: colours.darkOrange,
    cautionMessage: CautionMessage.MissingData,
    severity: Severity.Warning,
  },
  [Severity.Error]: {
    colour: colours.pinkRed,
    cautionMessage: CautionMessage.Errors,
    severity: Severity.Error,
  },
};

export const getConfigForSeverity = (severity: string): SeverityConfig => {
  if (severity === Severity.Error) {
    return severityConfig[Severity.Error];
  }
  return severityConfig[Severity.Warning];
};

export const renderNumericTableCell = (
  field: TableCellData,
  precision: number = 3
): string => {
  if (field === null || field === undefined) {
    return "—";
  }

  const numericValue = Number(field);

  if (isNaN(numericValue)) {
    throw new Error(`Invalid number: ${field}`);
  }

  return numericValue.toFixed(precision);
};

export const datasets: { [key: string]: Dataset } = {
  BuyStack: {
    name: "Buy stack",
    id: "OFFER-STACK",
  },
  SellStack: {
    name: "Sell stack",
    id: "BID-STACK",
  },
};

export const transformDetailedSystemPricesDownloadCallbacks = (
  datasetsToDownload: string[],
  dateFilter: DateFilterModel
): DownloadCallback[] => {
  const bidCallbacks = datasetsToDownload.includes(datasets.BuyStack.id)
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getSettlementBidOfferStacksData(
              toDateOnlyString(dateFilter.startDate),
              getSettlementPeriodFromSettlementTime(
                new Date(dateFilter.startDate)
              ),
              BidOfferQueryIndicator.OFFER,
              format
            ),
          filePrefix: "BuyStack",
        },
      ]
    : [];

  const offerCallbacks = datasetsToDownload.includes(datasets.SellStack.id)
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getSettlementBidOfferStacksData(
              toDateOnlyString(dateFilter.startDate),
              getSettlementPeriodFromSettlementTime(
                new Date(dateFilter.startDate)
              ),
              BidOfferQueryIndicator.BID,
              format
            ),
          filePrefix: "SellStack",
        },
      ]
    : [];

  return [...bidCallbacks, ...offerCallbacks];
};

export const filterByActionType = (
  data: SettlementBidOfferStackModelTransformed[],
  actionType: ActionType
): SettlementBidOfferStackModelTransformed[] =>
  actionType === ActionType.All
    ? data
    : data.filter((item) => {
        if (!item.id) {
          return false;
        }
        const isNumericId = /^\d+$/.test(item.id);
        if (actionType === ActionType.BmUnits) {
          return !isNumericId;
        } else if (actionType === ActionType.BsadActions) {
          return isNumericId;
        }
      });

export const mapSettlementDataToTableRow = (
  data: SettlementBidOfferStackModelTransformed[],
  actionType: ActionType
): SettlementBidOfferTableRowModel[] =>
  filterByActionType(data, actionType).map((item, key) => ({
    id: `${key}`,
    index: item.sequenceNumber,
    bmuId: item.id,
    acceptanceId: item.acceptanceId,
    bidOfferPairId: item.bidOfferPairId,
    cadlFlag: item.cadlFlag,
    soFlag: item.soFlag,
    storProviderFlag: item.storProviderFlag,
    repricedIndicator: item.repricedIndicator,
    reserveScarcityPrice: roundToMax4dp(item.reserveScarcityPrice),
    originalPrice: roundToMax4dp(item.originalPrice),
    volume: roundToMax4dp(item.volume),
    dmatAdjustedVolume: roundToMax4dp(item.dmatAdjustedVolume),
    arbitrageAdjustedVolume: roundToMax4dp(item.arbitrageAdjustedVolume),
    nivAdjustedVolume: roundToMax4dp(item.nivAdjustedVolume),
    parAdjustedVolume: roundToMax4dp(item.parAdjustedVolume),
    finalPrice: roundToMax4dp(item.finalPrice),
    transmissionLossMultiplier: roundToMax4dp(item.transmissionLossMultiplier),
    tlmAdjustedVolume: roundToMax4dp(item.tlmAdjustedVolume),
    tlmAdjustedCost: roundToMax4dp(item.tlmAdjustedCost),
  }));

export const createDetailedSystemPricesTableHeaders = (
  stackTypeHeader: StackTypeHeader
): Record<
  keyof WithoutId<SettlementBidOfferTableRowModel>,
  string | ColumnHeader
> => ({
  index: "Index",
  bmuId: "ID",
  acceptanceId: "Acceptance Id",
  bidOfferPairId: "Bid Offer Pair Id",
  cadlFlag: "CADL Flag",
  soFlag: "SO Flag",
  storProviderFlag: "STOR Flag",
  repricedIndicator: "Re-priced",
  reserveScarcityPrice: "Reserve Scarcity Price",
  originalPrice: `${stackTypeHeader} Price (£/MWh)`,
  volume: `${stackTypeHeader} Volume (MWh)`,
  dmatAdjustedVolume: "DMAT Adjusted Volume (MWh)",
  arbitrageAdjustedVolume: "Arbitrage Adjusted Volume (MWh)",
  nivAdjustedVolume: "NIV Adjusted Volume (MWh)",
  parAdjustedVolume: "PAR Adjusted Volume (MWh)",
  finalPrice: "Final Price (£/MWh)",
  transmissionLossMultiplier: "TLM",
  tlmAdjustedVolume: {
    headerText: "TLM Adjusted Volume (MWh)",
    tooltipText: "= QAPO × TLM",
  },
  tlmAdjustedCost: {
    headerText: "TLM Adjusted Cost (£/MWh)",
    tooltipText: "= QAPO × PO × TLM",
  },
});

export const getCellRender = (
  row: SettlementBidOfferTableRowModel,
  key: KeyOfSettlementBidOfferTableRowModel
): TableCellRender => {
  const isParAdjustedVolumeNonZero = !!row.parAdjustedVolume;
  const shouldApplyRed =
    key === "nivAdjustedVolume" || key === "parAdjustedVolume";

  return {
    content: <span>{row[key]}</span>,
    tdStyle:
      isParAdjustedVolumeNonZero && shouldApplyRed
        ? {
            backgroundColor: colours.lightPink,
            color: colours.crimsonRed,
          }
        : !isParAdjustedVolumeNonZero && row[key] === 0
        ? { backgroundColor: colours.goldYellow }
        : {},
  };
};

const getTransformedSummaryData = (
  summaryData: SettlementCalculationSummaryModel | null
): SettlementCalculationSummaryModelTransformed | null => {
  if (!summaryData) {
    return null;
  }
  return {
    ...summaryData,
    createdDateTime: new Date(summaryData.createdDateTime),
  };
};

export const getTransformedMessageData = (
  messagesData: SettlementMessageModel[] | null
): SettlementMessageModelTransformed[] =>
  messagesData
    ? messagesData.map((data) => ({
        ...data,
        messageReceivedDateTime: new Date(data.messageReceivedDateTime),
        settlementDate: new Date(data.settlementDate),
      }))
    : [];

const getTransformedStackData = (
  stackData: SettlementBidOfferStackModel[] | null
): SettlementBidOfferStackModelTransformed[] =>
  stackData
    ? stackData.map((data) => ({
        ...data,
        createdDateTime: new Date(data.createdDateTime),
        settlementDate: new Date(data.settlementDate),
      }))
    : [];

export const getTransformedSettlementData = (
  summaryData: SettlementCalculationSummaryModel | null,
  messagesData: SettlementMessageModel[] | null,
  bidData: SettlementBidOfferStackModel[] | null,
  offerData: SettlementBidOfferStackModel[] | null
): SettlementTransformedData => ({
  summary: getTransformedSummaryData(summaryData),
  messages: getTransformedMessageData(messagesData),
  bid: getTransformedStackData(bidData),
  offer: getTransformedStackData(offerData),
});

export const getMostRecentMessageDateTime = (
  transformedMessageData: SettlementMessageModelTransformed[]
): Date | null =>
  transformedMessageData.length
    ? new Date(
        Math.max(
          ...transformedMessageData.map((msg) =>
            msg.messageReceivedDateTime.getTime()
          )
        )
      )
    : null;

export const extractDateTimes = (
  data: SettlementBidOfferStackModelTransformed[] | null
): (Date | null)[] => data?.map((item) => item.createdDateTime) ?? [];

const getMostRecentStackDateTime = (
  bidData: SettlementBidOfferStackModelTransformed[] | null,
  offerData: SettlementBidOfferStackModelTransformed[] | null
): Date | null => {
  const allCreatedDateTimes = [
    ...extractDateTimes(bidData),
    ...extractDateTimes(offerData),
  ];

  const validDates = allCreatedDateTimes.filter(
    (dateTime): dateTime is Date => dateTime !== null
  );

  if (validDates.length > 0) {
    // Using spread syntax with Math.max to find the maximum date (i.e. most recent).
    return new Date(Math.max(...validDates.map((date) => date.getTime())));
  }
  return null;
};

const getMostRecentDateTime = (dates: Date[]): Date | null =>
  dates.length > 0
    ? new Date(Math.max(...dates.map((date) => date.getTime())))
    : null;

const getMostRecentSettlementRunDateTime = (
  settlementData: SettlementTransformedData
): Date | null => {
  const summaryDateTime = settlementData.summary
    ? settlementData.summary.createdDateTime
    : null;
  const messagesMostRecentDateTime = getMostRecentMessageDateTime(
    settlementData.messages
  );
  const stackDateTime = getMostRecentStackDateTime(
    settlementData.bid,
    settlementData.offer
  );
  return getMostRecentDateTime(
    [summaryDateTime, messagesMostRecentDateTime, stackDateTime].filter(
      (date) => date !== null
    ) as Date[]
  );
};

export const checkIfDateInMostRecentSettlementRun = (
  dateToCheck: Date,
  mostRecentDate: Date
): boolean => {
  const bufferBetweenBmvAndIpFileInMinutes = 1;
  return (
    addMinsToDate(dateToCheck, bufferBetweenBmvAndIpFileInMinutes) >=
    mostRecentDate
  );
};

export const filterSummaryDataToMostRecentRun = (
  summaryData: SettlementCalculationSummaryModelTransformed | null,
  mostRecentDateTime: Date
): SettlementCalculationSummaryModelTransformed | null =>
  summaryData
    ? checkIfDateInMostRecentSettlementRun(
        summaryData.createdDateTime,
        mostRecentDateTime
      )
      ? summaryData
      : null
    : null;

export const filterSettlementMessagesToMostRecentRun = (
  messages: SettlementMessageModelTransformed[],
  mostRecentDateTime: Date
): SettlementMessageModelTransformed[] =>
  messages.length > 0
    ? checkIfDateInMostRecentSettlementRun(
        new Date(messages[0].messageReceivedDateTime),
        mostRecentDateTime
      )
      ? messages
      : []
    : [];

export const filterStackDataToMostRecentRun = (
  stackData: SettlementBidOfferStackModelTransformed[],
  mostRecentDateTime: Date
): SettlementBidOfferStackModelTransformed[] =>
  stackData.length > 0
    ? checkIfDateInMostRecentSettlementRun(
        new Date(stackData[0].createdDateTime),
        mostRecentDateTime
      )
      ? stackData
      : []
    : [];

export const getMostRecentRunData = (
  settlementData: SettlementTransformedData
): SettlementTransformedData => {
  const mostRecentDateTime = getMostRecentSettlementRunDateTime(settlementData);
  if (mostRecentDateTime === null) {
    return { summary: null, messages: [], bid: [], offer: [] };
  }
  return {
    summary: filterSummaryDataToMostRecentRun(
      settlementData.summary,
      mostRecentDateTime
    ),
    messages: filterSettlementMessagesToMostRecentRun(
      settlementData.messages,
      mostRecentDateTime
    ),
    bid: filterStackDataToMostRecentRun(settlementData.bid, mostRecentDateTime),
    offer: filterStackDataToMostRecentRun(
      settlementData.offer,
      mostRecentDateTime
    ),
  };
};
