import InternalLink from "components/shared/InternalLink/InternalLink";
import { EventStatus } from "models/remit/remitMessageDetailsModel";
import styled from "styled-components";
import colours from "styles/colours";
import { resetButtonStyling } from "styles/commonStyles";
import fonts from "styles/fonts";

interface EventStatusProps {
  eventStatus: EventStatus;
}

export const ModalHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
`;

export const Title = styled.h3`
  margin-top: 5px;
  margin-left: 5px;
`;

export const CloseButton = styled.button`
  ${resetButtonStyling};

  display: flex;
  justify-content: start;

  &:hover {
    background: ${colours.mediumGrey};
  }
`;

export const GridLayoutList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 15px;
  column-gap: 150px;
  padding-left: 50px;
  && {
    margin-left: 0;
  }
`;

export const GridListItem = styled.li`
  list-style: none;
  display: flex;
  gap: 10px;

  && {
    margin-top: 0;
  }
`;

export const EventStatusCircle = styled.div<EventStatusProps>`
  height: 1.5em;
  width: 1.5em;
  margin: 4px;
  border-radius: 50%;
  background-color: ${({ eventStatus }): string => {
    switch (eventStatus) {
      case "Active":
        return colours.tropicalRainForest;
      case "Inactive":
        return colours.pinkRed;
      case "Dismissed":
      default: // we don't expect this case to ever be hit in practice as we've already covered all event statuses
        return colours.mediumGrey;
    }
  }};
`;

export const InfoTextContainer = styled.div`
  font-size: ${fonts.smallMedium};
  line-height: 1.2em;
`;

export const MarginLeftInternalLink = styled(InternalLink)`
  margin-left: 80%;
`;
