import DateFilterModel from "models/filterModels/dateFilterModel";
import {
  addDaysToDate,
  getHalfHourlyTimesBetween,
  startOfSettlementDay,
  addDaysToSettlementDate,
  addMinsToDate,
  endOfSettlementDay,
  toDateOnlyUkString,
} from "utils/dateHelpers";

/**
 * Dates should be UTC midnight or UK time midnight.
 */
type SettlementPeriodsForDateRange = {
  settlementDate: Date;
};

/**
 * Dates should be UTC midnight or UK time midnight.
 */
type SingleSettlementPeriod = {
  settlementDate: Date;
  settlementPeriod: number;
};

export type SettlementPeriodRange =
  | SettlementPeriodsForDateRange
  | SingleSettlementPeriod;

export const rangeIsForSinglePeriod = (
  range: SettlementPeriodRange
): range is SingleSettlementPeriod => "settlementPeriod" in range;

export const isInRange = (
  range: SettlementPeriodRange,
  settlementDate: string,
  settlementPeriod: number
): boolean =>
  toDateOnlyUkString(range.settlementDate) === settlementDate &&
  (!rangeIsForSinglePeriod(range) ||
    range.settlementPeriod === settlementPeriod);

export const normaliseDateToMinuteInterval = (
  date: Date,
  minuteInterval?: number
): Date => {
  if (!minuteInterval) {
    return date;
  }

  const milliseconds = 1000 * 60 * minuteInterval;
  const millisAwayFromInterval = date.getTime() % milliseconds;
  const millisAdjustment =
    millisAwayFromInterval > 0 ? milliseconds - millisAwayFromInterval : 0;

  const normalisedDate = new Date();
  normalisedDate.setTime(date.getTime() + millisAdjustment);
  return normalisedDate;
};

const toUkTimeString = (datetime: Date): string =>
  datetime.toLocaleTimeString("en-GB", {
    timeZone: "Europe/London",
  });

export const toSettlementDateString = (datetime: Date): string =>
  datetime.toLocaleDateString("en-GB", {
    timeZone: "Europe/London",
  });

/**
 * Returns true if the time is midnight (ignoring milliseconds) in the UK.
 * During BST, UK midnight is 11pm UTC.
 * @param datetime the datetime to check
 */
export const isUkMidnight = (datetime: Date): boolean =>
  toUkTimeString(datetime) === "00:00:00";

export const getSettlementDateAndPeriodFromSettlementTime = (
  settlementTime: Date
): SingleSettlementPeriod => {
  const periodUkTime = toUkTimeString(settlementTime);
  const periodUtcTime = settlementTime.toUTCString().slice(17, 25);
  const periodIsInBst = periodUkTime !== periodUtcTime;

  const dateInUk = toSettlementDateString(settlementTime);

  const yearInUk = parseInt(dateInUk.slice(6), 10);
  const monthInUk = parseInt(dateInUk.slice(3, 5), 10) - 1;
  const dayInUk = parseInt(dateInUk.slice(0, 3), 10);

  const midnightUtcOnDateInUk = new Date(
    Date.UTC(yearInUk, monthInUk, dayInUk)
  );
  const startOfDayIsInBst = !isUkMidnight(midnightUtcOnDateInUk);

  const hasShortDayAdjustment = periodIsInBst && !startOfDayIsInBst;
  const hasLongDayAdjustment = !periodIsInBst && startOfDayIsInBst;

  let clockChangeOffset = 0;

  if (hasShortDayAdjustment) {
    clockChangeOffset = -1;
  }

  if (hasLongDayAdjustment) {
    clockChangeOffset = 1;
  }

  const ukTimeHours = parseInt(periodUkTime.slice(0, 2), 10);
  const ukTimeMinutes = parseInt(periodUkTime.slice(3, 5), 10);

  return {
    settlementDate: midnightUtcOnDateInUk,
    settlementPeriod:
      (ukTimeHours + clockChangeOffset) * 2 +
      Math.round(ukTimeMinutes / 30) +
      1,
  };
};

export const getSettlementPeriodFromSettlementTime = (
  settlementTime: Date
): number =>
  getSettlementDateAndPeriodFromSettlementTime(settlementTime).settlementPeriod;

/**
 * Finds the start year of the most recent Triad season for the given date. If no date is given then the current date is used.
 *
 * The Triad season runs from November to February, so
 * * if the given date is in January to October (inclusive) then the returned year will be the year before, and
 * * if the given date is in November or December then the returned year will be the same as the given year.
 *
 * @example getMostRecentTriadSeasonYear(new Date("October 31, 2020")) // returns 2019
 * @example getMostRecentTriadSeasonYear(new Date("November 01, 2020")) // return 2020
 * @example getMostRecentTriadSeasonYear(new Date("January 01, 2021")) // returns 2020
 */
export const getMostRecentTriadSeasonYear = (asOf?: Date): number => {
  const date = asOf ?? new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // The new Triad season starts in November
  return month >= 11 ? year : year - 1;
};

const getSettlementPeriodDatesForDateFilter = (
  datefilter: DateFilterModel
): Date[] => {
  const firstDate = startOfSettlementDay(datefilter.startDate);
  const lastDate = endOfSettlementDay(datefilter.endDate);
  let nextDate = addDaysToSettlementDate(firstDate, 1);
  const dates = [firstDate];
  while (nextDate <= lastDate) {
    dates.push(nextDate);
    nextDate = startOfSettlementDay(addDaysToSettlementDate(nextDate, 1));
  }
  return dates;
};

const getSettlementPeriodsBetweenTimes = (
  startTime: Date,
  endTime: Date,
  inclusiveEnd: boolean
): SingleSettlementPeriod[] => {
  const halfHourlyTimes = getHalfHourlyTimesBetween(
    startTime,
    endTime,
    inclusiveEnd
  );
  return halfHourlyTimes.map((time) =>
    getSettlementDateAndPeriodFromSettlementTime(time)
  );
};

const getStartDaySettlementPeriodRanges = (
  startDate: Date,
  firstSettlementDate: Date,
  secondSettlementDate: Date
): SettlementPeriodRange[] => {
  // If the startDate is later than SP1 of the first SD:
  if (startDate > firstSettlementDate) {
    // Calculate individual SP requests
    return getSettlementPeriodsBetweenTimes(
      startDate,
      secondSettlementDate,
      false
    );
  }
  // Otherwise return the request for the whole day
  return [{ settlementDate: firstSettlementDate }];
};

const getEndDaySettlementPeriodRanges = (
  endDate: Date,
  lastSettlementDate: Date
): SettlementPeriodRange[] => {
  // If the endDate is earlier than the last SP of the last SD
  const startOfLastSettlementPeriod = addMinsToDate(
    addDaysToDate(lastSettlementDate, 1),
    -30
  );
  if (endDate < startOfLastSettlementPeriod) {
    // Calculate individual SP requests
    return getSettlementPeriodsBetweenTimes(lastSettlementDate, endDate, true);
  }
  // Otherwise return the request for the whole day
  return [{ settlementDate: lastSettlementDate }];
};

export const getSettlementPeriodRangesFromDateFilter = (
  dateFilter: DateFilterModel
): SettlementPeriodRange[] => {
  const startTime = dateFilter.startDate;
  const endTime = dateFilter.endDate;

  // 1. Get all settlementDates that cover the entire period
  const allDates = getSettlementPeriodDatesForDateFilter(dateFilter);

  // 2. If there is only one settlementDate, calculate the individual SP requests and return
  if (allDates.length === 1) {
    return getSettlementPeriodsBetweenTimes(startTime, endTime, true);
  }

  // 3. Calculate requests for start day
  const firstSettlementDate = allDates[0];
  const secondSettlementDate = allDates[1];

  const startDateRequests = getStartDaySettlementPeriodRanges(
    startTime,
    firstSettlementDate,
    secondSettlementDate
  );

  // 4. Calculate SD requests for any dates between start date and end date
  const middleDatesRequests: SettlementPeriodRange[] = allDates
    .slice(1, allDates.length - 1)
    .map((date) => ({ settlementDate: date }));

  // 5. Calculate requests for end day
  const lastSettlementDate = allDates[allDates.length - 1];
  const endDateRequests = getEndDaySettlementPeriodRanges(
    endTime,
    lastSettlementDate
  );

  // 6. return the three lists combined
  return [...startDateRequests, ...middleDatesRequests, ...endDateRequests];
};

export const getPathsFromRanges = (
  periods: SettlementPeriodRange[]
): string[] =>
  periods.map((range) =>
    // we use the 'UkString' version here because no matter whether the date is
    // stored as midnight UTC or midnight UK time it will map to the correct date
    // (in UK time). It happens to be that in this file we store settlement dates
    // as midnight UTC when creating a SettlementPeriodsForDateRange and midnight
    // UK time when creating a SingleSettlementPeriod, but that isn't strictly
    // necessary.
    rangeIsForSinglePeriod(range)
      ? `${toDateOnlyUkString(range.settlementDate)}/${range.settlementPeriod}`
      : `${toDateOnlyUkString(range.settlementDate)}`
  );

export const getPathsFromDateFilter = (dateFilter: DateFilterModel): string[] =>
  getPathsFromRanges(getSettlementPeriodRangesFromDateFilter(dateFilter));

export const getDatePathsFromDateFilter = (
  dateFilter: DateFilterModel
): string[] =>
  getSettlementPeriodDatesForDateFilter(dateFilter).map(toDateOnlyUkString);
