import Bubble from "components/components/Bubble/Bubble";
import {
  DateText,
  ContentContainer,
  StyledLink,
  TextContentContainer,
  StyledTitle,
  OtherImageContainer,
} from "components/components/Updates/style";
import TileChild from "components/shared/tile/TileChild/TileChild";
import TileParent from "components/shared/tile/TileParent/TileParent";
import { UpdateWithImage } from "models/updateModel";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

type OtherUpdateProps = {
  update: UpdateWithImage;
  showNewBubble: boolean;
  showDate: boolean;
};

export const OtherUpdate: React.FC<OtherUpdateProps> = ({
  update: { title, imageInfo, link, linkTitleOverride, date, body },
  showNewBubble,
  showDate,
}) => {
  const linkText = `Visit the ${linkTitleOverride || title.toLowerCase()} page`;
  return (
    <TileParent>
      <TileChild isMiniTile as="article">
        <ContentContainer>
          <div>
            <OtherImageContainer>
              <img src={imageInfo.path} alt={imageInfo.alt} />
            </OtherImageContainer>
            <TextContentContainer>
              {showNewBubble && <Bubble>New</Bubble>}
              <StyledTitle>{title}</StyledTitle>
              {showDate && (
                <DateText>
                  {formatDateTimeString(date, DateFormat.DateOnly)}
                </DateText>
              )}
              {body}
            </TextContentContainer>
          </div>
          <TextContentContainer>
            {link && <StyledLink to={link}>{linkText}</StyledLink>}
          </TextContentContainer>
        </ContentContainer>
      </TileChild>
    </TileParent>
  );
};
