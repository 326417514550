import { SettlementMessageModelTransformed } from "models/detailedSystemPrices/settlementModels";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";

const settlementPeriodIsOnOrAfter = (
  date: Date,
  period: number,
  compareAgainstDate: Date,
  compareAgainstPeriod: number
): boolean =>
  date > compareAgainstDate ||
  (date.getTime() === compareAgainstDate.getTime() &&
    period >= compareAgainstPeriod);

const filterSettlementMessagesToShow = (
  settlementMessages: SettlementMessageModelTransformed[],
  systemPrices: SystemPriceModel[]
): SettlementMessageModelTransformed[] => {
  const latestPrice = systemPrices.reduce<SystemPriceModel | null>(
    (currentMaxPrice, systemPrice) =>
      currentMaxPrice === null ||
      settlementPeriodIsOnOrAfter(
        new Date(systemPrice.settlementDate),
        systemPrice.settlementPeriod,
        new Date(currentMaxPrice.settlementDate),
        currentMaxPrice.settlementPeriod
      )
        ? systemPrice
        : currentMaxPrice,
    null
  );

  // If there are no prices then just fallback to showing all messages. This should never happen in
  // practice because it would mean there had been no successful price calculations in 24 hours!
  if (latestPrice === null) {
    return settlementMessages;
  }

  return settlementMessages.filter((message) =>
    settlementPeriodIsOnOrAfter(
      message.settlementDate,
      message.settlementPeriod,
      new Date(latestPrice.settlementDate),
      latestPrice.settlementPeriod
    )
  );
};

const compareSettlementMessagesByReceivedDateTime = (
  a: SettlementMessageModelTransformed,
  b: SettlementMessageModelTransformed
): number =>
  a.messageReceivedDateTime.getTime() - b.messageReceivedDateTime.getTime();

/**
 * We only show messages that are relevant for the final period shown on the price graph onwards.
 *
 * The reason we include messages more recent than the last price is so that if there was a failed
 * file we will still show the messages. See
 * https://elxnfa.visualstudio.com/Insights%20Solution/_wiki/wikis/Insights-Solution.wiki/121412/SAA-Data
 * for more details about SAA data.
 *
 * @param settlementMessages the full list of messages (from the last 24 hours - see homepage.tsx)
 * @param systemPrices the system prices (from the last 24 hours - see homepage.tsx)
 * @returns the messages for the last prices available onwards, sorted by message reveived time
 */
export const filterAndSortSettlementMessagesToShow = (
  settlementMessages: SettlementMessageModelTransformed[],
  systemPrices: SystemPriceModel[]
): SettlementMessageModelTransformed[] =>
  filterSettlementMessagesToShow(settlementMessages, systemPrices).sort(
    compareSettlementMessagesByReceivedDateTime
  );
