import OutageProfileChart from "components/remit/MessageDetails/RemitMessageTiles/OutageProfile/OutageProfileChart";
import OutageProfileTile from "components/remit/MessageDetails/RemitMessageTiles/OutageProfile/OutageProfileTile";
import RemitMessageTile from "components/remit/MessageDetails/RemitMessageTiles/RemitMessageTile";
import {
  ContentTileWrapper,
  OutageProfileChartContainer,
  TilesWrapper,
  TransparentTileWrapper,
  TileTitle,
  RowDiv,
} from "components/remit/MessageDetails/RemitMessageTiles/style";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import React from "react";
import { pickProps } from "utils/remitHelpers";

const RemitMessageTiles: React.FC<{ details: RemitMessageDetailsModel }> = ({
  details,
}) => {
  const messageDetails = pickProps(details, [
    "mrid",
    "id",
    "revisionNumber",
    "messageType",
    "messageHeading",
  ]);

  const capacity = pickProps(details, [
    "normalCapacity",
    "availableCapacity",
    "unavailableCapacity",
  ]);

  const isCapacityNotNull = Object.values(capacity).some(
    (value) => value !== null && value !== undefined
  );

  const assetDetails = pickProps(details, [
    "assetId",
    "assetType",
    "participantId",
    "affectedUnit",
    "affectedUnitEIC",
    "affectedArea",
    "fuelType",
    "biddingZone",
  ]);

  const eventDetails = pickProps(details, [
    "eventStartTime",
    "eventEndTime",
    "eventType",
    "durationUncertainty",
    "cause",
  ]);

  const outageProfile = details.outageProfile ?? [];

  return (
    <TilesWrapper>
      <RemitMessageTile data={messageDetails} tileTitle="Message details" />
      {isCapacityNotNull && (
        <RemitMessageTile data={capacity} tileTitle="Capacity" />
      )}
      <RemitMessageTile data={assetDetails} tileTitle="Asset details" />
      <RemitMessageTile data={eventDetails} tileTitle="Event details" />
      {outageProfile.length > 0 &&
        (outageProfile.length < 5 ? (
          <OutageProfileTile data={outageProfile} tileTitle="Outage Profile*" />
        ) : (
          <OutageProfileChartContainer data-test-id="chart-container">
            <TileTitle>Outage profile</TileTitle>
            <OutageProfileChart data={outageProfile} />
          </OutageProfileChartContainer>
        ))}
      {details.relatedInformation && (
        <TransparentTileWrapper>
          <ContentTileWrapper>
            <TileTitle>Related information</TileTitle>
            <RowDiv>
              <p>{details.relatedInformation}</p>
            </RowDiv>
          </ContentTileWrapper>
        </TransparentTileWrapper>
      )}
    </TilesWrapper>
  );
};

export default RemitMessageTiles;
