import { getSystemPricesJsonData } from "api/dataVisualisation/settlementSystemPricesAndMessages/settlementSystemPricesAndMessages";
import { ErrorHandlingProps } from "components/components/ComponentWithRequest/ComponentWithRequest";
import useRequest from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import { useCallback, useEffect } from "react";

interface SystemSellBuyPricesChartContainerData extends ErrorHandlingProps {
  systemPrices: SystemPriceModel[] | null;
}

const useSystemSellBuyPricesChartContainerData = (
  dateFilter: DateFilterModel
): SystemSellBuyPricesChartContainerData => {
  const {
    data: jsonData,
    status,
    request,
  } = useRequest(getSystemPricesJsonData);

  const data = jsonData?.data ?? null;

  const fetchData = useCallback(async () => {
    request(dateFilter);
  }, [dateFilter, request]);

  // Initial load
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    systemPrices: data,
    dataFetchStatus: status,
    rerequestData: fetchData,
  };
};

export default useSystemSellBuyPricesChartContainerData;
