import {
  faRefresh,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import LastUpdatedText from "components/shared/LastUpdated/LastUpdatedText/LastUpdatedText";
import { RequestStatus } from "hooks/useRequest";
import React from "react";

import {
  Container,
  RefreshErrorContainer,
  RefreshButton,
  RefreshButtonText,
} from "./style";

interface Props {
  lastRefreshedDate?: Date;
  requestStatus: RequestStatus;
  triggerDataRefresh: () => Promise<void>;
}

/**
 * Displays the elapsed time since the last data refresh
 * and provides a refresh button to trigger an update.
 *
 * @param {Date} [lastRefreshedDate] - The timestamp of the last data refresh (optional).
 * @param {RequestStatus} requestStatus - The current status of the data request.
 * @param {() => Promise<void>} triggerDataRefresh - A function to manually trigger a data refresh.
 */
const LastUpdatedWithRefresh: React.FC<Props> = ({
  lastRefreshedDate,
  requestStatus,
  triggerDataRefresh,
}) => (
  <Container aria-live="polite">
    <RefreshButton
      onClick={triggerDataRefresh}
      disabled={requestStatus === RequestStatus.IN_PROGRESS}
    >
      <RefreshButtonText>Refresh</RefreshButtonText>
      <Icon iconName={faRefresh} size={IconSize.mediumSmall} />
    </RefreshButton>
    <LastUpdatedText
      lastRefreshedDate={lastRefreshedDate}
      isLoading={requestStatus === RequestStatus.IN_PROGRESS}
    />
    {requestStatus === RequestStatus.ERRORED && (
      <RefreshErrorContainer>
        <Icon iconName={faExclamationTriangle} size={IconSize.mediumSmall} />
        <p aria-live="polite">
          Page failed to auto-refresh, please click on refresh
        </p>
      </RefreshErrorContainer>
    )}
  </Container>
);

export default LastUpdatedWithRefresh;
