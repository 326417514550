import useRequest, { RequestStatus } from "hooks/useRequest";
import { useCallback, useEffect, useRef, useState } from "react";

type DataWithRefresh<DataType> = {
  data: DataType | null;
  lastRefreshedDate: Date | undefined;
  requestStatus: RequestStatus;
  errorCode: number | null;
  triggerDataRefresh: () => Promise<void>;
};

/*
  Given a function that fetches some data and an interval at which that data
  should be refreshed, this hook will call that function on load, when requested
  (via outputted function) and if it has been a longer time than the interval
  given without refreshing.

  The current datetime is provided to the fetcher function so that it can use 
  the time that will be used as the new lastUpdatedTime if appropriate.

  It returns the datetime that the function was last refreshed, a manual refresh
  function (which also resets the timer and date of last refresh) and the
  current status of the fetcher function (loading / success / error).

  If the fetcher errors then the lastUpdatedDate will NOT be updated.
 */
const useDataWithRefresh = <DataType>(
  fetcher: (date: Date) => Promise<DataType>,
  intervalMinutes: number
): DataWithRefresh<DataType> => {
  const [lastRefreshedDate, setLastRefreshedDate] = useState<
    Date | undefined
  >();
  const refreshDataTimer = useRef<NodeJS.Timeout>();

  const fetcherWithDateUpdate = useCallback(async () => {
    const now = new Date();
    const result = await fetcher(now);
    setLastRefreshedDate(now);
    return result;
  }, [fetcher]);

  const {
    request,
    status: requestStatus,
    errorCode,
    data,
  } = useRequest(fetcherWithDateUpdate);

  const triggerDataRefresh = useCallback(async () => {
    clearTimeout(refreshDataTimer.current);
    try {
      await request();
    } finally {
      // Set up timer to refresh in intervalMinutes whether or not the request
      // succeeds.
      // Note: if page is not the active tab then timers may take longer to
      // fire, but in this case we do not mind since the user will not be
      // interacting with the page and they can manually refresh when they
      // require up-to-date data
      refreshDataTimer.current = setTimeout(() => {
        triggerDataRefresh();
      }, intervalMinutes * 60_000);
    }
  }, [request, intervalMinutes]);

  useEffect(() => {
    triggerDataRefresh();
  }, [triggerDataRefresh]);

  return {
    data,
    lastRefreshedDate,
    requestStatus,
    errorCode,
    triggerDataRefresh,
  };
};

// TODO 260419 Add tests for useDataWithRefresh

export default useDataWithRefresh;
