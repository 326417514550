import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import InformationTooltip from "components/components/InformationTooltip/InformationTooltip";
import { RequestStatus } from "hooks/useRequest";
import React from "react";
import colours from "styles/colours";
import {
  dataIsErrored,
  DataExtract,
  isDataOrErroredObject,
  dataIsSuccess,
} from "utils/dataOrErrored";

import {
  ErrorWrapper,
  Header,
  HorizontalLayout,
  MinHeight,
  PanelWithVerticalMargins,
  Title,
  FixedSizeLoadingSpinner,
  StyledLink,
  LastUpdatedTextWithMargins,
} from "./style";

interface Props<TData> {
  title: string;
  description: React.ReactElement;
  link: {
    text: string;
    href: string;
  };
  children: (data: DataExtract<TData>) => React.ReactNode;
  requestStatus: RequestStatus;
  data?: TData | null;
  testId?: string;
  noMinHeight?: boolean; // Used for section panels that should occupy less space when no data is present. (e.g. Credit default notices)
  lastRefreshedDate?: Date;
}

/**
 * Component for use on the homepage to provide the panel containers for each section.
 *
 * Handles loading and error states, and passes successful data to the children to be
 * rendered. (See Homepage.tsx for usage.)
 */
const SectionPanel = <TData,>({
  title,
  description,
  link,
  children,
  requestStatus,
  data,
  testId,
  noMinHeight,
  lastRefreshedDate,
}: Props<TData>): React.ReactElement => {
  const renderContent = (): React.ReactNode => {
    if (
      (!data && requestStatus === RequestStatus.ERRORED) ||
      (data &&
        Object.values(data).some(
          (value) => isDataOrErroredObject(value) && dataIsErrored(value)
        ))
    ) {
      return (
        <ErrorWrapper>
          <Icon
            iconName={faExclamationTriangle}
            size={IconSize.small}
            colour={colours.elexonYellow}
          />
          <p>Data failed to load</p>
        </ErrorWrapper>
      );
    }

    if (!data) {
      return <FixedSizeLoadingSpinner isLoading />;
    }

    // By this point we know that there is no errored data
    const extractedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        isDataOrErroredObject(value) && dataIsSuccess(value)
          ? value.data
          : value,
      ])
    ) as DataExtract<TData>;
    return children(extractedData);
  };

  return (
    <PanelWithVerticalMargins
      forwardedAs="section"
      colour={colours.elexonGreen}
      testId={testId}
    >
      <Header>
        <HorizontalLayout>
          <Title>{title}</Title>
          <InformationTooltip>{description}</InformationTooltip>
          <LastUpdatedTextWithMargins
            isLoading={requestStatus === RequestStatus.IN_PROGRESS}
            lastRefreshedDate={lastRefreshedDate}
          />
        </HorizontalLayout>
        <StyledLink to={link.href} data-test-id="section-link-to-main-page">
          {link.text}
        </StyledLink>
      </Header>
      {noMinHeight ? (
        <>{renderContent()}</>
      ) : (
        <MinHeight>{renderContent()}</MinHeight>
      )}
    </PanelWithVerticalMargins>
  );
};

export default SectionPanel;
