import ResultsCount from "components/components/ResultsCount/ResultsCount";
import { StyledDateTime } from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
  WithoutId,
} from "components/components/SortableTable/SortableTable";
import ParagraphWithLineBreaks from "components/shared/ParagraphWithLineBreaks/ParagraphWithLineBreaks";
import ShowMoreText from "components/shared/ShowMoreText/ShowMoreText";
import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";
import { SystemWarningsTableModel } from "models/systemWarnings/systemWarningsTableModel";
import React from "react";
import { processNewLinesAndSplit } from "utils/stringHelpers";

import { FixedColumnWidths } from "./style";

const SystemWarningHeaders: Record<
  keyof WithoutId<SystemWarningsModel>,
  string
> = {
  publishTime: "Warning date",
  warningType: "Warning type",
  warningText: "Message text",
};

interface Props {
  data: SystemWarningsTableModel[];
}

const SystemWarningsTable: React.FC<Props> = ({ data }) => (
  <>
    <ResultsCount count={data.length} />
    <FixedColumnWidths>
      <SortableTable
        headers={SystemWarningHeaders}
        items={data}
        dataTestId="system-warnings-table"
        renderFns={{
          publishTime: ({ publishTime }): TableCellRender => ({
            content: StyledDateTime(publishTime),
          }),
          warningText: ({ warningText }): TableCellRender => ({
            content: (
              <ShowMoreText>
                <ParagraphWithLineBreaks
                  lines={processNewLinesAndSplit(warningText)}
                />
              </ShowMoreText>
            ),
          }),
        }}
        scrollable={false}
      />
    </FixedColumnWidths>
  </>
);

export default SystemWarningsTable;
