import styled from "styled-components";
import colours from "styles/colours";
import { resetButtonStyling } from "styles/commonStyles";
import fonts from "styles/fonts";

export const RemitListItem = styled.li`
  display: flex;
  gap: 20px;
  list-style: none;

  && {
    margin-top: 0;
  }
`;

export const InterconnectorInformation = styled.div`
  text-align: center;
  place-content: center;
  width: 120px;
  height: 120px; // match pie chart sizing
`;

export const UnavailableCapacity = styled.span`
  text-align: center;
  font-weight: bold;
  font-size: ${fonts.medium};
  color: ${colours.crimsonRed};
`;

export const GreenBorderOnHoverButton = styled.button`
  ${resetButtonStyling};

  display: flex;
  gap: 20px;
  padding: 10px;
  border: 2px solid transparent;

  &:hover {
    border-radius: 20px;
    box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.1);
    border: 2px solid ${colours.elexonGreen};
  }
`;
