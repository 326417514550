import { EventItem } from "components/homepage/Events/EventItem/EventItem";
import { NoContentText } from "components/homepage/style";
import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";
import React from "react";

import { EventsList } from "./style";

const warningsToIgnore = ["SO-SO TRADES"];
const maxNumberOfEventsToShow = 3;

interface Props {
  systemWarnings: SystemWarningsModel[];
}

const EventsContent: React.FC<Props> = ({ systemWarnings }) => {
  const eventsToShow = systemWarnings
    .filter((event) => !warningsToIgnore.includes(event.warningType))
    .slice(0, maxNumberOfEventsToShow);

  if (eventsToShow.length === 0) {
    return <NoContentText>No new events at this time</NoContentText>;
  }

  return (
    <EventsList>
      {eventsToShow.map((event) => (
        <EventItem
          key={`${event.publishTime}-${event.warningType}-${event.warningText}`}
          id={event.publishTime}
          name={event.warningType}
          timestamp={new Date(event.publishTime)}
          description={event.warningText}
        />
      ))}
    </EventsList>
  );
};

export default EventsContent;
