import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const FixedSizeContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
`;

export const DisplayText = styled.div<{ isRed: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  font-size: ${fonts.small};
  line-height: ${fonts.small};

  ${({ isRed }): false | FlattenSimpleInterpolation =>
    isRed &&
    css`
      color: ${colours.crimsonRed};
    `}
`;

export const BoldedText = styled.span`
  font-weight: ${fonts.heavy};
`;
