import classnames from "classnames";
import FocusTrap from "focus-trap-react";
import useOnEscape from "hooks/useOnEscape";
import React from "react";

import {
  ModalContentContainer,
  StyledModalBackground,
  StyledModalContent,
} from "./style";

export enum ModalSize {
  small = "",
  medium = "medium",
  large = "large",
  xLarge = "x-large",
}

interface Props {
  size: ModalSize;
  isActive: boolean;
  onClose: () => void;
  children: React.ReactNode;
  closeOnEsc?: boolean;
  headingId?: string;
  descriptionId?: string;
  allowOverflow?: boolean;
  ariaLive?: React.AriaAttributes["aria-live"];
}

const Modal: React.FC<Props> = ({
  size,
  isActive,
  onClose,
  children,
  closeOnEsc = true,
  headingId,
  descriptionId,
  allowOverflow,
  ariaLive,
}: Props) => {
  useOnEscape(closeOnEsc ? onClose : (): void => {});

  return (
    <FocusTrap
      active={isActive}
      focusTrapOptions={{
        fallbackFocus: ".modal-content",
        allowOutsideClick: true,
        initialFocus: ".modal.is-active",
      }}
    >
      <div
        className={classnames("modal", { "is-active": isActive })}
        data-test-id="modal-container"
        role="dialog"
        tabIndex={-1}
        aria-modal
        aria-labelledby={headingId}
        aria-describedby={descriptionId}
        aria-live={ariaLive}
      >
        <StyledModalBackground
          className="modal-background"
          onClick={onClose}
          data-test-id="modal-background"
        />

        <StyledModalContent
          className={classnames(
            "modal-content",
            { overflow: allowOverflow },
            size
          )}
        >
          <ModalContentContainer>{children}</ModalContentContainer>
        </StyledModalContent>
      </div>
    </FocusTrap>
  );
};

export default Modal;
