import { BidOfferQueryIndicator } from "components/dataVisualization/DetailedSystemPrices/utils";
import { BmuModel } from "models/bmuData/bmuModel";
import {
  AcceptanceVolumeModel,
  AcceptanceVolumeRow,
} from "models/derivedData/acceptanceVolumeModel";
import { DerivedDataPairs } from "models/derivedData/derivedData";
import {
  IndicativeCashflowModel,
  IndicativeCashflowRow,
} from "models/derivedData/indicativeCashflow";
import {
  IndicativeVolumeModel,
  IndicativeVolumeRow,
} from "models/derivedData/indicativeVolumeModel";
import { roundToMax4dp } from "utils/tableHelpers";

import { BidOffer } from "./useAcceptanceVolumeData";

export interface DerivedDataFilters {
  leadParty: string | null;
  bmuType: string | null;
}

export const hasAnyFilters = (filters: DerivedDataFilters): boolean => {
  const { leadParty, bmuType } = filters;
  return !!(leadParty ?? bmuType);
};

export const filterBMUByDerivedDataFilters = (
  bmuData: BmuModel[],
  filter: DerivedDataFilters
): BmuModel[] | undefined =>
  hasAnyFilters(filter)
    ? bmuData
        .filter(
          (bmu) => !filter.leadParty || bmu.leadPartyName == filter.leadParty
        )
        .filter((bmu) => !filter.bmuType || bmu.bmUnitType == filter.bmuType)
    : undefined;

export const mapAcceptanceVolumeDataToRow = (
  data: AcceptanceVolumeModel[]
): AcceptanceVolumeRow[] =>
  data.map(({ pairVolumes, ...row }, key) => ({
    id: `${key}`,
    bmUnit: row.bmUnit,
    leadPartyName: row.leadPartyName,
    acceptanceId: row.acceptanceId,
    acceptanceDuration: row.acceptanceDuration,
    totalVolumeAccepted: roundToMax4dp(row.totalVolumeAccepted),
    negative1: roundToMax4dp(pairVolumes.negative1),
    positive1: roundToMax4dp(pairVolumes.positive1),
    negative2: roundToMax4dp(pairVolumes.negative2),
    positive2: roundToMax4dp(pairVolumes.positive2),
    negative3: roundToMax4dp(pairVolumes.negative3),
    positive3: roundToMax4dp(pairVolumes.positive3),
    negative4: roundToMax4dp(pairVolumes.negative4),
    positive4: roundToMax4dp(pairVolumes.positive4),
    negative5: roundToMax4dp(pairVolumes.negative5),
    positive5: roundToMax4dp(pairVolumes.positive5),
    negative6: roundToMax4dp(pairVolumes.negative6),
    positive6: roundToMax4dp(pairVolumes.positive6),
    bmUnitType: row.bmUnitType,
    nationalGridBmUnit: row.nationalGridBmUnit,
  }));

const mapIndicativeCashflowDataToRow = (
  data: IndicativeCashflowModel[] | null,
  bidOffer: BidOffer
): IndicativeCashflowRow[] =>
  data
    ? data.map(({ bidOfferPairCashflows, ...row }, key) => ({
        id: `${bidOffer}-${key}`,
        bmUnit: row.bmUnit,
        leadPartyName: row.leadPartyName,
        totalCashflow: roundToMax4dp(row.totalCashflow),
        bidOffer:
          bidOffer === BidOfferQueryIndicator.BID
            ? "Bid Cashflow"
            : "Offer Cashflow",
        negative1: roundToMax4dp(bidOfferPairCashflows.negative1),
        positive1: roundToMax4dp(bidOfferPairCashflows.positive1),
        negative2: roundToMax4dp(bidOfferPairCashflows.negative2),
        positive2: roundToMax4dp(bidOfferPairCashflows.positive2),
        negative3: roundToMax4dp(bidOfferPairCashflows.negative3),
        positive3: roundToMax4dp(bidOfferPairCashflows.positive3),
        negative4: roundToMax4dp(bidOfferPairCashflows.negative4),
        positive4: roundToMax4dp(bidOfferPairCashflows.positive4),
        negative5: roundToMax4dp(bidOfferPairCashflows.negative5),
        positive5: roundToMax4dp(bidOfferPairCashflows.positive5),
        negative6: roundToMax4dp(bidOfferPairCashflows.negative6),
        positive6: roundToMax4dp(bidOfferPairCashflows.positive6),
        bmUnitType: row.bmUnitType,
        nationalGridBmUnit: row.nationalGridBmUnit,
      }))
    : [];

export const mapIndicativeCashflowData = (
  rawBidData: IndicativeCashflowModel[] | null,
  rawOfferData: IndicativeCashflowModel[] | null
): IndicativeCashflowRow[] => [
  ...mapIndicativeCashflowDataToRow(rawBidData, BidOfferQueryIndicator.BID),
  ...mapIndicativeCashflowDataToRow(rawOfferData, BidOfferQueryIndicator.OFFER),
];

export const mapIndicativeVolumeDataToRow = (
  data: IndicativeVolumeModel[] | null,
  bidOffer: BidOffer
): IndicativeVolumeRow[] =>
  data
    ? data.map(({ pairVolumes, ...row }, key) => ({
        id: `${bidOffer}-${key}`,
        bmUnit: row.bmUnit,
        leadPartyName: row.leadPartyName,
        bidOffer: bidOffer === BidOfferQueryIndicator.BID ? "Bid" : "Offer",
        dataType: row.dataType,
        totalVolumeAccepted: roundToMax4dp(row.totalVolumeAccepted),
        negative1: roundToMax4dp(pairVolumes.negative1),
        positive1: roundToMax4dp(pairVolumes.positive1),
        negative2: roundToMax4dp(pairVolumes.negative2),
        positive2: roundToMax4dp(pairVolumes.positive2),
        negative3: roundToMax4dp(pairVolumes.negative3),
        positive3: roundToMax4dp(pairVolumes.positive3),
        negative4: roundToMax4dp(pairVolumes.negative4),
        positive4: roundToMax4dp(pairVolumes.positive4),
        negative5: roundToMax4dp(pairVolumes.negative5),
        positive5: roundToMax4dp(pairVolumes.positive5),
        negative6: roundToMax4dp(pairVolumes.negative6),
        positive6: roundToMax4dp(pairVolumes.positive6),
        bmUnitType: row.bmUnitType,
        nationalGridBmUnit: row.nationalGridBmUnit,
      }))
    : [];

export const mapIndicativeVolumeData = (
  rawBidData: IndicativeVolumeModel[] | null,
  rawOfferData: IndicativeVolumeModel[] | null
): IndicativeVolumeRow[] => [
  ...mapIndicativeVolumeDataToRow(rawBidData, BidOfferQueryIndicator.BID),
  ...mapIndicativeVolumeDataToRow(rawOfferData, BidOfferQueryIndicator.OFFER),
];

export const pairHeaders = {
  negative1: "Pair -1",
  positive1: "Pair 1",
  negative2: "Pair -2",
  positive2: "Pair 2",
  negative3: "Pair -3",
  positive3: "Pair 3",
  negative4: "Pair -4",
  positive4: "Pair 4",
  negative5: "Pair -5",
  positive5: "Pair 5",
  negative6: "Pair -6",
  positive6: "Pair 6",
};

export const pairHeaderList = [
  ["negative1", "positive1"],
  ["negative2", "positive2"],
  ["negative3", "positive3"],
  ["negative4", "positive4"],
  ["negative5", "positive5"],
  ["negative6", "positive6"],
];

export const getNullPairHeaders = <T extends DerivedDataPairs>(
  data: T[]
): Partial<keyof DerivedDataPairs>[] =>
  pairHeaderList
    .filter(
      (pair) =>
        pair.filter(
          (key) =>
            data.filter((row) => row[key as keyof DerivedDataPairs] !== null)
              .length !== 0
        ).length === 0
    )
    .reduce((previous, current) => [...previous, ...current], []) as Partial<
    keyof DerivedDataPairs
  >[];
