import { CreditDefaultNoticeModel } from "./creditDefaultNoticeModel";

export interface CreditDefaultNoticeModelTransformed {
  participantId: string;
  participantName: string;
  creditDefaultLevel: number;
  enteredDefaultSettlementDate: Date;
  enteredDefaultSettlementPeriod: number;
  clearedDefaultSettlementDate: Date | null;
  clearedDefaultSettlementPeriod: number | null;
  clearedDefaultText: string | null;
}

export const getTransformedCreditDefaultNotice = (
  notice: CreditDefaultNoticeModel
): CreditDefaultNoticeModelTransformed => ({
  ...notice,
  enteredDefaultSettlementDate: new Date(notice.enteredDefaultSettlementDate),
  clearedDefaultSettlementDate: notice.clearedDefaultSettlementDate
    ? new Date(notice.clearedDefaultSettlementDate)
    : null,
});
