import CreditDefaultNoticeItem from "components/homepage/CreditDefaultNotices/CreditDefaultNoticeItem/CreditDefaultNoticeItem";
import { NoContentText } from "components/homepage/style";
import { CreditDefaultNoticeModelTransformed } from "models/creditDefaultNotice/creditDeafultNoticeTransformed";
import React from "react";

import { ColumnList, Flexbox } from "./style";

interface Props {
  creditDefaultNotices: CreditDefaultNoticeModelTransformed[];
}

const compareByEnteredSettlementDate = (
  a: CreditDefaultNoticeModelTransformed,
  b: CreditDefaultNoticeModelTransformed
): number =>
  b.enteredDefaultSettlementDate.getTime() -
  a.enteredDefaultSettlementDate.getTime();

const renderCreditDefaultNoticeItems = (
  creditDefaultNotices: CreditDefaultNoticeModelTransformed[]
): React.ReactElement[] =>
  creditDefaultNotices.map((cdn) => (
    <CreditDefaultNoticeItem
      key={`${cdn.enteredDefaultSettlementDate}-${cdn.enteredDefaultSettlementPeriod}-${cdn.participantName}-${cdn.creditDefaultLevel}`}
      participantName={cdn.participantName}
      creditDefaultLevel={cdn.creditDefaultLevel}
      enteredDefaultSettlementDate={cdn.enteredDefaultSettlementDate}
      enteredDefaultSettlementPeriod={cdn.enteredDefaultSettlementPeriod}
      clearedDefaultSettlementDate={cdn.clearedDefaultSettlementDate}
      clearedDefaultSettlementPeriod={cdn.clearedDefaultSettlementPeriod}
    />
  ));

const CreditDefaultNoticesContent: React.FC<Props> = ({
  creditDefaultNotices,
}) => {
  creditDefaultNotices.sort(compareByEnteredSettlementDate);
  if (creditDefaultNotices.length === 0) {
    return (
      <NoContentText>No credit default notices currently active</NoContentText>
    );
  }

  const level1CreditDefaultNotices = creditDefaultNotices.filter(
    (cdn) => cdn.creditDefaultLevel == 1
  );
  const level2CreditDefaultNotices = creditDefaultNotices.filter(
    (cdn) => cdn.creditDefaultLevel == 2
  );
  return (
    <Flexbox>
      <ColumnList>
        {renderCreditDefaultNoticeItems(level1CreditDefaultNotices)}
      </ColumnList>
      <ColumnList>
        {renderCreditDefaultNoticeItems(level2CreditDefaultNotices)}
      </ColumnList>
    </Flexbox>
  );
};

export default CreditDefaultNoticesContent;
