import {
  FontSizeOverrideProps,
  MultiChartTitle,
} from "components/dataVisualization/chartComponents/MultiChartTitle/style";
import styled from "styled-components";

export interface SystemPricesMultiChartTitleProps
  extends FontSizeOverrideProps {
  chartIndent: number;
}

export const SystemPricesMultiChartTitle = styled(
  MultiChartTitle
)<SystemPricesMultiChartTitleProps>`
  &&& {
    margin: ${({ chartIndent }): string => `0 0 0 ${120 + chartIndent}px`};
  }
`;
