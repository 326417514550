import React from "react";

interface Props {
  children: JSX.Element;
}

/**
 * A react wrapper component for the Bulma tile ancestor class.
 *
 * Use with TileChild and TileParent.
 */
const TileRowContainer: React.FC<Props> = ({ children }) => (
  <div className="tile is-ancestor">{children}</div>
);

export default TileRowContainer;
