import Button from "components/components/Button/Button";
import React, { useEffect, useState } from "react";
import {
  functionalityCookiesAreEnabled,
  HAS_COLLAPSED_BANNER_COOKIE,
  parseCookies,
  setCookieIfNotExists,
} from "utils/cookieHelper";

import {
  Tag,
  HeaderRow,
  ExpandableDiv,
  StyledTestBanner,
  StyledSubtitle,
  MainWebsiteLink,
} from "./style";

const TestBanner: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const expanderButtonText = expanded ? "Show less" : "Learn more";

  useEffect(() => {
    const testBannerCookie = parseCookies(document.cookie)[
      HAS_COLLAPSED_BANNER_COOKIE
    ];
    if (!testBannerCookie) {
      setExpanded(true);
    }
  }, []);

  const expandOrCollapseBanner = (): void => {
    if (expanded) {
      const consent = functionalityCookiesAreEnabled();

      if (consent) {
        setCookieIfNotExists(HAS_COLLAPSED_BANNER_COOKIE, true.toString());
      }
    }

    setExpanded((v) => !v);
  };

  return (
    <>
      <StyledTestBanner aria-live="polite">
        <HeaderRow className="banner-row">
          <div>
            <Tag>Test</Tag>
          </div>
          You are using the Test Website, designed to ensure that all features
          and functionalities are working as intended
          <Button
            className="plain-inline align-right expander-button"
            aria-label={expanderButtonText}
            onClick={(): void => expandOrCollapseBanner()}
            buttonText={expanderButtonText}
          >
            <span className="expander-icon" aria-hidden="true">
              {expanded ? "3" : "o"}
            </span>
          </Button>
        </HeaderRow>
        {expanded && (
          <ExpandableDiv
            className="banner-row"
            data-test-id="test-banner-content"
          >
            <section>
              <StyledSubtitle>About the Test Website</StyledSubtitle>
              <p>
                This version may include outdated or inaccurate information, or
                temporary data that could be modified or removed without prior
                notice. Performance may not meet expectations, and both design
                and functionality are subject to change.
              </p>
              <br />
              <p>
                <MainWebsiteLink href="https://bmrs.elexon.co.uk/">
                  Click here to access the Live Website.
                </MainWebsiteLink>
              </p>
            </section>
          </ExpandableDiv>
        )}
      </StyledTestBanner>
    </>
  );
};

export default TestBanner;
