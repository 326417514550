import { ListItemWithNewHighlight } from "components/homepage/style";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const HorizontalLayout = styled.div`
  display: flex;
  gap: 20px;
`;

export const OrangeBubble = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${colours.darkOrange}; /* Orange/Brown color */
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1;
  font-size: ${fonts.xSmall};
`;

export const Label = styled.span`
  font-size: ${fonts.small};
  text-transform: uppercase;
`;

export const Number = styled.span`
  font-size: ${fonts.large};
  font-weight: bold;
`;

export const StyledListItemWithNewHighlight = styled(ListItemWithNewHighlight)`
  width: fit-content;
`;
