import {
  faArrowsToDot,
  faArrowsUpToLine,
  faArrowTrendDown,
  faExclamationTriangle,
  faPlugCircleExclamation,
  faSortAmountDown,
  faSquareMinus,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import {
  ItemName,
  ListItemWithNewHighlight,
  NewItemLabel,
} from "components/homepage/style";
import ParagraphWithLineBreaks from "components/shared/ParagraphWithLineBreaks/ParagraphWithLineBreaks";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";
import { processNewLinesAndSplit } from "utils/stringHelpers";

import {
  HorizontalLayout,
  IconWrapper,
  EventInfo,
  StyledShowMoreText,
} from "./style";

interface Props {
  id: string;
  name: string;
  timestamp: Date;
  description: string;
}

const iconMap = new Map([
  ["IT SYSTEMS OUTAGE", faPlugCircleExclamation],
  ["ELECTRICITY MARGIN NOTICE", faArrowTrendDown],
  ["CAPACITY MARKET NOTICE", faArrowsUpToLine],
  ["HIGH RISK OF DEMAND CONTROL", faSortAmountDown],
  ["DEMAND CONTROL IMMINENT", faArrowsToDot],
  ["DEMAND FLEXIBILITY SERVICE", faWater],
  ["NEGATIVE RESERVE ACTIVE POWER MARGIN", faSquareMinus],
  ["OTHER", faExclamationTriangle],
]);

const nameMap = new Map([
  ["IT SYSTEMS OUTAGE", "NESO IT Systems Outage"], // the NESO prefix should only be displayed on this page and the API response should not contain it
  ["ELECTRICITY MARGIN NOTICE", "Electricity Margin Notice"],
  ["CAPACITY MARKET NOTICE", "Capacity Market Notice"],
  ["HIGH RISK OF DEMAND CONTROL", "High Risk Of Demand Control"],
  ["DEMAND CONTROL IMMINENT", "Demand Control Imminent"],
  ["DEMAND FLEXIBILITY SERVICE", "Demand Flexibility Service"],
  [
    "NEGATIVE RESERVE ACTIVE POWER MARGIN",
    "Negative Reserve Active Power Margin",
  ],
  ["OTHER", "Other"],
]);

const thresholdInMinutes = 30;

const EventItem: React.FC<Props> = ({ id, name, timestamp, description }) => {
  const icon = iconMap.get(name) ?? faExclamationTriangle;
  const transformedName = nameMap.get(name) ?? name;

  const ageInMilliseconds = new Date().getTime() - timestamp.getTime();
  const ageInMinutes = ageInMilliseconds / (1000 * 60);
  const isNew = ageInMinutes < thresholdInMinutes;

  const newMessageId = `new-message-${id}`;
  return (
    <ListItemWithNewHighlight
      aria-describedby={isNew ? newMessageId : undefined}
      isNew={isNew}
      data-test-id="event-item"
    >
      {isNew && <NewItemLabel id={newMessageId}>New event</NewItemLabel>}
      <HorizontalLayout>
        <IconWrapper>
          <Icon iconName={icon} size={IconSize.medium} />
        </IconWrapper>
        <EventInfo>
          <ItemName>
            {transformedName}{" "}
            {formatDateTimeString(timestamp, DateFormat.DateTime)}
          </ItemName>
          <StyledShowMoreText>
            <ParagraphWithLineBreaks
              lines={processNewLinesAndSplit(description)}
            />
          </StyledShowMoreText>
        </EventInfo>
      </HorizontalLayout>
    </ListItemWithNewHighlight>
  );
};

export { EventItem };
