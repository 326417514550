import { defaultEndDate, defaultStartDate } from "components/remit/remitConfig";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { addDaysToDate } from "utils/dateHelpers";

export enum UnavailabilityType {
  Planned = "Planned",
  Unplanned = "Unplanned",
}

export enum MessageType {
  OtherMarketInformation = "Other Market Information",
  UnavailabilitiesOfElectricityFacilities = "Unavailabilities of Electricity Facilities",
}

export enum EventType {
  Production = "Production Unavailability",
  Transmission = "Transmission Unavailability",
  Consumption = "Consumption Unavailability",
  Other = "Other Unavailability",
}

export enum TimeRangeType {
  Previous = "Previous",
  Next = "Next",
  Custom = "Custom",
}

export enum TimeRangeLength {
  Day = "24h",
  Week = "7 days",
  Month = "30 days",
  Quarter = "90 days",
  Year = "365 days",
}

export enum RemitFilterEndpoint {
  Publish = "published",
  Event = "event",
}

export enum RevisionHistory {
  Include = "include-history",
  LatestOnly = "latest-only",
}

export interface RemitFilters {
  timeRangeType: string;
  timeRangeLength: string;
  messageType: string[];
  unavailabilityType: string[];
  eventType: string[];
  fuelType: string[];
  participantID: string | null;
  assetID: string | null;
  messageID: string | null;
  endpoint: string;
  revisionHistory: RevisionHistory;
  dateFilter: DateFilterModel;
}

/** Asset ID and Participant ID use the same dropdown,
 * so if one is set then we must clear the other. */
export const ensureAssetParticipantFiltersAreValid = (
  filters: RemitFilters,
  key?: string
): RemitFilters => {
  if (key === "assetID") {
    return {
      ...filters,
      participantID: null,
    };
  }

  if (key === "participantID") {
    return {
      ...filters,
      assetID: null,
    };
  }

  // If we get to this state then the filters must have been edited in the url
  if (key === undefined && !!filters.assetID && !!filters.participantID) {
    return {
      ...filters,
      assetID: null,
      participantID: null,
    };
  }

  return filters;
};

export const ensureEndpointAndTimeRangeTypeAreValid = (
  filters: RemitFilters
): RemitFilters => {
  // If the URL was edited we could include an invalid endpoint type
  if (
    filters.endpoint !== RemitFilterEndpoint.Event &&
    filters.endpoint !== RemitFilterEndpoint.Publish
  ) {
    return {
      ...filters,
      endpoint: RemitFilterEndpoint.Event,
    };
  }

  // If we are searching by publish date there is no point being able to search in the future
  if (
    filters.endpoint === RemitFilterEndpoint.Publish &&
    filters.timeRangeType === TimeRangeType.Next
  ) {
    return {
      ...filters,
      timeRangeType: TimeRangeType.Previous,
    };
  }

  return filters;
};

const getTimeLengthFilterValueFromTimeRangeType = (
  timeRangeType: string,
  timeRangeLength: string
): string => {
  if (timeRangeType === TimeRangeType.Custom) {
    // timeRangeType = "custom" => timeRangeLength = "custom"
    return TimeRangeType.Custom;
  } else if (timeRangeLength === "Custom") {
    // If timeRangeType != "custom" and timeRangeLength == "custom" then we must
    // default to a timeRangeLength of 24 hours
    return TimeRangeLength.Day;
  } else {
    return timeRangeLength;
  }
};

/** If the timeRangeType is changed there are certain timeRangeLengths that are or aren't valid. */
export const ensureTimeRangeTypeAndLengthAreAValidCombination = (
  filters: RemitFilters
): RemitFilters => {
  const newTimeLengthValue = getTimeLengthFilterValueFromTimeRangeType(
    filters.timeRangeType,
    filters.timeRangeLength
  );

  return {
    ...filters,
    timeRangeLength: newTimeLengthValue,
  };
};

const getDaysFromTimeRangeLength = (timeRangeLength: string): number => {
  switch (timeRangeLength) {
    case TimeRangeLength.Day:
      return 1;
    case TimeRangeLength.Week:
      return 7;
    case TimeRangeLength.Month:
      return 30;
    case TimeRangeLength.Quarter:
      return 90;
    case TimeRangeLength.Year:
      return 365;
    default:
      return 1;
  }
};

const getDateFilterFromSelectedFilters = (
  timeRangeType: string,
  timeRangeLength: string,
  currentDateFilter: DateFilterModel
): DateFilterModel => {
  let startDate = currentDateFilter.startDate ?? defaultStartDate;
  let endDate = currentDateFilter.endDate ?? defaultEndDate;
  const days = getDaysFromTimeRangeLength(timeRangeLength);

  switch (timeRangeType) {
    case TimeRangeType.Previous:
      startDate = addDaysToDate(defaultEndDate, -days);
      endDate = defaultEndDate;
      break;
    case TimeRangeType.Next:
      // We don't want to include the current hour when 'Next' is selected
      startDate = defaultEndDate;
      endDate = addDaysToDate(defaultEndDate, days);
      break;
    case TimeRangeType.Custom:
      return currentDateFilter;
    default:
      break;
  }

  return new DateFilterModel(startDate, endDate);
};

/** If the timeRangeType/timeRangeLength have changed then we must update the dateFilter to match. */
export const ensureDateFilterReflectsTimeRangeTypeAndLength = (
  filters: RemitFilters
): RemitFilters => {
  const newDateFilter = getDateFilterFromSelectedFilters(
    filters.timeRangeType,
    filters.timeRangeLength,
    filters.dateFilter
  );

  return {
    ...filters,
    dateFilter: newDateFilter,
  };
};

export const ensureEndDateIsWithinEndpointRange = (
  filters: RemitFilters
): RemitFilters => {
  if (
    filters.endpoint === RemitFilterEndpoint.Publish &&
    filters.dateFilter.endDate > new Date()
  ) {
    return {
      ...filters,
      dateFilter: new DateFilterModel(filters.dateFilter.startDate, new Date()),
    };
  }

  return filters;
};

export const ensureFiltersInValidState = (
  filters: RemitFilters,
  key?: string
): RemitFilters => {
  let newFilters = {
    ...filters,
  };

  newFilters = ensureEndpointAndTimeRangeTypeAreValid(newFilters);
  newFilters = ensureTimeRangeTypeAndLengthAreAValidCombination(newFilters);
  newFilters = ensureDateFilterReflectsTimeRangeTypeAndLength(newFilters);
  newFilters = ensureEndDateIsWithinEndpointRange(newFilters);

  return ensureAssetParticipantFiltersAreValid(newFilters, key);
};
