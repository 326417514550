import styled from "styled-components";

export const GridLayoutList = styled.ol`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  column-gap: 50px;
  && {
    margin-left: -10px; /* To counteract the padding inside each item */
  }

  @media screen and (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1405px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
