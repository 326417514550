import ResultsCount from "components/components/ResultsCount/ResultsCount";
import {
  StyledBoolean,
  StyledDateTime,
} from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
  WithoutId,
} from "components/components/SortableTable/SortableTable";
import { DemandControlInstructionsTableModel } from "models/demandControlInstructions/demandControlInstructionsTableModel";
import React from "react";

const DemandControlInstructionHeaders: Record<
  keyof WithoutId<DemandControlInstructionsTableModel>,
  string
> = {
  dcId: "DC ID",
  affectedDso: "Affected Dso",
  instructionSequence: "Instruction Sequence",
  demandControlEventFlag: "DC Event Flag",
  timeFrom: "Time From (UTC)",
  timeTo: "Time To (UTC)",
  volume: "Volume",
  systemManagementActionFlag: "SO Flag",
};

interface Props {
  data: DemandControlInstructionsTableModel[];
}

const DemandControlInstructionsTable: React.FC<Props> = ({ data }) => (
  <>
    <ResultsCount count={data.length} />
    <SortableTable
      headers={DemandControlInstructionHeaders}
      items={data}
      dataTestId="demand-control-instructions-table"
      renderFns={{
        timeFrom: ({ timeFrom }): TableCellRender => ({
          content: StyledDateTime(timeFrom),
        }),
        timeTo: ({ timeTo }): TableCellRender => ({
          content: StyledDateTime(timeTo),
        }),
        systemManagementActionFlag: ({
          systemManagementActionFlag,
        }): TableCellRender => ({
          content: StyledBoolean(systemManagementActionFlag),
        }),
      }}
      scrollable={false}
    />
  </>
);

export default DemandControlInstructionsTable;
