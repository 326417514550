import InformationTooltip from "components/components/InformationTooltip/InformationTooltip";
import {
  ColumnHeader,
  HasId,
  MaybeHasUrl,
  WithoutId,
} from "components/components/SortableTable/SortableTable";
import React, { useEffect, useState } from "react";
import colours from "styles/colours";
import { roundToMax4dp } from "utils/tableHelpers";

export const useTableScrolling = (): {
  showScrollButtons: boolean;
  disableLeftScroll: boolean;
  disableRightScroll: boolean;
  scrollLeft: () => void;
  scrollRight: () => void;
  setTableRef: (scrollableTable: HTMLDivElement | null) => void;
} => {
  const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
  const [fullTableWidth, setFullTableWidth] = useState<number | null>(null);
  const [displayedTableWidth, setDisplayedTableWidth] = useState<number | null>(
    null
  );
  const [scrollPosition, setScrollPosition] = useState<number | null>(null);

  useEffect(() => {
    if (tableRef) {
      setFullTableWidth(tableRef.scrollWidth);
      setDisplayedTableWidth(tableRef.clientWidth);
      setScrollPosition(tableRef.scrollLeft);
    }
  }, [tableRef]);

  useEffect(() => {
    const updateScrollPosition = (): void => {
      if (tableRef) {
        setScrollPosition(tableRef.scrollLeft);
      }
    };
    tableRef?.addEventListener("scroll", updateScrollPosition);
    return () => {
      tableRef?.removeEventListener("scroll", updateScrollPosition);
    };
  }, [tableRef]);

  useEffect(() => {
    const updateTableWidths = (): void => {
      if (tableRef) {
        setFullTableWidth(tableRef.scrollWidth);
        setDisplayedTableWidth(tableRef.clientWidth);
        setScrollPosition(tableRef.scrollLeft);
      }
    };
    window.addEventListener("resize", updateTableWidths);
    return () => {
      window.removeEventListener("resize", updateTableWidths);
    };
  }, [tableRef]);

  const showScrollButtons =
    fullTableWidth !== null &&
    displayedTableWidth !== null &&
    displayedTableWidth < fullTableWidth;

  const disableLeftScroll = scrollPosition === 0;
  const disableRightScroll =
    fullTableWidth !== null &&
    displayedTableWidth !== null &&
    scrollPosition !== null &&
    // The second condition `scrollPosition !== 0` is required to ensure that
    // the cypress tests run correctly
    // Furthermore, the first condition requires rounding the scroll position
    // as the widths are automatically rounded, leading to a small error in
    // comparisons when using the decimal value instead
    Math.floor(scrollPosition) + 1 >= fullTableWidth - displayedTableWidth &&
    scrollPosition !== 0;

  const scrollLeft = (): void => tableRef?.scrollTo({ left: 0 });
  const scrollRight = (): void =>
    tableRef?.scrollTo({ left: fullTableWidth ?? 0 });

  return {
    showScrollButtons,
    disableLeftScroll,
    disableRightScroll,
    scrollLeft,
    scrollRight,
    setTableRef,
  };
};

export const calculateTotal = <TItem extends HasId & MaybeHasUrl>(
  orderedItems: TItem[],
  key: keyof WithoutId<TItem>
): Number | null =>
  roundToMax4dp(
    orderedItems.reduce(
      (total, item) => total + (item[key] == null ? 0 : Number(item[key])),
      0
    )
  );

export const getHeaderName = (header: string | ColumnHeader): string =>
  typeof header === "object" ? header.headerText : header;

export const getHeaderElement = (
  header: string | ColumnHeader
): JSX.Element => {
  if (typeof header === "object") {
    return (
      <>
        {header.headerText}
        <InformationTooltip colour={colours.white}>
          <>{header.tooltipText}</>
        </InformationTooltip>
      </>
    );
  }
  return <>{header}</>;
};
