import OutageProfile from "./outageProfile";
import RemitMessageDetailsModel, {
  EventStatus,
} from "./remitMessageDetailsModel";

interface RemitMessageDetailsModelTransformed {
  id: number;
  mrid: string;
  revisionNumber: number;
  createdTime: Date;
  publishTime: Date;
  messageType: string;
  messageHeading: string;
  eventType: string | null;
  unavailabilityType: string | null;
  participantId: string;
  registrationCode: string;
  assetId: string;
  assetType: string | null;
  affectedUnit: string | null;
  affectedUnitEIC: string | null;
  affectedArea: string | null;
  biddingZone: string | null;
  fuelType: string | null;
  normalCapacity: number | null;
  availableCapacity: number | null;
  unavailableCapacity: number | null;
  eventStatus: EventStatus;
  eventStartTime: Date;
  eventEndTime: Date | null;
  durationUncertainty: string | null;
  cause: string;
  relatedInformation: string | null;
  outageProfile: OutageProfile[] | null;
}

export const getTransformedRemitMessageDetail = (
  messagesData: RemitMessageDetailsModel
): RemitMessageDetailsModelTransformed => ({
  ...messagesData,
  createdTime: new Date(messagesData.createdTime),
  publishTime: new Date(messagesData.publishTime),
  eventStartTime: new Date(messagesData.eventStartTime),
  eventEndTime: messagesData.eventEndTime
    ? new Date(messagesData.eventEndTime)
    : null,
});

export default RemitMessageDetailsModelTransformed;
