import classnames from "classnames";
import React from "react";

import { Bar, ChildContainer, Loader, LoaderContainer } from "./style";

interface Props {
  isLoading: boolean;
  children?: JSX.Element;
  className?: string;
}

const LoadingSpinner: React.FC<Props> = ({
  isLoading,
  children,
  className,
}: Props) => (
  <LoaderContainer className={className}>
    <Loader
      className={classnames({ loading: isLoading })}
      aria-label="Loading"
      data-test-id="loading-spinner"
      role="status"
    >
      <Bar className="bar1" />
      <Bar className="bar2" />
      <Bar className="bar3" />
      <Bar className="bar4" />
      <Bar className="bar5" />
    </Loader>
    <ChildContainer className={classnames({ loading: isLoading })}>
      {children}
    </ChildContainer>
  </LoaderContainer>
);

export default LoadingSpinner;
