import SectionPanel from "components/homepage/SectionPanel/SectionPanel";
import { RequestStatus } from "hooks/useRequest";
import RemitMessageDetailsModelTransformed from "models/remit/remitMessageDetailsModelTransformed";
import React from "react";
import { DataOrErrored } from "utils/dataOrErrored";
import routeConstants from "utils/routeConstants";

import RemitContent from "./RemitContent/RemitContent";

export type RemitData = {
  remitMessages: DataOrErrored<RemitMessageDetailsModelTransformed[]>;
} | null;

interface Props {
  requestStatus: RequestStatus;
  data: RemitData;
  lastRefreshedDate?: Date;
}

const Remit: React.FC<Props> = ({ requestStatus, data, lastRefreshedDate }) => (
  <SectionPanel
    title="Unplanned unavailability of electrical facilities"
    description={
      <p>
        This shows the 9 most recent messages regarding ongoing unplanned
        unavailability events that have been published in the last 12 hours.
      </p>
    }
    link={{
      text: "See all REMIT",
      href: routeConstants.remit,
    }}
    data={data}
    requestStatus={requestStatus}
    testId="remit"
    lastRefreshedDate={lastRefreshedDate}
  >
    {({ remitMessages }): React.ReactNode => (
      <RemitContent remitMessages={remitMessages} />
    )}
  </SectionPanel>
);

export default Remit;
