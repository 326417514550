import classnames from "classnames";
import Button from "components/components/Button/Button";
import Dropdown from "components/components/Dropdown/Dropdown";
import {
  ApplyFiltersButton,
  ButtonsDiv,
  UnsavedChangesDiv,
  ErrorMessageDiv,
} from "components/components/FiltersForm/style";
import {
  MarketViewBmuFilters,
  mapToBmuTypeOptions,
  mapToFuelTypeOptions,
  mapToLeadPartyOptions,
} from "components/components/balancingMechanism/marketView/MarketViewFilters/helpers";
import { useMarketViewContext } from "contexts/MarketViewContext";
import { RequestStatus } from "hooks/useRequest";
import { FormEvent, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { areObjectsEqual } from "utils/objectUtils";

import { DropdownsDiv, StyledForm } from "./style";

interface Props {
  show: boolean;
}

const MarketViewFiltersForm: React.FC<Props> = ({ show }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = useState<MarketViewBmuFilters>({
    leadParty: searchParams.get("leadParty"),
    bmuType: searchParams.get("bmuType"),
    fuelType: searchParams.get("fuelType"),
  });
  const [activeFilters, setActiveFilters] =
    useState<MarketViewBmuFilters>(selectedFilters);
  const unsavedChanges = useMemo(
    () =>
      !areObjectsEqual<MarketViewBmuFilters>(activeFilters, selectedFilters),
    [selectedFilters, activeFilters]
  );

  const {
    bmuDetails: { data: bmuData, status },
    setBmuFilters: setAppliedFilters,
  } = useMarketViewContext();

  const updateFilter = <T extends keyof MarketViewBmuFilters>(
    newOption: string,
    key: T
  ): void => {
    setSelectedFilters((curr) => ({ ...curr, [key]: newOption }));
  };

  const onApplyFilters = (filters: MarketViewBmuFilters): void => {
    const { leadParty, bmuType, fuelType } = filters;
    const queryParams = {
      ...(leadParty && { leadParty }),
      ...(bmuType && { bmuType }),
      ...(fuelType && { fuelType }),
    };

    setSearchParams(queryParams);
    setAppliedFilters(filters);
    history.replaceState(
      {
        query:
          leadParty || bmuType || fuelType
            ? `?${new URLSearchParams(queryParams).toString()}`
            : "",
      },
      ""
    );
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onApplyFilters(selectedFilters);
    setActiveFilters(selectedFilters);
  };

  const handleReset = (): void => {
    if (
      selectedFilters.leadParty ||
      selectedFilters.bmuType ||
      selectedFilters.fuelType
    ) {
      setSelectedFilters({
        leadParty: null,
        bmuType: null,
        fuelType: null,
      });
    }
  };

  const handleDropdownChange = <T extends keyof MarketViewBmuFilters>(
    newValue: string,
    oldValue: string | null,
    key: T
  ): void => {
    if (newValue !== oldValue) {
      updateFilter(newValue, key);
    }
  };

  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      className={classnames({ visible: show })}
      aria-live="polite"
    >
      <DropdownsDiv>
        <div data-test-id="lead-party-filter">
          <label>Lead party name</label>
          <Dropdown
            values={mapToLeadPartyOptions(bmuData)}
            selectedValue={selectedFilters.leadParty}
            onChange={(newValue: string): void => {
              handleDropdownChange(
                newValue,
                selectedFilters.leadParty,
                "leadParty"
              );
            }}
            ariaLabel="Lead party dropdown"
            status={status}
          />
        </div>
        <div data-test-id="bmu-type-filter">
          <label>BMU type</label>
          <Dropdown
            values={mapToBmuTypeOptions(bmuData)}
            selectedValue={selectedFilters.bmuType}
            onChange={(newValue: string): void =>
              handleDropdownChange(newValue, selectedFilters.bmuType, "bmuType")
            }
            ariaLabel="BMU type dropdown"
            status={status}
          />
        </div>
        <div data-test-id="fuel-type-filter">
          <label>Fuel type</label>
          <Dropdown
            values={mapToFuelTypeOptions(bmuData)}
            selectedValue={selectedFilters.fuelType}
            onChange={(newValue: string): void => {
              handleDropdownChange(
                newValue,
                selectedFilters.fuelType,
                "fuelType"
              );
            }}
            ariaLabel="Fuel type dropdown"
            status={status}
          />
        </div>
      </DropdownsDiv>
      <ButtonsDiv>
        <ApplyFiltersButton buttonText="Apply Filters" type="submit" />
        <Button
          className="link-inline"
          buttonText="Reset all filters"
          type="reset"
          onClick={handleReset}
        />
      </ButtonsDiv>
      {unsavedChanges && (
        <UnsavedChangesDiv>
          There are unsaved changes in your filters. Remember to click
          &quot;apply filters&quot; to see accurate results in the table below.
        </UnsavedChangesDiv>
      )}
      {status === RequestStatus.ERRORED && (
        <ErrorMessageDiv>
          An error occurred while loading BMU filters, please refresh the page.
        </ErrorMessageDiv>
      )}
    </StyledForm>
  );
};

export default MarketViewFiltersForm;
