import TileChild from "components/shared/tile/TileChild/TileChild";
import TileParent from "components/shared/tile/TileParent/TileParent";
import TileRowContainer from "components/shared/tile/TileRowContainer/TileRowContainer";
import React from "react";

interface Props {
  children: JSX.Element;
}

const SummaryTile: React.FC<Props> = ({ children }: Props) => (
  <TileRowContainer>
    <TileParent>
      <TileChild isMiniTile as="article">
        {children}
      </TileChild>
    </TileParent>
  </TileRowContainer>
);

export default SummaryTile;
