import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { DropdownIndicatorProps, components } from "react-select";
import colours from "styles/colours";

export const DropdownIndicator: React.FC<DropdownIndicatorProps<unknown>> = (
  props: DropdownIndicatorProps<unknown>
) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Icon iconName={faAngleDown} colour={colours.darkGrey} />
    </components.DropdownIndicator>
  );
