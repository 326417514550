import { BoldedText, ItemName, NewItemLabel } from "components/homepage/style";
import React from "react";
import {
  DateFormat,
  formatDateTimeString,
  isTodayInUK,
} from "utils/dateHelpers";

import {
  HorizontalLayout,
  Label,
  Number,
  OrangeBubble,
  StyledListItemWithNewHighlight,
} from "./style";

interface Props {
  participantName: string;
  creditDefaultLevel: number;
  enteredDefaultSettlementDate: Date;
  enteredDefaultSettlementPeriod: number;
  clearedDefaultSettlementDate: Date | null;
  clearedDefaultSettlementPeriod: number | null;
}

const CreditDefaultNoticeItem: React.FC<Props> = ({
  participantName,
  creditDefaultLevel,
  clearedDefaultSettlementPeriod,
  enteredDefaultSettlementPeriod,
  clearedDefaultSettlementDate,
  enteredDefaultSettlementDate,
}) => {
  const isNew = isTodayInUK(enteredDefaultSettlementDate);
  const newMessageId = `new-message-${enteredDefaultSettlementDate}-${enteredDefaultSettlementPeriod}-${participantName}-${creditDefaultLevel}`;
  return (
    <StyledListItemWithNewHighlight
      aria-describedby={isNew ? newMessageId : undefined}
      isNew={isNew}
    >
      {isNew && (
        <NewItemLabel id={newMessageId}>New credit notice</NewItemLabel>
      )}
      <HorizontalLayout>
        <OrangeBubble data-test-id="level-bubble">
          <Label data-test-id="level-label">Level</Label>
          <Number>{creditDefaultLevel}</Number>
        </OrangeBubble>
        <div>
          <ItemName>{participantName}</ItemName>
          <div>
            <BoldedText>Entered</BoldedText>:{" "}
            {formatDateTimeString(
              enteredDefaultSettlementDate,
              DateFormat.DateOnly
            )}
            , Settlement period {enteredDefaultSettlementPeriod}
          </div>
          <div>
            <BoldedText>Cleared</BoldedText>:{" "}
            {clearedDefaultSettlementDate ? (
              <>
                {formatDateTimeString(
                  clearedDefaultSettlementDate,
                  DateFormat.DateOnly
                )}
                , Settlement period {clearedDefaultSettlementPeriod}
              </>
            ) : (
              <>-</>
            )}
          </div>
        </div>
      </HorizontalLayout>
    </StyledListItemWithNewHighlight>
  );
};

export default CreditDefaultNoticeItem;
