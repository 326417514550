/**
 * Transforms the input string to lowercase and replaces spaces with hyphens
 * @param text input string
 */
export const toKebabCase = (text: string): string =>
  text.toLowerCase().split(" ").join("-");

/**
 * Copies the selected string to clipboard
 * @param text input string
 * @param onSuccess callback invoked if the copy succeeds
 * @param onError callback invoked if the copy fails
 */
export const copyText = (
  text: string,
  onSuccess: () => void,
  onError: () => void
): void => {
  navigator.clipboard.writeText(text).then(onSuccess, onError);
};

// Splits on the "\n\n" string and replaces "\n" and \r with either nothing, or a space
// dependent on context.
// We need this because of how string data is received from the API (particuarly for
// events).
export const processNewLinesAndSplit = (text: string): string[] =>
  text
    .split("\\n\\n")
    .map((line) =>
      line
        .replace(/(?<= )(\\n|\r)+|(\\n|\r)+(?= )/g, "") // if there is an adjacent space already, replace one or more with empty string
        .replace(/(\\n|\r)+/g, " ") // otherwise convert it to a space
        .trim()
    )
    .filter((line) => !line.match(/^\s*$/)); // remove any lines that are entirely whitespace
