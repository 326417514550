import {
  ShowMore,
  ShowMoreRef,
  ShowMoreToggleLinesFn,
} from "@re-dev/react-truncate";
import React, { ReactNode, useRef } from "react";

import { ExpandButton } from "./style";

type Props = {
  children: ReactNode;
  lines?: number;
  className?: string;
};

const ShowMoreButton: React.FC<{
  onClick: ShowMoreToggleLinesFn;
}> = ({ onClick }) => (
  <>
    <span>...</span>
    <ExpandButton data-test-id="show-more-button" onClick={onClick}>
      Show full message
    </ExpandButton>
  </>
);

const ShowLessButton: React.FC<{
  onClick: ShowMoreToggleLinesFn;
}> = ({ onClick }) => (
  <ExpandButton data-test-id="show-less-button" onClick={onClick}>
    Show less
  </ExpandButton>
);

/**
 * Truncates text at a certain number of lines and shows a button to expand/collapse.
 *
 * It is a wrapper around @re-dev/react-truncate's ShowMore component.
 *
 * !! Warning: if it truncates an element then that element's tag is not preserved, e.g.
 * 'p' tags are changed to spans when cut off part way.
 */
const ShowMoreText: React.FC<Props> = ({ children, lines = 2, className }) => {
  const ref = useRef<ShowMoreRef>(null);

  const toggleLines: ShowMoreToggleLinesFn = (e) => {
    ref.current?.toggleLines(e);
  };

  return (
    <ShowMore
      lines={lines}
      more={<ShowMoreButton onClick={toggleLines} />}
      less={<ShowLessButton onClick={toggleLines} />}
      ref={ref}
      className={className ?? ""}
    >
      {children}
    </ShowMore>
  );
};

export default ShowMoreText;
