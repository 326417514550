import SectionPanel from "components/homepage/SectionPanel/SectionPanel";
import { RequestStatus } from "hooks/useRequest";
import { SettlementMessageModelTransformed } from "models/detailedSystemPrices/settlementModels";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import React from "react";
import { DataOrErrored } from "utils/dataOrErrored";
import routeConstants from "utils/routeConstants";

import SystemSellBuyPricesContent from "./SystemSellBuyPricesContent/SystemSellBuyPricesContent";

export type SystemSellBuyPriceData = {
  settlementMessages: DataOrErrored<SettlementMessageModelTransformed[]>;
  systemPrices: DataOrErrored<SystemPriceModel[]>;
  systemPricesDateFilter: DateFilterModel;
} | null;

interface Props {
  requestStatus: RequestStatus;
  data: SystemSellBuyPriceData;
  lastRefreshedDate?: Date;
}

const SystemSellBuyPrices: React.FC<Props> = ({
  requestStatus,
  data,
  lastRefreshedDate,
}) => (
  <SectionPanel
    testId="system-sell-buy-prices"
    title="System Buy / Sell Prices"
    description={
      <>
        <p>
          The indicative price calculation is published 15 minutes after the
          effective Settlement Period based on parameters available at the time
          the calculations were run.
        </p>
        <p>
          The calculations are refreshed at D+1, i.e. the following day, to
          account for late actions or changes made by the System Operator
          showing a more accurate view of the price calculations.
        </p>
      </>
    }
    link={{
      text: "See full graph",
      href: routeConstants.systemPrices,
    }}
    requestStatus={requestStatus}
    data={data}
    lastRefreshedDate={lastRefreshedDate}
  >
    {({
      systemPrices,
      settlementMessages,
      systemPricesDateFilter,
    }): React.ReactNode => (
      <SystemSellBuyPricesContent
        settlementMessages={settlementMessages}
        systemPrices={systemPrices}
        systemPricesDateFilter={systemPricesDateFilter}
      />
    )}
  </SectionPanel>
);

export default SystemSellBuyPrices;
