import Checkbox from "components/components/CheckboxInput/Checkbox";
import RadioButtonsRow from "components/components/RadioInput/RadioButtonsRow";
import {
  StyledBoolean,
  StyledSwatch,
  StyledTimeOnly,
} from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import { NoWrapCell } from "components/components/SortableTable/style";
import Swatch from "components/components/Swatch/Swatch";
import {
  HideableContainer,
  InlineSwatchWrapper,
} from "components/dataVisualization/balancingMechanismTabs/Physical/PhysicalTables/style";
import {
  mapToBidOfferAcceptanceRow,
  mapToPhysicalRow,
  mapToStableLimitRow,
} from "components/dataVisualization/balancingMechanismTabs/Physical/bmuView/utils";
import { useBmuViewContext } from "contexts/BmuViewContext";
import {
  PhysicalChartDataset,
  physicalChartDatasetDisplayName,
  pointShapeForPhysicalDataset,
} from "models/bmuData/physicalChartData";
import React, { useState } from "react";
import { physicalDatasetColours } from "styles/colours";
import { transformStableLimitDataToRangedData as transformStableLimitDataToRangedData } from "utils/ChartDataUtils/physical/stableDataTransformer";
import {
  addMinsToDate,
  DateFormat,
  formatDateTimeString,
  getNumberOfDaysBetweenDates,
  toSettlementPeriodRangeString,
} from "utils/dateHelpers";
import { filterRawModelToSettlementPeriod } from "utils/tableHelpers";

interface Props {
  settlementPeriodDatetime: Date | null;
}

const PhysicalTables: React.FC<Props> = ({ settlementPeriodDatetime }) => {
  const {
    physicalDetails: { data },
  } = useBmuViewContext();
  const [radioButtonSelected, setRadioButtonSelected] = useState("boal-radio");
  const [hideBoalFlags, setHideBoalFlags] = useState(false);

  const bidOfferAcceptanceTableData =
    data?.bidOfferAcceptanceData && settlementPeriodDatetime
      ? mapToBidOfferAcceptanceRow(
          filterRawModelToSettlementPeriod(
            settlementPeriodDatetime,
            data?.bidOfferAcceptanceData
          )
        )
      : [];

  const physicalTableData =
    data?.physicalData && settlementPeriodDatetime
      ? mapToPhysicalRow(
          filterRawModelToSettlementPeriod(
            settlementPeriodDatetime,
            data?.physicalData
          )
        )
      : [];

  const stableTableData =
    data?.chartData?.stableLimitChartData && settlementPeriodDatetime
      ? mapToStableLimitRow(
          transformStableLimitDataToRangedData(
            data?.chartData?.stableLimitChartData,
            settlementPeriodDatetime,
            addMinsToDate(settlementPeriodDatetime, 30)
          ).filter(
            (row) =>
              row.timeTo > settlementPeriodDatetime &&
              row.timeFrom < addMinsToDate(settlementPeriodDatetime, 30)
          )
        )
      : [];

  return (
    <div data-test-id="physical-tables" id="physical-tables">
      {settlementPeriodDatetime ? (
        <>
          <h3>{toSettlementPeriodRangeString(settlementPeriodDatetime)}</h3>

          <RadioButtonsRow
            radioButtons={[
              {
                inputId: "boal-radio",
                inlineLabelContent: (
                  <span key="boal-data-element">
                    BOAL data (
                    <InlineSwatchWrapper>
                      <Swatch
                        colour={physicalDatasetColours.BOAL}
                        shape={pointShapeForPhysicalDataset.BOAL}
                      />
                    </InlineSwatchWrapper>
                    )
                  </span>
                ),
              },
              {
                inputId: "other-radio",
                inlineLabelContent: "Other data",
              },
            ]}
            selected={radioButtonSelected}
            setSelected={setRadioButtonSelected}
          />
          <HideableContainer isHidden={radioButtonSelected !== "boal-radio"}>
            <SortableTable
              headers={{
                timeFrom: "From time (UTC)",
                levelFrom: "From level (MW)",
                timeTo: "To time (UTC)",
                levelTo: "To level (MW)",
                acceptId: "Accept ID",
                acceptTime: "Accept time (UTC)",
                deemedFlag: "Deemed",
                soFlag: "SO",
                storFlag: "STOR provider",
                rrFlag: "RR instruction",
              }}
              items={bidOfferAcceptanceTableData}
              renderFns={{
                timeFrom: ({ timeFrom }): TableCellRender => ({
                  content: StyledTimeOnly(timeFrom),
                }),
                timeTo: ({ timeTo }): TableCellRender => ({
                  content: StyledTimeOnly(timeTo),
                }),
                acceptTime: ({ acceptTime }): TableCellRender => ({
                  content: StyledTimeOnly(acceptTime),
                }),
                deemedFlag: ({ deemedFlag }): TableCellRender => ({
                  content: StyledBoolean(deemedFlag),
                }),
                soFlag: ({ soFlag }): TableCellRender => ({
                  content: StyledBoolean(soFlag),
                }),
                storFlag: ({ storFlag }): TableCellRender => ({
                  content: StyledBoolean(storFlag),
                }),
                rrFlag: ({ rrFlag }): TableCellRender => ({
                  content: StyledBoolean(rrFlag),
                }),
              }}
              headerGroupings={
                hideBoalFlags
                  ? []
                  : [
                      {
                        fromColumnNumber: 6,
                        toColumnNumber: 9,
                        name: "Flags",
                      },
                    ]
              }
              dataTestId="bid-offer-acceptance-table-physical-data"
              hiddenColumns={
                hideBoalFlags
                  ? ["deemedFlag", "soFlag", "storFlag", "rrFlag"]
                  : []
              }
            />
            <div>
              <Checkbox
                inputId="toggle-hidden-boal-flags"
                isChecked={hideBoalFlags}
                isInline
                onChange={(): void =>
                  setHideBoalFlags((prevState) => !prevState)
                }
                inlineLabelContent='Hide all "Flag" columns'
              />
            </div>
          </HideableContainer>
          <HideableContainer isHidden={radioButtonSelected === "boal-radio"}>
            <SortableTable
              headers={{
                dataset: "Dataset",
                timeFrom: "From time (UTC)",
                levelFrom: "From level (MW)",
                timeTo: "To time (UTC)",
                levelTo: "To level (MW)",
              }}
              items={physicalTableData}
              renderFns={{
                dataset: ({ dataset }): TableCellRender => ({
                  content: StyledSwatch(
                    physicalDatasetColours[dataset as PhysicalChartDataset],
                    pointShapeForPhysicalDataset[
                      dataset as PhysicalChartDataset
                    ],
                    physicalChartDatasetDisplayName[
                      dataset as PhysicalChartDataset
                    ]
                  ),
                }),
                timeFrom: ({ timeFrom }): TableCellRender => ({
                  content: StyledTimeOnly(timeFrom),
                }),
                timeTo: ({ timeTo }): TableCellRender => ({
                  content: StyledTimeOnly(timeTo),
                }),
              }}
              dataTestId="physical-table-physical-data"
            />
            <br />
            <SortableTable
              headers={{
                dataset: "Dataset",
                publishTime: "Published time (UTC)",
                level: "Level (MW)",
              }}
              items={stableTableData}
              renderFns={{
                dataset: ({ dataset }): TableCellRender => ({
                  content: StyledSwatch(
                    physicalDatasetColours[dataset as PhysicalChartDataset],
                    pointShapeForPhysicalDataset[
                      dataset as PhysicalChartDataset
                    ],
                    physicalChartDatasetDisplayName[
                      dataset as PhysicalChartDataset
                    ]
                  ),
                }),
                publishTime: ({ publishTime }): TableCellRender => ({
                  content: (
                    <NoWrapCell>
                      {formatDateTimeString(
                        publishTime,
                        getNumberOfDaysBetweenDates(
                          publishTime,
                          settlementPeriodDatetime
                        )
                          ? DateFormat.DateTimeWithHyphen
                          : DateFormat.TimeOnly
                      )}
                    </NoWrapCell>
                  ),
                }),
              }}
              dataTestId="stable-table-physical-data"
              shouldOverlapPreviousElement={false}
            />
          </HideableContainer>
        </>
      ) : (
        // Only show prompt when data is available
        !!data?.chartData?.rangedData.length &&
        "Select a settlement period on the graph above to see the values of each dataset."
      )}
    </div>
  );
};

export default PhysicalTables;
