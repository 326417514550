import React from "react";

import { StyledTileParent } from "./style";

interface Props {
  children: JSX.Element;
}

/**
 * A react wrapper component for the Bulma tile parent class.
 *
 * Use with TileChild and TileRowContainer.
 */
const TileParent: React.FC<Props> = ({ children }) => (
  <StyledTileParent className="tile is-parent">{children}</StyledTileParent>
);

export default TileParent;
