import { ResponsivePie } from "@nivo/pie";
import React from "react";
import colours from "styles/colours";

import { FixedSizeContainer, DisplayText, BoldedText } from "./style";

interface Props {
  percentage: number;
  displayText: string;
  displayTextLabel?: string;
}
/**
 * A hollow pie chart that visually represents a percentage with color mappings based on thresholds.
 * The central gap displays textual information about the percentage.
 *
 * **Props:**
 * - `percentage`: The percentage value represented by the filled portion of the pie chart.
 * - `displayText`: The text displayed inside the hollow center.
 * - `displayTextLabel` (optional): A label above `displayText` to provide additional context.
 *
 * **Color Thresholds:**
 * - `>50%` → Orange
 * - `<=50%` → Red
 * - `0%` → Faded red, fully filled pie chart, and red text.
 */
const PercentageDonutChart: React.FC<Props> = ({
  percentage,
  displayText,
  displayTextLabel,
}) => {
  const chartData = [
    {
      id: "percentage",
      value: percentage,
    },
    {
      id: "missingPercentage",
      value: 100 - percentage,
    },
  ];

  const colourThresholdMap = [
    { color: colours.darkOrange, threshold: 50 },
    { color: colours.crimsonRed, threshold: 0 },
  ];

  const percentageColour =
    colourThresholdMap.find(({ threshold }) => percentage > threshold)?.color ??
    colours.white;

  const missingColour = percentage === 0 ? colours.fadedRed : colours.white;
  return (
    <FixedSizeContainer>
      <DisplayText isRed={percentage === 0}>
        <span>
          {displayTextLabel && (
            <>
              {displayTextLabel}
              <br />
            </>
          )}
          <BoldedText>{displayText}</BoldedText>
        </span>
      </DisplayText>
      {/* The pie chart is not accessible (e.g. has role="img" but no way of adding an accessible
      title). Since the chart adds no information for screenreader users we hide it completely. */}
      <FixedSizeContainer aria-hidden>
        <ResponsivePie
          data={chartData}
          innerRadius={0.6}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          isInteractive={false}
          colors={[percentageColour, missingColour]}
        />
      </FixedSizeContainer>
    </FixedSizeContainer>
  );
};

export default PercentageDonutChart;
