import classnames from "classnames";
import React from "react";
import { StyledComponentsPolymorphicProp } from "utils/commonTypes";

import { StyledPanel } from "./style";

interface Props {
  isMiniTile?: boolean;
  className?: string;
  as?: StyledComponentsPolymorphicProp;
  children: React.ReactNode;
}

/**
 * A react wrapper component for the Bulma tile child class, styled as a Panel.
 *
 * Use with TileParent and TileRowContainer.
 */
const TileChild: React.FC<Props> = ({
  isMiniTile,
  className,
  as,
  children,
}) => (
  <StyledPanel
    className={classnames("tile is-child", className)}
    forwardedAs={as}
    isMiniTile={isMiniTile}
  >
    {children}
  </StyledPanel>
);

export default TileChild;
