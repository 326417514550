import {
  faBoltLightning,
  faClock,
  faClose,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import { BoldedText } from "components/homepage/style";
import { EventStatus } from "models/remit/remitMessageDetailsModel";
import React from "react";
import {
  DateFormat,
  formatDateTimeString,
  formatUTCDuration,
  getDurationFromStartAndEndDatesUTC,
} from "utils/dateHelpers";
import routeConstants from "utils/routeConstants";

import {
  ModalHeaderContainer,
  Title,
  InfoTextContainer,
  GridLayoutList,
  GridListItem,
  CloseButton,
  EventStatusCircle,
  MarginLeftInternalLink,
} from "./style";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  mrid: string;
  messageId: number;
  assetId: string;
  eventStatus: EventStatus;
  eventStartTime: Date;
  eventEndTime: Date | null;
  fuelType: string | null;
  normalCapacity: number;
  availableCapacity: number;
  unavailableCapacity: number;
}

const RemitInfoPopup: React.FC<Props> = ({
  showModal,
  setShowModal,
  mrid,
  messageId,
  assetId,
  eventStatus,
  eventStartTime,
  eventEndTime,
  fuelType,
  normalCapacity,
  availableCapacity,
  unavailableCapacity,
}) => {
  const messageDetailsLink = `${routeConstants.remitMessageDetails}/${mrid}?messageId=${messageId}`;

  return (
    <Modal
      size={ModalSize.medium}
      isActive={showModal}
      onClose={(): void => setShowModal(false)}
    >
      <ModalHeaderContainer>
        <Title>{assetId}</Title>
        <CloseButton
          onClick={(): void => setShowModal(false)}
          data-test-id="close-button"
          aria-label="Close modal"
        >
          <Icon iconName={faClose} size={IconSize.small} />
        </CloseButton>
      </ModalHeaderContainer>
      <GridLayoutList>
        <GridListItem>
          <EventStatusCircle eventStatus={eventStatus} />
          <InfoTextContainer>
            <BoldedText>Event status </BoldedText>
            <div>{eventStatus}</div>
          </InfoTextContainer>
        </GridListItem>
        {eventEndTime ? (
          <GridListItem>
            <Icon iconName={faClock} size={IconSize.mediumSmall} />
            <InfoTextContainer>
              <BoldedText>Event duration</BoldedText>
              <div>
                {formatUTCDuration(
                  getDurationFromStartAndEndDatesUTC(
                    eventStartTime,
                    eventEndTime
                  )
                )}
              </div>
            </InfoTextContainer>
          </GridListItem>
        ) : null}
        <GridListItem>
          <Icon iconName={faLightbulb} size={IconSize.mediumSmall} />
          <InfoTextContainer>
            <BoldedText>Normal capacity</BoldedText>
            <div>{normalCapacity}</div>
          </InfoTextContainer>
        </GridListItem>
        <GridListItem>
          <Icon iconName={faClock} size={IconSize.mediumSmall} />
          <InfoTextContainer>
            <BoldedText>Event start</BoldedText>
            <div>
              {formatDateTimeString(eventStartTime, DateFormat.DateTime)}
            </div>
          </InfoTextContainer>
        </GridListItem>
        <GridListItem>
          <Icon iconName={faLightbulb} size={IconSize.mediumSmall} />
          <InfoTextContainer>
            <BoldedText>Available capacity</BoldedText>
            <div>{availableCapacity}</div>
          </InfoTextContainer>
        </GridListItem>
        {eventEndTime ? (
          <GridListItem>
            <Icon iconName={faClock} size={IconSize.mediumSmall} />
            <InfoTextContainer>
              <BoldedText>Event end</BoldedText>
              <div>
                {formatDateTimeString(eventEndTime, DateFormat.DateTime)}
              </div>
            </InfoTextContainer>
          </GridListItem>
        ) : null}
        <GridListItem>
          <Icon iconName={faLightbulb} size={IconSize.mediumSmall} />
          <InfoTextContainer>
            <BoldedText>Unavailable capacity </BoldedText>
            <div>{unavailableCapacity}</div>
          </InfoTextContainer>
        </GridListItem>
        {fuelType ? (
          <GridListItem>
            <Icon iconName={faBoltLightning} size={IconSize.mediumSmall} />
            <InfoTextContainer>
              <BoldedText>Fuel type</BoldedText>
              <div>{fuelType}</div>
            </InfoTextContainer>
          </GridListItem>
        ) : null}
      </GridLayoutList>
      <MarginLeftInternalLink
        to={messageDetailsLink}
        target="_blank"
        rel="noreferrer"
      >
        Show full message
      </MarginLeftInternalLink>
    </Modal>
  );
};

export default RemitInfoPopup;
