import { normaliseDateToMinuteInterval } from "utils/DateUtils";
import {
  addDaysToDate,
  DAYS_IN_ONE_WEEK,
  getNumberOfHoursBetweenDates,
  HOURS_IN_ONE_DAY,
} from "utils/dateHelpers";

interface DateFilter {
  startDate: Date;
  endDate: Date;
  readonly normalisedStartDate: Date;
  readonly normalisedEndDate: Date;
}

export default class DateFilterModel implements DateFilter {
  constructor(public startDate: Date, public endDate: Date) {}

  get normalisedStartDate(): Date {
    return normaliseDateToMinuteInterval(this.startDate, 30);
  }

  get normalisedEndDate(): Date {
    return normaliseDateToMinuteInterval(this.endDate, 30);
  }

  restrictToMaxDaysFromStart(maxDaysBetweenDates: number): DateFilterModel {
    const restrictedEndDate =
      getNumberOfHoursBetweenDates(this.startDate, this.endDate) <=
      maxDaysBetweenDates * HOURS_IN_ONE_DAY
        ? this.endDate
        : addDaysToDate(this.startDate, maxDaysBetweenDates);

    return new DateFilterModel(this.startDate, restrictedEndDate);
  }

  restrictToMaxDaysFromEnd(maxDaysBetweenDates: number): DateFilterModel {
    const restrictedStartDate =
      getNumberOfHoursBetweenDates(this.startDate, this.endDate) <=
      maxDaysBetweenDates * HOURS_IN_ONE_DAY
        ? this.startDate
        : addDaysToDate(this.endDate, -maxDaysBetweenDates);

    return new DateFilterModel(restrictedStartDate, this.endDate);
  }

  restrictToMaxOneWeek(): DateFilterModel {
    return this.restrictToMaxDaysFromStart(DAYS_IN_ONE_WEEK);
  }
}
