import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection, {
  DocumentationInformation,
} from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import IndicativeDemandPeakChartContainer from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/IndicativeDemandPeakChartContainer";
import useIndicativeDemandPeakChartContainerData from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/useIndicativePeakDemandData";
import IndicativeDemandPeakSummaryTile from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakSummaryTile/IndicativeDemandPeakSummaryTile";
import useToggle from "hooks/useToggle";
import React from "react";
import { getMostRecentTriadSeasonYear } from "utils/DateUtils";

const indicativeDemandPeakDocumentationLinkDict: {
  [key in "isSettlement" | "isOperational"]: DocumentationInformation;
} = {
  isSettlement: {
    chart: [
      "demand/peak/indicative/settlement/{triadSeason}",
      "demand/peak/triad",
    ],
    tile: ["demand/peak/triad"],
  },
  isOperational: {
    chart: [
      "demand/peak/indicative/operational/{triadSeason}",
      "demand/peak/triad",
    ],
    tile: ["demand/peak/triad"],
  },
};

const IndicativeDemandPeak: React.FC = () => {
  const [showOperationalData, toggleOperationalData] = useToggle(false);
  const displayYear = getMostRecentTriadSeasonYear();

  const { data, fetchData, status } = useIndicativeDemandPeakChartContainerData(
    showOperationalData,
    displayYear
  );

  return (
    <>
      <PageMeta title="Indicative peak demand" />
      <PageHeader
        title="Indicative peak demand"
        pageDescription={
          <>
            <p>
              The Triad indicative demand peaks are the three settlement periods
              of highest transmission system demand (adjusted to exclude demand
              from interconnectors) within a financial year (specifically in the
              Triad season November to February inclusive). These three half
              hour settlement periods of peak system demand must be separated
              from each other by at least ten clear days.
            </p>
            <p>
              The indicative Triad Demand Peak information using Operational
              Metering is derived from Initial Transmission System Demand
              Outturn (ITSDO) which is calculated from generation volumes, and
              losses are implicitly included within the calculations. The Triad
              Demand Peak information from Settlement Metering is calculated
              using demand data from metering system in the Settlement Reports
              (SAA-I014) which would exclude Transmission Losses. This results
              in a lower demand volume when comparing with Triad data using
              Operational Metering.
            </p>
          </>
        }
        expanderHeading="What you need to know while using this data"
        expanderContent={
          <p>
            The dates are purely indicative, being based on peak demands
            calculated using operational metering data, and so the final triad
            dates may well be different from these dates. When available, the
            final definitive triad dates (as calculated in accordance with&nbsp;
            <a
              href="https://www.nationalgrid.com/uk/electricity/codes/connection-and-use-system-code?code-documents"
              target="_blank"
              rel="noreferrer"
            >
              chapter 4 of the Statement of Use of System Charging Methodology
            </a>
            ) based on the latest available settlement metering data are
            published on the&nbsp;
            <a
              href="https://www.nationalgrid.com/uk/electricity/charging-and-methodology/charging-policy-and-guidance"
              target="_blank"
              rel="noreferrer"
            >
              National Energy System Operator website
            </a>
            , normally at the end of March each year for the preceding Nov-Feb
            winter triad period.
          </p>
        }
      >
        <IndicativeDemandPeakSummaryTile
          triadPeaksRaw={data?.triadPeaks}
          showOperationalData={showOperationalData}
        />
      </PageHeader>
      <FullWidthChartSection>
        <IndicativeDemandPeakChartContainer
          data={data}
          fetchData={fetchData}
          status={status}
          showOperationalData={showOperationalData}
          toggleHandler={toggleOperationalData}
        />
      </FullWidthChartSection>
      <AdditionalInfoSection
        documentationInformation={
          indicativeDemandPeakDocumentationLinkDict[
            showOperationalData ? "isOperational" : "isSettlement"
          ]
        }
      />
    </>
  );
};

export default IndicativeDemandPeak;
