import StyledButton from "components/components/Button/style";
import React from "react";

interface Props {
  rerequestData?: () => void | Promise<void>;
  buttonDataTestId?: string;
}

/**
 * Message for when data fails to load.
 *
 * If a rerequest function is passed then a button to reload the data is shown.
 */
const DataLoadFailedMessage: React.FC<Props> = ({
  rerequestData,
  buttonDataTestId,
}) => (
  <>
    <h2>Data failed to load</h2>
    {rerequestData && (
      <StyledButton
        onClick={rerequestData}
        className="button"
        data-test-id={buttonDataTestId}
      >
        Try Again
      </StyledButton>
    )}
  </>
);

export default DataLoadFailedMessage;
