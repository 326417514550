import styled from "styled-components";

export const ExpandButton = styled.button`
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  margin-left: 5px;
`;
