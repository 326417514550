import styled from "styled-components";
import colours from "styles/colours";

export const StyledTestBanner = styled.div`
  background-color: ${colours.elexonYellow};
  display: block;
  align-items: baseline;
  color: ${colours.elexonBlack};

  > .banner-row {
    margin: auto;
    max-width: 74%;
    padding: 10px;
  }

  .expander-button {
    cursor: pointer;
    align-items: center;
    text-decoration: none;
  }

  .expander-icon {
    font-family: elexon-icons;
    font-size: 0.75rem;
    width: 1.75rem;
  }
`;

export const Tag = styled.div`
  background-color: ${colours.elexonBlue};
  color: ${colours.white};
  padding: 2px 8px;
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: bold;
  border-radius: 2px;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  margin: auto;

  .align-right {
    margin-left: auto;
  }
`;

export const ExpandableDiv = styled.div`
  display: flex;
  align-items: flex-start;

  > div {
    flex: 1;
    margin: 0;
    display: block;
    padding-bottom: 38px;
  }
`;

export const StyledSubtitle = styled.h3`
  font-size: 1.25rem;
`;

export const MainWebsiteLink = styled.a`
  color: ${colours.elexonBlue};
  text-decoration: underline;
`;
