import { getSoSoTradePricesData } from "api/dataVisualisation/soSoTradePrices/soSoTradePrices";
import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import { ChartHeader } from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import SoSoTradePricesTable from "components/dataVisualization/soSoTradePrices/SoSoTradePricesTable/SoSoTradePricesTable";
import useSoSoTradePricesData from "components/dataVisualization/soSoTradePrices/useSoSoTradePrices";
import { mapToSoSoTradePricesRow } from "components/dataVisualization/soSoTradePrices/utils";
import { TableFooter } from "components/dataVisualization/tableComponents/style";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addHoursToDate,
  ceilToHalfHour,
  ONE_DAY,
  restrictDateFilterToMaxHoursRange,
} from "utils/dateHelpers";

const MAX_TIME_RANGE_HOURS = 24;
const DEFAULT_TIME_RANGE_HOURS = 12;

const SoSoTradePricesTableContainer: React.FC = () => {
  const { dispatch: dataExportCountDispatch } = useDataExport();
  const defaultEndDate = ceilToHalfHour(new Date());
  const defaultStartDate = addHoursToDate(
    defaultEndDate,
    -1 * DEFAULT_TIME_RANGE_HOURS
  );
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(defaultStartDate, defaultEndDate)
  );

  const {
    data: rawData,
    status,
    fetchData,
  } = useSoSoTradePricesData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const tableData = useMemo(
    () => (rawData ? mapToSoSoTradePricesRow(rawData) : []),
    [rawData]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getSoSoTradePricesData(dateFilter, fileFormat),
      `SoSoTradePrices-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDateSelection = useCallback(
    (newDateFilter: DateFilterModel): void => {
      setDateFilter(
        restrictDateFilterToMaxHoursRange(newDateFilter, MAX_TIME_RANGE_HOURS)
      );
    },
    [setDateFilter]
  );

  return (
    <>
      <ChartHeader className="is-table-view">
        <ChartFilter
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={[]}
          activeDateSelectorTab={null}
          maxRange={ONE_DAY}
        />
      </ChartHeader>
      <TableWithErrorHandling
        data={tableData}
        dataFetchStatus={status}
        rerequestData={fetchData}
      >
        <SoSoTradePricesTable data={tableData} />
      </TableWithErrorHandling>
      <TableFooter rightAligned>
        <DataExporter downloadData={downloadFile} />
      </TableFooter>
    </>
  );
};
export default SoSoTradePricesTableContainer;
